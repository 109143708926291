export enum ChainId {
  UNDEFINED = 0,
  ETHEREUM = 1, // ETHEREUM MAINNET
  MAINNET = 1, // ETHEREUM MAINNET
  // ROPSTEN = 3, // ETHEREUM TESTNET
  RINKEBY = 4, // ETHEREUM TESTNET
  TESTNET = 4,
  BSC = 56, // BINANCE SMART CHAIN MAINNET
  BSCTEST = 97, // BINANCE SMART CHAIN TESTNET
  // SOKOL = 77, // XDAI TESTNET
  // XDAI = 100, // XDAI MAINNET
  POLYGON = 137, // Polygon Mainnet
  // POLYGONTEST = 80001, // Polygon Testnet
  LETH = 6661, // LOCAL ETHEREUM
  LBSC = 6662, // LOCAL BSC
  AVALANCHETEST = 43113, // AVALANCHE TESTNET
  AVALANCHE = 43114, // AVALANCHE MAINNET
  FANTOM = 250, // FANTOM MAINNET
  AUTOBAHN = 45000, // FANTOM MAINNET
}
