import { createAction } from '@reduxjs/toolkit'

export const typeToken = createAction<{ typedToken: string }>('bridgeERC721/typeToken')
export const typeRecipient = createAction<{ typedRecipient: string }>('bridgeERC721/typeRecipient')
export const selectCollection = createAction<{ collectionId: string }>('bridgeERC721/selectCollection')
export const selectSourceChain = createAction<{ sourceChainId: number }>('bridgeERC721/selectSourceChain')
export const selectTargetChain = createAction<{ targetChainId: number }>('bridgeERC721/selectTargetChain')
export const setDeposit = createAction<{ sourceChainId: number; depositHash: string }>('bridgeERC721/setDeposit')
export const setApproval = createAction<{ approvalHash: string }>('bridgeERC721/setApproval')
export const reset = createAction('bridgeERC721/reset')
