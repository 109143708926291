import { useEffect, useState, useRef } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { simpleRpcProvider } from 'utils/providers'
// eslint-disable-next-line import/no-unresolved
import { Web3ReactContextInterface } from '@web3-react/core/dist/types'
import { useNoUserWalletPresentActionHandlers, useNoUserWalletPresentState } from '../state/userNoWalletPresent/hooks'

/**
 * Provides a web3 provider with or without user's signer
 * Recreate web3 instance only if the provider change
 */
const useActiveWeb3React = (): Web3ReactContextInterface<Web3Provider> => {
  const { library, chainId, account, ...web3React } = useWeb3React()
  const refEth = useRef(library)
  const [provider, setprovider] = useState(library || simpleRpcProvider)

  // used for LM, F, RP state when user is not connected with any account
  const { selectedChainNoUserWalletPresent, isUserWalletConnected } = useNoUserWalletPresentState()
  const { onUpdateIsUserWalletConnected, onChainSelect } = useNoUserWalletPresentActionHandlers()

  useEffect(() => {
    if (library !== refEth.current) {
      setprovider(library || simpleRpcProvider)
      refEth.current = library
    }

    if (account) {
      onUpdateIsUserWalletConnected(true)
      onChainSelect(null)
    }
  }, [
    selectedChainNoUserWalletPresent,
    chainId,
    library,
    account,
    onUpdateIsUserWalletConnected,
    onChainSelect,
    isUserWalletConnected,
  ])

  const activeChain = chainId ?? selectedChainNoUserWalletPresent

  return { library: provider, chainId: activeChain, account, ...web3React }
}

export default useActiveWeb3React
