import { createReducer } from '@reduxjs/toolkit'
import { addUsdPriceForToken, addUsdPriceForTokens, clear, reset } from './actions'

export interface PricesState {
  readonly prices: Record<string, number>
}

const initialState = {
  prices: {},
}

export default createReducer<PricesState>(initialState, (builder) =>
  builder
    .addCase(clear, () => initialState)
    .addCase(reset, (state) => {
      return {
        prices: state.prices,
      }
    })
    .addCase(addUsdPriceForToken, (state, { payload }) => {
      if (payload.usdPrice > 0) {
        return {
          prices: {
            ...state.prices,
            [payload.address]: payload.usdPrice,
          },
        }
      }

      return state
    })
    .addCase(addUsdPriceForTokens, (state, { payload }) => {
      return {
        prices: {
          ...state.prices,
          ...payload.fetchResult,
        },
      }
    }),
)
