import { Currency, CurrencyAmount, JSBI, TokenAmount } from '@pancakeswap/sdk'
import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import ERC20_INTERFACE from 'config/abi/erc20'
import { useAllTokens } from 'hooks/Tokens'
import { useMulticallContract } from 'hooks/useContract'
import { getBridgeSourceContract, isAddress } from 'utils'
import { Token } from 'sdk/entities/token'
import { isNativeSymbol } from 'sdk/entities/currency'
import { useSingleContractMultipleData, useMultipleContractSingleData } from '../multicall/hooks'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import getAddresses from '../../utils/getAddresses'

/**
 * Returns a map of the given addresses to their eventually consistent BNB balances.
 */
export function useBNBBalances(uncheckedAddresses?: (string | undefined)[]): {
  [address: string]: CurrencyAmount | undefined
} {
  const { chainId } = useActiveWeb3React()
  const multicallContract = useMulticallContract(chainId)

  const addresses: string[] = useMemo(
    () =>
      uncheckedAddresses
        ? uncheckedAddresses
            .map(isAddress)
            .filter((a): a is string => a !== false)
            .sort()
        : [],
    [uncheckedAddresses],
  )

  const results = useSingleContractMultipleData(
    multicallContract,
    'getEthBalance',
    addresses.map((address) => [address]),
  )

  return useMemo(
    () =>
      addresses.reduce<{ [address: string]: CurrencyAmount }>((memo, address, i) => {
        const value = results?.[i]?.result?.[0]
        if (value) memo[address] = CurrencyAmount.ether(JSBI.BigInt(value.toString()))
        return memo
      }, {}),
    [addresses, results],
  )
}

/**
 * Returns a map of token addresses to their eventually consistent token balances for a single account.
 */
export function useTokenBalancesWithLoadingIndicator(
  address?: string,
  tokens?: (Token | undefined)[],
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [],
    [tokens],
  )

  const validatedTokenAddresses = useMemo(() => validatedTokens.map((vt) => vt.address), [validatedTokens])

  const balances = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'balanceOf', [address])

  const anyLoading: boolean = useMemo(() => balances.some((callState) => callState.loading), [balances])

  return [
    useMemo(
      () =>
        address && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const value = balances?.[i]?.result?.[0]
              const amount = value ? JSBI.BigInt(value.toString()) : undefined

              if (amount) {
                memo[token.address] = new TokenAmount(token as any, amount)
              }
              return memo
            }, {})
          : {},
      [address, validatedTokens, balances],
    ),
    anyLoading,
  ]
}

/**
 * Returns a map of token addresses with balances that have been collected in the
 * ERC20bridgeContract inside of collectedUnsentFees variable. These tokens are ready
 * to be burned and can be displayed in the front-end
 */
export function useBurnTokensBalancesWithLoadingIndicator(
  tokens?: (Token | undefined)[],
  filterLowLiqudiityBurnTokens?: boolean,
): [{ [tokenAddress: string]: TokenAmount | undefined }, boolean] {
  const validatedTokens: Token[] = useMemo(
    () => tokens?.filter((t?: Token): t is Token => isAddress(t?.address) !== false) ?? [],
    [tokens],
  )
  const { library, chainId, account } = useActiveWeb3React()

  const validatedTokenAddresses = useMemo(() => validatedTokens.map((vt) => vt.address), [validatedTokens])
  const bridgeContractAddress = useMemo(() => getAddresses(chainId).BridgeV2ERC20, [chainId])
  const buybackAndBurnAddress = useMemo(() => getAddresses(chainId).BridgeV2BuyBackAndBurn, [chainId])
  const contract = getBridgeSourceContract(library, chainId, account || undefined)
  const erc20BalancesInContract = useMultipleContractSingleData(validatedTokenAddresses, ERC20_INTERFACE, 'balanceOf', [
    bridgeContractAddress,
  ])
  const mappedInputsForContractMethod = validatedTokenAddresses.map((eachTokenAddress) => {
    return [eachTokenAddress, buybackAndBurnAddress]
  })

  const burnTokenbalances = useSingleContractMultipleData(
    contract,
    'collectedUnsentFees',
    mappedInputsForContractMethod,
  )

  const anyLoading: boolean = useMemo(
    () => burnTokenbalances.some((callState) => callState.loading),
    [burnTokenbalances],
  )

  return [
    useMemo(
      () =>
        bridgeContractAddress && validatedTokens.length > 0
          ? validatedTokens.reduce<{ [tokenAddress: string]: TokenAmount | undefined }>((memo, token, i) => {
              const value = burnTokenbalances?.[i]?.result?.[0]
              const balanceOfEachTokenInContract = erc20BalancesInContract?.[i]?.result?.[0]
              const erc20blance = balanceOfEachTokenInContract
                ? new TokenAmount(token as any, JSBI.BigInt(balanceOfEachTokenInContract?.toString()))
                : undefined

              const amount = value ? JSBI.BigInt(value.toString()) : undefined

              const isContractLiquidityOfErc20TokenGreaterThanAmountWeWantToBurn =
                erc20blance &&
                amount &&
                parseFloat(erc20blance?.toSignificant(8)) >
                  parseFloat(new TokenAmount(token as any, amount)?.toSignificant(8))
              if (amount && filterLowLiqudiityBurnTokens) {
                if (isContractLiquidityOfErc20TokenGreaterThanAmountWeWantToBurn) {
                  memo[token.address] = new TokenAmount(token as any, amount)
                }
              } else if (amount && !filterLowLiqudiityBurnTokens) {
                memo[token.address] = new TokenAmount(token as any, amount)
              }
              return memo
            }, {})
          : {},
      [
        bridgeContractAddress,
        validatedTokens,
        burnTokenbalances,
        erc20BalancesInContract,
        filterLowLiqudiityBurnTokens,
      ],
    ),
    anyLoading,
  ]
}

export function useTokenBalances(
  address?: string,
  tokens?: (Token | undefined)[],
): { [tokenAddress: string]: TokenAmount | undefined } {
  return useTokenBalancesWithLoadingIndicator(address, tokens)[0]
}

export function useBurnTokenBalances(tokens?: (Token | undefined)[]): {
  [tokenAddress: string]: TokenAmount | undefined
} {
  return useBurnTokensBalancesWithLoadingIndicator(tokens)[0]
}

// get the balance for a single token/account combo
export function useTokenBalance(account?: string, token?: Token): TokenAmount | undefined {
  const tokenBalances = useTokenBalances(account, [token])
  if (!token) return undefined
  return tokenBalances[token.address]
}

export function useCurrencyBalances(
  account?: string,
  currencies?: (Currency | undefined)[],
  isBurnCurrency?: boolean,
): (CurrencyAmount | undefined)[] {
  const tokens = useMemo(
    () => currencies?.filter((currency): currency is Token => currency instanceof Token) ?? [],
    [currencies],
  )

  const tokenBalances = useTokenBalances(account, tokens)
  const burnTokenBalances = useBurnTokenBalances(tokens)
  const containsBNB: boolean = useMemo(
    () => currencies?.some((currency) => isNativeSymbol(currency?.symbol)) ?? false,
    [currencies],
  )
  const ethBalance = useBNBBalances(containsBNB ? [account] : [])

  return useMemo(
    () =>
      currencies?.map((currency) => {
        if (!account || !currency) return undefined
        if (currency instanceof Token)
          return isBurnCurrency ? burnTokenBalances[currency.address] : tokenBalances[currency.address]
        if (isNativeSymbol(currency.symbol)) return ethBalance[account]
        return undefined
      }) ?? [],
    [account, currencies, isBurnCurrency, ethBalance, tokenBalances, burnTokenBalances],
  )
}

export function useCurrencyBalance(
  account?: string,
  currency?: Currency,
  isBurnCurrency?: boolean,
): CurrencyAmount | undefined {
  return useCurrencyBalances(account, [currency], isBurnCurrency)[0]
}

// mimics useAllBalances
export function useAllTokenBalances(): { [tokenAddress: string]: TokenAmount | undefined } {
  const { account } = useWeb3React()
  const allTokens = useAllTokens()
  const allTokensArray = useMemo(() => Object.values(allTokens ?? {}), [allTokens])
  const balances = useTokenBalances(account ?? undefined, allTokensArray)
  return balances ?? {}
}
