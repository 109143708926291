import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import getFarmsConfig from 'config/constants/farms'
import isArchivedFarmId from 'utils/farmHelpers'
import priceHelperLpsConfig from 'config/constants/priceHelperLps'
import fetchFarms from './fetchFarms'
import fetchFarmsPrices from './fetchFarmsPrices'
import {
  fetchFarmUserAllowances,
  fetchFarmUserEarnings,
  fetchFarmUserStakedBalances,
  fetchFarmUserTokenBalances,
} from './fetchFarmUser'
import { Farm, FarmsState } from '../types'
import { ChainId } from '../../config/constants/chainId'

export const resetFarms = createAction('farms/reset')

const noAccountFarmConfig = (chainId: ChainId) =>
  getFarmsConfig(chainId).map((farm) => ({
    ...farm,
    userData: {
      allowance: '0',
      tokenBalance: '0',
      stakedBalance: '0',
      earnings: '0',
    },
  }))

const initialState: FarmsState = {
  data: noAccountFarmConfig(ChainId.UNDEFINED),
  loadArchivedFarmsData: false,
  userDataLoaded: false,
}

export const nonArchivedFarms = (chainId: ChainId) =>
  getFarmsConfig(chainId).filter(({ farmId }) => !isArchivedFarmId(farmId))

// Async thunks
export const fetchFarmsPublicDataAsync = createAsyncThunk<Farm[], { chainId: ChainId; farmIds: number[] }>(
  'farms/fetchFarmsPublicDataAsync',
  async ({ chainId, farmIds }) => {
    let farmsToFetch = getFarmsConfig(chainId)
    if (farmIds && farmIds.length > 0) {
      farmsToFetch = farmsToFetch.filter((farmConfig) => farmIds.includes(farmConfig.farmId))
    }

    // Add price helper farms
    const farmsWithPriceHelpers = farmsToFetch.concat(priceHelperLpsConfig)

    const farms = await fetchFarms(farmsWithPriceHelpers, chainId)
    const farmsWithPrices = await fetchFarmsPrices(farms)

    // Filter out price helper LP config farms
    return farmsWithPrices.filter((farm: Farm) => {
      return typeof farm.farmId !== 'undefined' && farm.farmId !== null
    })
  },
)

interface FarmUserDataResponse {
  farmId: number
  allowance: string
  tokenBalance: string
  stakedBalance: string
  earnings: string
}

export const fetchFarmUserDataAsync = createAsyncThunk<
  FarmUserDataResponse[],
  { account: string; chainId: ChainId; farmIds: number[] }
>('farms/fetchFarmUserDataAsync', async ({ account, chainId, farmIds }) => {
  const farmsToFetch = getFarmsConfig(chainId).filter((farmConfig) => farmIds.includes(farmConfig.farmId))
  const userFarmAllowances = await fetchFarmUserAllowances(account, farmsToFetch, chainId)
  const userFarmTokenBalances = await fetchFarmUserTokenBalances(account, farmsToFetch, chainId)
  const userStakedBalances = await fetchFarmUserStakedBalances(account, farmsToFetch, chainId)
  const userFarmEarnings = await fetchFarmUserEarnings(account, farmsToFetch, chainId)

  return userFarmAllowances.map((farmAllowance, index) => {
    return {
      farmId: farmsToFetch[index].farmId,
      allowance: userFarmAllowances[index],
      tokenBalance: userFarmTokenBalances[index],
      stakedBalance: userStakedBalances[index],
      earnings: userFarmEarnings[index],
    }
  })
})

export const farmsSlice = createSlice({
  name: 'Farms',
  initialState,
  reducers: {
    setLoadArchivedFarmsData: (state, action) => {
      const loadArchivedFarmsData = action.payload
      state.loadArchivedFarmsData = loadArchivedFarmsData
    },
  },
  extraReducers: (builder) => {
    // Update farms with live data
    builder.addCase(fetchFarmsPublicDataAsync.fulfilled, (state, action) => {
      const farmIds = action.payload.map((farmData) => farmData.farmId)
      state.data = state.data
        .filter((farm) => farmIds.indexOf(farm.farmId) > -1)
        .map((farm) => {
          const liveFarmData = action.payload.find((farmData) => farmData.farmId === farm.farmId)
          return { ...farm, ...liveFarmData }
        })
    })

    // Update farms with user data
    builder.addCase(fetchFarmUserDataAsync.fulfilled, (state, action) => {
      action.payload.forEach((userDataEl) => {
        const { farmId } = userDataEl
        const index = state.data.findIndex((farm) => farm.farmId === farmId)
        state.data[index] = { ...state.data[index], userData: userDataEl }
      })
      state.userDataLoaded = true
    })

    builder.addCase(resetFarms, () => {
      return initialState
    })
  },
})

// Actions
export const { setLoadArchivedFarmsData } = farmsSlice.actions

export default farmsSlice.reducer
