import { Currency } from '@pancakeswap/sdk'
import { ChainId } from 'config/constants/chainId'

export const NativeCurrencySymbols = ['LETH', 'LBNB', 'ETH', 'TXL' /* , 'BNB', 'MATIC' */]

class NativeCurrency extends Currency {
  public static readonly LETH: Currency = new Currency(18, 'LETH', 'Local Ether')

  public static readonly LBNB: Currency = new Currency(18, 'LBNB', 'Local BNB')

  public static readonly ETH: Currency = new Currency(18, 'ETH', 'Ether')

  public static readonly BNB: Currency = new Currency(18, 'BNB', 'Binance Coin')

  public static readonly MATIC: Currency = new Currency(18, 'MATIC', 'Polygon (Matic)')

  public static readonly TXL: Currency = new Currency(18, 'TXL', 'Autobahn gas token')
}

export function getNativeCurrency(chainId: ChainId): Currency | undefined {
  switch (chainId) {
    // Local
    case ChainId.LBSC:
      return NativeCurrency.LBNB
    case ChainId.LETH:
      return NativeCurrency.LETH

    // Binance
    case ChainId.BSC:
    case ChainId.BSCTEST:
      return NativeCurrency.BNB

    // Ethererum
    case ChainId.ETHEREUM:
    case ChainId.RINKEBY:
      return NativeCurrency.ETH

    // Polygon
    case ChainId.POLYGON:
      return NativeCurrency.MATIC

    // Polygon
    case ChainId.AUTOBAHN:
      return NativeCurrency.TXL

    default:
      return undefined
  }
}

export function isNativeSymbol(symbol: string) {
  return NativeCurrencySymbols.includes(symbol)
}
