import { createReducer } from '@reduxjs/toolkit'
import { updateIsUserWalletConnected, reset, updateSelectedChainNoUserWalletPresent } from './actions'
import { ChainId } from '../../config/constants/chainId'

export interface userWalletState {
  readonly isUserWalletConnected: boolean
  readonly selectedChainNoUserWalletPresent: number
}

// when wallet is connected selectedChainNoWalletConnected === null
const initialState = {
  isUserWalletConnected: false,
  selectedChainNoUserWalletPresent: ChainId.BSC,
}

export default createReducer<userWalletState>(initialState, (builder) =>
  builder
    .addCase(reset, () => initialState)
    .addCase(updateIsUserWalletConnected, (state, { payload: { isUserWalletConnected } }) => {
      state.isUserWalletConnected = isUserWalletConnected
    })
    .addCase(updateSelectedChainNoUserWalletPresent, (state, { payload: { selectedChainNoUserWalletPresent } }) => {
      state.selectedChainNoUserWalletPresent = selectedChainNoUserWalletPresent
    }),
)
