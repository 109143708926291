import { createReducer } from '@reduxjs/toolkit'
import { addClaimLink } from 'state/claims/actions'
import { speedUpTransaction } from 'state/transactions/actions'
import {
  reset,
  selectCurrency,
  selectSourceChain,
  selectTargetChain,
  setDeposit,
  typeAmount,
  typeRecipient,
} from './actions'

export interface SwapState {
  readonly typedAmount: string
  readonly typedRecipient: string
  readonly targetChainId: number | null
  readonly sourceChainId: number | null
  readonly currencyId: string
  readonly depositHash: string
}

const initialState = {
  typedAmount: '',
  typedRecipient: '',
  currencyId: '',
  targetChainId: null,
  sourceChainId: null,
  depositHash: '',
}

export default createReducer<SwapState>(initialState, (builder) =>
  builder
    .addCase(reset, () => initialState)
    .addCase(addClaimLink, () => initialState)
    .addCase(typeAmount, (state, { payload: { typedAmount } }) => {
      return {
        ...state,
        typedAmount,
      }
    })
    .addCase(typeRecipient, (state, { payload: { typedRecipient } }) => {
      return {
        ...state,
        typedRecipient,
      }
    })
    .addCase(selectCurrency, (state, { payload: { currencyId } }) => {
      return {
        ...state,
        currencyId,
      }
    })
    .addCase(selectSourceChain, (state, { payload: { sourceChainId } }) => {
      return {
        ...state,
        sourceChainId,
      }
    })
    .addCase(selectTargetChain, (state, { payload: { targetChainId } }) => {
      return {
        ...state,
        targetChainId,
      }
    })
    .addCase(setDeposit, (state, { payload: { depositHash, sourceChainId } }) => {
      return {
        ...state,
        sourceChainId,
        depositHash,
      }
    })
    .addCase(speedUpTransaction, (state, { payload: { hash, toHash } }) => {
      if (state.depositHash === hash) {
        state.depositHash = toHash
      }
    }),
)
