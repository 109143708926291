import React, { useCallback } from 'react'
import TransactionConfirmationModal, { TransactionErrorContent } from 'components/TransactionConfirmationModal'
import { useTranslation } from 'contexts/Localization'
import { InjectedModalProps } from '@pancakeswap/uikit'

interface ReleaseBridgeModalProps {
  attemptingTxn: boolean
  txHash?: string
  swapErrorMessage?: string
  customOnDismiss?: () => void
  onRetry?: () => any
}

export function ReleaseBridgeModal({
  onDismiss,
  onRetry,
  customOnDismiss,
  swapErrorMessage,
  attemptingTxn,
  txHash,
}: ReleaseBridgeModalProps & InjectedModalProps) {
  const { t } = useTranslation()
  const pendingText = 'Release Token'

  const closeModal = useCallback(() => {
    onDismiss()
    if (customOnDismiss) customOnDismiss()
  }, [onDismiss, customOnDismiss])

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={closeModal} message={swapErrorMessage} onRetry={onRetry} />
      ) : null,
    [closeModal, swapErrorMessage, onRetry],
  )

  return (
    <TransactionConfirmationModal
      title={t('Releasing token on bridge')}
      onDismiss={closeModal}
      customOnDismiss={customOnDismiss}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
    />
  )
}
