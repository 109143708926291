import { createAction } from '@reduxjs/toolkit'

export const typeAmount = createAction<{ typedAmount: string }>('liquidity/typeAmount')
export const selectCurrency = createAction<{ currencyId: string }>('liquidity/selectCurrency')
export const reset = createAction('liquidity/reset')
export const clear = createAction('liquidity/clear')
export const typeRecipient = createAction<{ typedRecipient: string }>('liquidity/typeRecipient')
export const selectSourceChain = createAction<{ sourceChainId: number }>('liquidity/selectSourceChain')
export const selectTargetChain = createAction<{ targetChainId: number }>('liquidity/selectTargetChain')
export const setDeposit = createAction<{ sourceChainId: number; depositHash: string }>('liquidity/setDeposit')
export const setCurrencyBeingRemoved = createAction<{ currencyIdBeingRemoved: string }>(
  'liquidity/setCurrencyBeingRemoved',
)
export const addPosition = createAction<{ chainId: number; lpToken: string; token: string }>('liquidity/addPosition')
