import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import { save, load } from 'redux-localstorage-simple'
import { useDispatch } from 'react-redux'
import farmsReducer from './farms'
import rewardPoolsReducer from './rewardPools'
import stakingReducer from './staking'
import blockReducer from './block'
import votingReducer from './voting'
import { updateVersion } from './global/actions'
import user from './user/reducer'
import transactions from './transactions/reducer'
import swap from './swap/reducer'
import mint from './mint/reducer'
import transientReducer from './transient/reducer'
import bridgeERC20Reducer from './bridgeERC20/reducer'
import bridgeERC721Reducer from './bridgeERC721/reducer'
import liquidityReducer from './liquidity/reducer'
import aprsReducer from './aprs/reducer'
import pricesReducer from './prices/reducer'
import claimsReducer from './claims/reducer'
import lists from './lists/reducer'
import burn from './burn/reducer'
import multicall from './multicall/reducer'
import userNoWalletPresentReducer from './userNoWalletPresent/reducer'
import buyBackAndBurnReducer from './buybackAndBurn/reducer'

const PERSISTED_KEYS: string[] = [
  'userNoWalletPresent',
  'user',
  'transactions',
  'lists',
  'bridgeERC20',
  'bridgeERC721',
  'liquidity',
  'claims',
  'buyBackAndBurn',
]

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    userNoWalletPresent: userNoWalletPresentReducer,
    aprs: aprsReducer,
    block: blockReducer,
    farms: farmsReducer,
    pools: rewardPoolsReducer,
    staking: stakingReducer,
    voting: votingReducer,
    bridgeERC20: bridgeERC20Reducer,
    bridgeERC721: bridgeERC721Reducer,
    liquidity: liquidityReducer,
    prices: pricesReducer,
    transient: transientReducer,
    claims: claimsReducer,
    buyBackAndBurn: buyBackAndBurnReducer,

    // Exchange
    user,
    transactions,
    swap,
    mint,
    burn,
    multicall,
    lists,
  },
  middleware: [...getDefaultMiddleware({ thunk: true }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS }),
})

store.dispatch(updateVersion())

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch()

export default store
