import { useMemo } from 'react'
import JSBI from 'jsbi'
import { Contract } from 'ethers'
import { TokenAmount } from 'sdk/entities/tokenAmount'
import { Token } from 'sdk/entities/token'
import { getNativeCurrency } from 'sdk/entities/currency'
import { useSingleCallResult } from '../multicall/hooks'

export function useBridgingFees(
  contract: Contract,
  targetChainId?: number,
  collectionAddress?: string,
): TokenAmount | undefined {
  const defaultFee = useSingleCallResult(contract, 'defaultBridgeFee', [])
  const tokenAddr = useMemo(() => [collectionAddress], [collectionAddress])
  const bridgeFees = useSingleCallResult(contract, 'bridgeFees', tokenAddr)

  const defaultBridgeFee = useMemo(() => {
    if (defaultFee && defaultFee.result) {
      return JSBI.BigInt(defaultFee.result[0])
    }

    return undefined
  }, [defaultFee])

  const bridgeFee = useMemo(() => {
    if (bridgeFees && bridgeFees.result) {
      return JSBI.BigInt(bridgeFees.result[0])
    }

    return undefined
  }, [bridgeFees])

  return useMemo(() => {
    // check which fee is above zero, prio the bridge fee, default fee is last
    const feeAmount =
      bridgeFee && JSBI.GT(bridgeFee, 0)
        ? bridgeFee
        : defaultBridgeFee && JSBI.GT(defaultBridgeFee, 0)
        ? defaultBridgeFee
        : JSBI.BigInt(0)

    const feeCurrency = getNativeCurrency(targetChainId)

    if (feeCurrency) {
      return new TokenAmount(feeCurrency as Token, feeAmount)
    }

    return undefined
  }, [defaultBridgeFee, bridgeFee, targetChainId])
}
