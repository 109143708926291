import { createSlice } from '@reduxjs/toolkit'
import getPoolsConfig from 'config/constants/pools'
import { BIG_ZERO } from 'utils/bigNumber'
import { AppThunk, Pool, PoolsState } from 'state/types'
import { getAddress } from 'utils/addressHelpers'
import { fetchPoolsStakingLimits, fetchPoolsTotalStaking } from './fetchPools'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserPendingRewards,
  fetchUserStakeBalances,
} from './fetchPoolsUser'
import { ChainId } from '../../config/constants/chainId'

const initialState: PoolsState = {
  data: [...getPoolsConfig(ChainId.UNDEFINED)],
  userDataLoaded: false,
  cakeVault: {
    totalShares: null,
    pricePerFullShare: null,
    totalCakeInVault: null,
    estimatedCakeBountyReward: null,
    totalPendingCakeHarvest: null,
    fees: {
      performanceFee: null,
      callFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    },
    userData: {
      isLoading: true,
      userShares: null,
      cakeAtLastUserAction: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
    },
  },
}

// Thunks
export const fetchPoolsPublicDataAsync = (chainId: ChainId) => async (dispatch) => {
  // const blockLimits = await fetchPoolsBlockLimits()
  const totalStakings = await fetchPoolsTotalStaking(chainId)

  const prices = 1 // getTokenPricesFromFarm(getState().farms.data)

  const liveData = getPoolsConfig(chainId).map((pool) => {
    const blockLimit = {}
    const totalStaking = totalStakings.find((entry) => entry.rewardPoolId === pool.rewardPoolId)
    const isPoolEndBlockExceeded = false
    const isPoolFinished = pool.isFinished || isPoolEndBlockExceeded

    const stakingTokenAddress =
      pool.stakingToken.address && chainId ? getAddress(pool.stakingToken.address, chainId).toLowerCase() : null
    const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

    const earningTokenAddress =
      pool.earningToken.address && chainId ? getAddress(pool.earningToken.address, chainId).toLowerCase() : null
    const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0
    const apr = -1

    return {
      ...blockLimit,
      ...totalStaking,
      stakingTokenPrice,
      earningTokenPrice,
      apr,
      isFinished: isPoolFinished,
    }
  })

  dispatch(setPoolsPublicData(liveData))
}

export const fetchPoolsStakingLimitsAsync = (chainId: ChainId) => async (dispatch, getState) => {
  const poolsWithStakingLimit = getState()
    .pools.data.filter(({ stakingLimit }) => stakingLimit !== null && stakingLimit !== undefined)
    .map((pool) => pool.rewardPoolId)

  const stakingLimits = await fetchPoolsStakingLimits(poolsWithStakingLimit, chainId)

  const stakingLimitData = getPoolsConfig(chainId).map((pool) => {
    if (poolsWithStakingLimit.includes(pool.rewardPoolId)) {
      return { rewardPoolId: pool.rewardPoolId }
    }
    const stakingLimit = stakingLimits[pool.rewardPoolId] || BIG_ZERO
    return {
      rewardPoolId: pool.rewardPoolId,
      stakingLimit: stakingLimit.toJSON(),
    }
  })

  dispatch(setPoolsPublicData(stakingLimitData))
}

export const fetchPoolsUserDataAsync =
  (account: string, chainId: ChainId): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchPoolsAllowance(account, chainId)
    const stakingTokenBalances = await fetchUserBalances(account, chainId)
    const stakedBalances = await fetchUserStakeBalances(account, chainId)
    const pendingRewards = await fetchUserPendingRewards(account, chainId)

    const userData = getPoolsConfig(chainId).map((pool) => ({
      rewardPoolId: pool.rewardPoolId,
      allowance: allowances[pool.rewardPoolId],
      stakingTokenBalance: stakingTokenBalances[pool.rewardPoolId],
      stakedBalance: stakedBalances[pool.rewardPoolId],
      pendingReward: pendingRewards[pool.rewardPoolId],
    }))

    dispatch(setPoolsUserData(userData))
  }

export const updateUserAllowance =
  (rewardPoolId: number, account: string, chainId: ChainId): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchPoolsAllowance(account, chainId)
    dispatch(updatePoolsUserData({ rewardPoolId, field: 'allowance', value: allowances[rewardPoolId] }))
  }

export const updateUserBalance =
  (rewardPoolId: number, account: string, chainId: ChainId): AppThunk =>
  async (dispatch) => {
    const tokenBalances = await fetchUserBalances(account, chainId)
    dispatch(updatePoolsUserData({ rewardPoolId, field: 'stakingTokenBalance', value: tokenBalances[rewardPoolId] }))
  }

export const updateUserStakedBalance =
  (rewardPoolId: number, account: string, chainId: ChainId): AppThunk =>
  async (dispatch) => {
    const stakedBalances = await fetchUserStakeBalances(account, chainId)
    dispatch(updatePoolsUserData({ rewardPoolId, field: 'stakedBalance', value: stakedBalances[rewardPoolId] }))
  }

export const updateUserPendingReward =
  (rewardPoolId: number, account: string, chainId: ChainId): AppThunk =>
  async (dispatch) => {
    const pendingRewards = await fetchUserPendingRewards(account, chainId)
    dispatch(updatePoolsUserData({ rewardPoolId, field: 'pendingReward', value: pendingRewards[rewardPoolId] }))
  }

export const PoolsSlice = createSlice({
  name: 'Pools',
  initialState,
  reducers: {
    setPoolsPublicData: (state, action) => {
      const livePoolsData: Pool[] = action.payload
      const rewardPoolIds = livePoolsData.map((poolData) => poolData.rewardPoolId)
      state.data = state.data
        .filter((pool) => rewardPoolIds.indexOf(pool.rewardPoolId) > -1)
        .map((pool) => {
          const livePoolData = livePoolsData.find((entry) => entry.rewardPoolId === pool.rewardPoolId)
          return { ...pool, ...livePoolData }
        })
    },
    setPoolsUserData: (state, action) => {
      const userData = action.payload
      const rewardPoolIds = userData.map((poolData) => poolData.rewardPoolId)

      state.data = state.data
        .filter((pool) => rewardPoolIds.indexOf(pool.rewardPoolId) > -1)
        .map((pool) => {
          const userPoolData = userData.find((entry) => entry.rewardPoolId === pool.rewardPoolId)
          return { ...pool, userData: userPoolData }
        })
      state.userDataLoaded = true
    },
    updatePoolsUserData: (state, action) => {
      const { field, value, rewardPoolId } = action.payload
      const index = state.data.findIndex((p) => p.rewardPoolId === rewardPoolId)

      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
      }
    },
    resetRewardPools: (state, action) => {
      return {
        ...initialState,
        data: getPoolsConfig(action.payload.chainId),
      }
    },
  },
})

// Actions
export const { setPoolsPublicData, setPoolsUserData, updatePoolsUserData, resetRewardPools } = PoolsSlice.actions

export default PoolsSlice.reducer
