import React from 'react'
import { Flex } from '@pancakeswap/uikit'
// import { Flex, IconButton, CogIcon, useModal } from '@pancakeswap/uikit'
// import SettingsModal from './SettingsModal'

const GlobalSettings = () => {
  // const [onPresentSettingsModal] = useModal(<SettingsModal />)

  return (
    <Flex>
      {/*
      <IconButton onClick={onPresentSettingsModal} variant="text" scale="sm" mr="8px">
        <CogIcon height={22} width={22} color="textSubtle" />
      </IconButton>
        * */}
    </Flex>
  )
}

export default GlobalSettings
