import React, { lazy } from 'react'
import { Router, Route, Switch, Redirect } from 'react-router-dom'
import { ResetCSS } from '@pancakeswap/uikit'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import { usePollBlockNumber } from 'state/block/hooks'
import { useLoadSpeedUpTx } from 'state/transactions/hooks'
import { DatePickerPortal } from 'components/DatePicker'
import TermsConditionModal from 'components/Menu/GlobalSettings/TermsConditionModal'
import GlobalStyle from './style/Global'
import { Menu } from './components/Menu'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import { ToastListener } from './contexts/ToastsContext'
import PageLoader from './components/Loader/PageLoader'
import history from './routerHistory'
// Views included in the main bundle
import BridgeERC721 from './views/BridgeERC721'
import BridgeERC20 from './views/BridgeERC20'
import { useSetupNetwork } from './utils/wallet'

// Route-based code splitting
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const RewardPools = lazy(() => import('./views/RewardPools'))
const NotFound = lazy(() => import('./views/NotFound'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const LiquidityMining = lazy(() => import('./views/LiquidityMining'))
const LiquidityManagement = lazy(() => import('./views/Liquidity'))
const LiquidityFinder = lazy(() => import('./views/LiquidityFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const BuyBackStats = lazy(() => import('./views/BuyBackStats'))
const BuyBackAndBurn = lazy(() => import('./views/BuyBackAndBurn'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  useLoadSpeedUpTx()
  usePollBlockNumber()
  useEagerConnect()
  const { setupNetwork } = useSetupNetwork()

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <Menu id="UserMenu1" onNetworkSelect={(chainId) => setupNetwork(chainId)}>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
            <Route exact strict path="/bridge/tokens" component={BridgeERC20} />
            <Route exact strict path="/bridge/tokens/:currencyId" component={BridgeERC20} />
            <Route exact strict path="/bridge/tokens/:currencyId/:toChainId" component={BridgeERC20} />
            <Route exact strict path="/bridge/erc20" component={BridgeERC20} />
            <Route exact strict path="/bridge/nfts" component={BridgeERC721} />
            <Route exact strict path="/bridge/nfts/:collectionId" component={BridgeERC721} />
            <Route exact strict path="/bridge/nfts/:collectionId/:toChainId" component={BridgeERC721} />
            <Route exact strict path="/bridge/erc721" component={BridgeERC721} />
            <Redirect from="/bridge/:currencyId" push to="/bridge/tokens/:currencyId" />
            <Route exact strict path="/mining" component={LiquidityMining} />
            <Route exact strict path="/mining/staked" component={LiquidityMining} />
            <Route exact strict path="/farms" component={Farms} />
            <Route exact strict path="/farms/staked" component={Farms} />
            <Route exact strict path="/farms/archived" component={Farms} />
            <Route exact strict path="/farms/history" component={Farms} />
            <Route exact strict path="/pools" component={RewardPools} />
            <Route exact strict path="/pools/staked" component={RewardPools} />
            <Route exact strict path="/liquidity/find" component={LiquidityFinder} />
            <Route exact strict path="/liquidity/manage" component={LiquidityManagement} />
            <Route exact path="/liquidity/add" component={AddLiquidity} />
            <Route exact path="/liquidity/add/:currencyId" component={AddLiquidity} />
            <Route exact strict path="/liquidity/remove/:currencyId" component={RemoveLiquidity} />
            <Route exact strict path="/buyback/stats" component={BuyBackStats} />
            <Route exact path="/buyback/burn" component={BuyBackAndBurn} />
            <Route exact path="/buyback/burn/:currencyId" component={BuyBackAndBurn} />

            <Redirect from="/" push to="/bridge/tokens" />

            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <ToastListener />
      <DatePickerPortal />
      <TermsConditionModal />
    </Router>
  )
}

export default React.memo(App)
