import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1321.15 1321.15">
        <g id="Camada_2" data-name="Camada 2">
          <g id="Camada_1-2" data-name="Camada 1">
            <path d="M988.44,778.12c-6.21-14.13-15.78-22.78-27-24.36-11.34-1.6-23.15,4.2-33.25,16.34a69.7,69.7,0,0,1-17,14.81c-21.25,13-45.15,17-73.09,12.21-23.34-4-40.89-14.08-53.66-30.94a31,31,0,0,0-24.72-12.65h0c-10.15,0-19.75,5-26.35,13.65a70.56,70.56,0,0,1-28.22,22.55c-20,8.85-41.58,11.38-64,7.51-23.74-4.1-41.47-14.31-54.2-31.21a30.1,30.1,0,0,0-23.2-12.35c-10-.37-19.85,4-27,12.15-5.41,6.13-11.9,13.1-19.08,18.24-11.62,8.32-26.21,13.11-43.37,14.23-39,2.53-66-7.8-84.89-32.51-10.47-13.69-29.86-16.15-44.12-5.6-13.93,10.29-17.25,29.29-7.56,43.24A125.89,125.89,0,0,0,355.47,824c24.12,22.42,54.91,35.37,91.51,38.51,43.74,3.75,82.38-6.55,114.9-30.59,54.48,41.88,143.48,42.1,197.63.08,30.42,21.17,62.7,31.77,96.51,31.77q34.82,0,71.71-15C956.47,837,976.87,818.61,988.37,794l.66-1.43v-13.1Z" />
            <path d="M987.8,889.93c-4.38-13.18-13.63-21.09-26.78-22.87-15.6-2.11-25.53,7.21-31.63,14.41a80.37,80.37,0,0,1-18.72,16.7c-19.95,12.21-43.56,16.36-70.19,12.33-24.46-3.71-42.5-13.66-55.16-30.42-6.49-8.59-15.86-13.53-25.71-13.54h0c-10.11,0-19.81,5.13-26.6,14.07a68.71,68.71,0,0,1-25.73,21.08c-19.85,9.44-41.5,12.4-64.35,8.81-24.54-3.86-41.68-13.13-53.93-29.16-7.27-9.5-16.87-14.74-27-14.74h0c-10.13,0-19.67,5.2-26.88,14.64a67.39,67.39,0,0,1-22.84,19.23c-16.93,8.83-35.67,12.59-55.69,11.18-30.36-2.15-51.29-12.12-65.86-31.4-11.23-14.85-30.72-17.91-45.33-7.12a31.24,31.24,0,0,0-5.78,45.7c7,8.47,16.37,19.27,27.7,27.67,26.5,19.64,61.1,30.27,97.38,29.89s70.72-11.58,97.29-31.6l.16-.12h0c27.56,20.47,62.5,31.74,98.5,31.74h0c36,0,71.13-11.28,98.86-31.78,30.37,21.37,62.67,32.08,96.58,32.08q34.76,0,71.67-15C956.4,950,976.8,931.61,988.36,907l.67-1.43V891.91L988,890.2C987.92,890.11,987.86,890,987.8,889.93Z" />
            <path d="M430.21,708.42v.79c0,1.27,0,2.71.12,4.21a32.17,32.17,0,0,0,41.28,28c13.79-4.16,22.79-16.41,22.94-31.2.09-8.56.07-17.26,0-25.67,0-1.38,0-2.76,0-4.15H706.3c0,1.61,0,3.21,0,4.81,0,8.93-.08,17.36.08,26a32.06,32.06,0,0,0,29.55,31.59c.85.07,1.69.1,2.53.1a32.34,32.34,0,0,0,31.49-25.79,48.31,48.31,0,0,0,.72-10v-.78q0-38.75,0-77.51c0-61.8,0-125.71.1-188.56a40.34,40.34,0,0,1,1.74-12.54c4.1-12.38,17.34-20.46,30.13-18.42,14,2.25,23.47,13.07,24.13,27.57.88,19.31,14.8,32.95,33.19,32.41,18.24-.53,31.6-15.37,31.08-34.52a89.17,89.17,0,0,0-2-16.87,94.44,94.44,0,0,0-36.13-55.6A89.58,89.58,0,0,0,790,345c-48.3,5.26-83.5,44.21-83.7,92.63-.06,15,0,30.23,0,45q0,9.85,0,19.71H494.57q0-7.42,0-14.8c0-16.25,0-33,0-49.48.08-16.19,11.81-28.64,27.29-29,15.93-.29,28,11.5,28.79,28.13.85,18.4,14.63,32.08,32.19,32.08h.64c18-.34,31.56-14.72,31.53-33.45,0-23.33-9.37-45.74-26.26-63.1s-39-27.31-62.32-28c-25.9-.77-50.13,8.48-68.2,26s-28,41.39-28,67.25c-.07,64.71-.05,130.5,0,194.12Q430.21,670.24,430.21,708.42Zm64.67-93.11V567.46H705.8v47.85Z" />
            <path d="M660.57,1321.15A660.74,660.74,0,0,1,403.44,51.93a660.4,660.4,0,0,1,917.71,608.64,42.52,42.52,0,1,1-85,0C1236.11,343.22,977.93,85,660.57,85S85,343.22,85,660.57s258.18,575.54,575.53,575.54c172.94,0,335.15-76.74,445-210.54a42.52,42.52,0,1,1,65.72,54C1045.22,1233.08,859.06,1321.15,660.57,1321.15Z" />
            <path d="M1211.67,979.18a42.54,42.54,0,0,1-37.54-62.43c10.22-19.29,36.26-91.88,36.26-121.29a42.52,42.52,0,1,1,85,0c0,22-6.13,52.38-18.22,90.12-8.86,27.63-20.08,56.15-27.93,71A42.52,42.52,0,0,1,1211.67,979.18Z" />
          </g>
        </g>
      </svg>
    </Svg>
  )
}

export default Icon
