import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1321.15 1321.15">
        <g id="Camada_2" data-name="Camada 2">
          <g id="Camada_1-2" data-name="Camada 1">
            <path d="M859.48,975.7c-95.81,0-173.75-77.94-173.75-173.75S763.67,628.2,859.48,628.2,1033.23,706.14,1033.23,802,955.29,975.7,859.48,975.7Zm0-298A124.29,124.29,0,1,0,983.77,802,124.43,124.43,0,0,0,859.48,677.66Z" />
            <path d="M556.5,959.69H344.67a56.82,56.82,0,0,1-56.75-56.75V691.11a56.82,56.82,0,0,1,56.75-56.76H556.5a56.81,56.81,0,0,1,56.75,56.76V902.94A56.81,56.81,0,0,1,556.5,959.69ZM344.67,683.81a7.39,7.39,0,0,0-7.29,7.3V902.94a7.39,7.39,0,0,0,7.29,7.29H556.5a7.39,7.39,0,0,0,7.3-7.29V691.11a7.39,7.39,0,0,0-7.3-7.3Z" />
            <path d="M792.36,569.59H528.79a43.44,43.44,0,0,1-37.62-65.16L623,276.18h0a43.43,43.43,0,0,1,75.23,0L830,504.43a43.44,43.44,0,0,1-37.62,65.16ZM539.22,520.13H781.93L660.58,309.94ZM665.79,300.91h0Z" />
            <path d="M660.57,1321.15A660.74,660.74,0,0,1,403.44,51.93a660.4,660.4,0,0,1,917.71,608.64,42.52,42.52,0,1,1-85,0C1236.11,343.22,977.93,85,660.57,85S85,343.22,85,660.57s258.18,575.54,575.53,575.54c172.94,0,335.15-76.74,445-210.54a42.52,42.52,0,1,1,65.72,54C1045.22,1233.08,859.06,1321.15,660.57,1321.15Z" />
            <path d="M1211.67,979.18a42.54,42.54,0,0,1-37.54-62.43c10.22-19.29,36.26-91.88,36.26-121.29a42.52,42.52,0,1,1,85,0c0,22-6.13,52.38-18.22,90.12-8.86,27.63-20.08,56.15-27.93,71A42.52,42.52,0,0,1,1211.67,979.18Z" />
          </g>
        </g>
      </svg>
    </Svg>
  )
}

export default Icon
