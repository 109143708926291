import BigNumber from 'bignumber.js'
import { ethers } from 'ethers'
import bridgechefABI from 'config/abi/bridgeChef.json'
import erc20 from 'config/abi/erc20.json'
import { getAddress, getBridgeChefAddress, getBridgeErc20Address } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import multicall from 'utils/multicall'
import { Farm, SerializedBigNumber } from '../types'
import { ChainId } from '../../config/constants/chainId'
import { getRpcProvider } from '../../utils/providers'

type PublicFarmData = {
  lpTotalSupply: SerializedBigNumber
  totalStaked: SerializedBigNumber
  totalLiquidity: SerializedBigNumber
  poolWeight: SerializedBigNumber
  multiplier: string
}

const fetchFarm = async (
  farm: Farm,
  chainId: ChainId,
  signer?: ethers.Signer | ethers.providers.Provider,
): Promise<PublicFarmData> => {
  const { farmId, lpToken, token } = farm
  const lpAddress = getAddress(lpToken.address, chainId)
  const tokenAddress = getAddress(token.address, chainId)
  const calls = [
    // Total supply of LP tokens
    {
      address: lpAddress,
      name: 'totalSupply',
    },
    // Total staked LP tokens
    {
      address: lpAddress,
      name: 'balanceOf',
      params: [getBridgeChefAddress(chainId)],
    },
    // Liquidity in Bridge
    {
      address: tokenAddress,
      name: 'balanceOf',
      params: [getBridgeErc20Address(chainId)],
    },
  ].filter((callParams) => callParams.address && callParams.address.length > 0)

  if (calls.length === 0) return null

  const defaultSigner = await getRpcProvider(chainId)
  const [lpTotalSupply, totalStaked, totalLiquidity] = await multicall(erc20, calls, chainId, defaultSigner || signer)

  // Only make bridgechef calls if farm has pid
  const [info, sumOfFarmMultiplier] =
    typeof farmId === 'undefined' || farmId === null
      ? [null, null]
      : await multicall(
          bridgechefABI,
          [
            {
              address: getBridgeChefAddress(chainId),
              name: 'farmInfo',
              params: [farmId],
            },
            {
              address: getBridgeChefAddress(chainId),
              name: 'sumOfFarmMultiplier',
            },
          ],
          chainId,
          defaultSigner || signer,
        )

  const farmMultiplier = info ? new BigNumber(info.farmMultiplier?._hex) : BIG_ZERO
  const poolWeight = sumOfFarmMultiplier ? farmMultiplier.div(new BigNumber(sumOfFarmMultiplier)) : BIG_ZERO

  return {
    lpTotalSupply: new BigNumber(lpTotalSupply).toJSON(),
    totalStaked: new BigNumber(totalStaked).toJSON(),
    totalLiquidity: new BigNumber(totalLiquidity).toJSON(),
    poolWeight: poolWeight.toJSON(),
    multiplier: `${farmMultiplier.div(100).toString()}X`,
  }
}

export default fetchFarm
