import React, { CSSProperties, MutableRefObject, useCallback } from 'react'
import { Text } from '@pancakeswap/uikit'
import { FixedSizeList } from 'react-window'
import { ChainId } from 'config/constants/chainId'
import Column from 'components/Layout/Column'
import styled from 'styled-components'
import { MenuItem } from './CurrencyList'
import { NETWORK_ICON } from '../../config/constants/networkIcons'
import { Flex } from '../../theme/components/Box'

const NetworkIconImg = styled.img`
  align-items: center;
  background-color: #faf9fa;
  border-color: #0050ff;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
  z-index: 102;
  margin-right: 1rem;
  box-shadow: inset 0px -2px 0px rgb(0 0 0 / 10%);
`

NetworkIconImg.defaultProps = {
  src: NetworkIconImg,
}

function NetworkRow({
  network,
  onSelect,
  isSelected,
  style,
}: {
  network: any
  onSelect: () => void
  isSelected: boolean
  style: CSSProperties
}) {
  return (
    <MenuItem
      style={style}
      className={`network-item-${network.chainId}`}
      onClick={() => (isSelected ? null : onSelect())}
      disabled={isSelected}
      selected={false}
    >
      <Flex alignItems="center">
        <NetworkIconImg src={NETWORK_ICON[network.chainId]} alt={`${network.name} Icon`} />
        <Column>
          <Text bold>{network.name}</Text>
          <Text color="textSubtle" small ellipsis>
            {network.description}
          </Text>
        </Column>
      </Flex>
    </MenuItem>
  )
}

interface NetworkListProps {
  height: number
  networks: any[]
  selectedNetwork?: ChainId | null
  onNetworkSelect: (chainId: ChainId) => void
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
}

export default function NetworkList({
  height,
  networks,
  selectedNetwork,
  onNetworkSelect,
  fixedListRef,
}: NetworkListProps) {
  const Row = useCallback(
    ({ data, index, style }) => {
      const network: any = data[index]
      const isSelected = Boolean(selectedNetwork && selectedNetwork === network.chainId)
      const handleSelect = () => onNetworkSelect(network.chainId)

      return <NetworkRow style={style} network={network} isSelected={isSelected} onSelect={handleSelect} />
    },
    [onNetworkSelect, selectedNetwork],
  )

  const itemKey = useCallback((index: number) => networks[index].chainId, [networks])

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={networks}
      itemCount={networks.length}
      itemSize={56}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}
