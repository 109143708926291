import { useCallback, useMemo } from 'react'
import { parseUnits } from '@ethersproject/units'
import { isHexString } from '@ethersproject/bytes'
import { useWeb3React } from '@web3-react/core'
import { Currency } from '@pancakeswap/sdk'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import JSBI from 'jsbi'
import { validChainId } from 'config/constants/networks'
import { Token } from 'sdk/entities/token'
import { TokenAmount } from 'sdk/entities/tokenAmount'
import { useTransactionAdder } from 'state/transactions/hooks'
import { getBridgeSourceContract } from 'utils'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from 'state'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { getToken, getTokenAddress } from 'config/constants/tokens'
import { TransactionDetails } from 'state/transactions/reducer'
import { isNativeSymbol } from 'sdk/entities/currency'
import { useSupportedNativeToken } from 'hooks/Tokens'
import { REQUIRED_BLOCK_CONFIRMATIONS } from '../../config'
import {
  reset,
  selectCurrency,
  selectSourceChain,
  selectTargetChain,
  setDeposit,
  typeAmount,
  typeRecipient,
} from './actions'
import useUserAddedTokens from '../user/hooks/useUserAddedTokens'
import useWrappedNative from './useWrappedNative'

export function useBridgeState(): AppState['bridgeERC20'] {
  return useSelector<AppState, AppState['bridgeERC20']>((state) => {
    return state.bridgeERC20
  })
}

export function useBridgeActionHandlers() {
  const dispatch = useDispatch<AppDispatch>()

  const onReset = useCallback(() => {
    dispatch(reset())
  }, [dispatch])

  const onAmountInput = useCallback(
    (typedAmount: string) => {
      dispatch(typeAmount({ typedAmount }))
    },
    [dispatch],
  )

  const onRecipientInput = useCallback(
    (typedRecipient: string) => {
      dispatch(typeRecipient({ typedRecipient }))
    },
    [dispatch],
  )

  const onSelectCurrency = useCallback(
    (currencyId: string) => {
      dispatch(selectCurrency({ currencyId }))
    },
    [dispatch],
  )

  const onSelectTargetChain = useCallback(
    (targetChainId: number) => {
      dispatch(selectTargetChain({ targetChainId }))
    },
    [dispatch],
  )

  const onSelectSourceChain = useCallback(
    (sourceChainId: number) => {
      dispatch(selectSourceChain({ sourceChainId }))
    },
    [dispatch],
  )

  const onSetDepositHash = useCallback(
    (depositHash: string, sourceChainId: number) => {
      dispatch(setDeposit({ depositHash, sourceChainId }))
    },
    [dispatch],
  )

  return {
    onReset,
    onAmountInput,
    onRecipientInput,
    onSelectCurrency,
    onSelectTargetChain,
    onSelectSourceChain,
    onSetDepositHash,
  }
}

export function useTransactionDetails(chainId: number, hash: string): TransactionDetails | undefined {
  return useSelector<AppState, TransactionDetails>((state) => {
    if (!chainId) return undefined

    return state.transactions[chainId]?.[hash]
  })
}

export function useTransactionInfo(
  chainId: number,
  hash: string,
): { currentBlock: number; blockNumber?: number; confirmations?: number } | undefined {
  const currentBlock = useSelector<AppState, number>((state) => state.block.currentBlock)

  const tx = useSelector<AppState, TransactionDetails>((state) => {
    if (!chainId) return undefined

    return state.transactions[chainId]?.[hash]
  })

  if (!tx) return { currentBlock }

  return {
    currentBlock,
    blockNumber: tx.receipt?.blockNumber,
    confirmations: tx.receipt ? currentBlock - tx.receipt.blockNumber : undefined,
  }
}

export const getWaitConfirmations = (chainId) => {
  const defaultConfirmations = parseInt(process.env.REACT_APP_WAIT_CONFIRMATIONS || '24')
  const waitConfirmations = REQUIRED_BLOCK_CONFIRMATIONS[chainId]
  return waitConfirmations || defaultConfirmations
}

export function useDerivedBridgeInfo() {
  const queryString = useParsedQueryString()
  const { chainId } = useWeb3React()
  const {
    depositHash,
    currencyId: stateCurrency,
    sourceChainId: stateSource,
    targetChainId: stateTarget,
  } = useBridgeState()
  const depositTx = useTransactionInfo(chainId, depositHash)
  const userAddedTokens = useUserAddedTokens()

  const sourceChainId = depositHash ? stateSource : chainId
  // prevents the form from having targetChainId the same as sourceChainId
  const targetChainId = stateTarget === sourceChainId ? null : stateTarget ?? null
  const currencyId = stateCurrency ?? ''

  const nativeToken = useSupportedNativeToken(sourceChainId, targetChainId)

  const WAIT_CONFIRMATIONS = getWaitConfirmations(chainId)

  // if deposited, compare to current block until enough confirmations
  const confirmations = depositTx.confirmations || 0
  const waitingDepositConfirmations =
    Boolean(depositHash) && confirmations < WAIT_CONFIRMATIONS && chainId === sourceChainId

  // if release params are present in the URL, take these to try a release
  if (queryString.release) {
    const parts = (queryString.release as string).split(',')

    if (parts.length === 3) {
      return {
        depositHash: parts[2],
        sourceChainId: Number.parseInt(parts[0]),
        targetChainId: Number.parseInt(parts[1]),
        networkSwitchNeeded: chainId !== Number.parseInt(parts[1]),
        deposited: true,
        waitingDepositConfirmations,
        confirmations,
      }
    }
  }

  const networkSwitchNeeded = depositHash && chainId !== targetChainId
  const deposited = Boolean(depositHash)

  let currency = isNativeSymbol(currencyId) ? nativeToken : getToken(sourceChainId, currencyId)

  if (!currency) {
    currency = userAddedTokens.filter((token) => token.symbol === stateCurrency || token.address === stateCurrency)[0]
  }

  return {
    depositHash,
    currencyId,
    sourceChainId,
    targetChainId,
    networkSwitchNeeded,
    deposited,
    currency,
    waitingDepositConfirmations,
    confirmations,
  }
}

export function tryParseAmount(value?: string, currency?: Currency): TokenAmount | undefined {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(value, currency.decimals).toString()
    if (typedValueParsed !== '0') {
      return new TokenAmount(currency as Token, JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.debug(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

export function useBridgeV2ERC20ReleaseCallback(token: Token): {
  isNative: boolean
  callback: null | ((releaseparams: any) => Promise<string>)
  error: string | null
} {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const { wrappedNative } = useWrappedNative()
  const contract = getBridgeSourceContract(library, chainId, account || undefined)

  // its a native withdrawal, if the lp token has the same address as the wrapped native token
  const isNative = wrappedNative === getTokenAddress(token, chainId)

  return useMemo(() => {
    if (!account || !chainId) {
      return {
        isNative,
        callback: null,
        error: 'No wallet connected.',
      }
    }

    if (!contract) {
      return {
        isNative,
        callback: null,
        error: 'BridgeERC20 contract not found',
      }
    }

    return {
      isNative,
      error: null,
      callback: async function onBridgeV2ERC20Release(releaseParams): Promise<string> {
        const callMethod = isNative ? 'releaseNative' : 'releaseERC20'

        try {
          const targetChainIsAutobahn = (await contract.provider.getNetwork()).chainId === 45000
          const gasLimit = await contract.estimateGas[callMethod](...releaseParams, {})

          return contract[callMethod](
            ...releaseParams,
            targetChainIsAutobahn ? { gasLimit, gasPrice: 1 } : { gasLimit },
          )
            .then((response: any) => {
              addTransaction(response)
              return response.hash
            })
            .catch((err: any) => {
              console.debug('Unexpected error during release', err)
              const errorMessage = err.message || err.data?.message

              if (errorMessage.indexOf('Not enough liquidity in bridge') > -1) {
                throw new Error('Not enough liquidity in bridge. Please try again later.')
              }

              if (errorMessage.indexOf('Deposit was already processed and released') > -1) {
                throw new Error('Deposit was already processed and released.')
              }

              if (errorMessage.includes('denied transaction')) {
                throw new Error('You denied the transaction. Please try again.')
              }

              // case when wrong release chainId has been used for release link
              if (errorMessage.includes('Release not permitted')) {
                throw new Error(
                  'Release failed. Make sure that you are on the correct network when releasing the tokens.',
                )
              }

              throw new Error(
                'Unexpected error during release. Make sure there is enough liquidity in the bridge. If there is, please try again in 15 mins.',
              )
            })
        } catch (gasError) {
          return contract.callStatic[callMethod](...releaseParams, {})
            .catch((callError) => {
              console.debug(callError)
              throw new Error(callError.error.message)
            })
            .then((result) => {
              console.debug('Unexpected successful call after failed estimate gas', gasError, result)
              throw new Error('Unexpected issue with estimating the gas. Please try again.')
            })
        }
      },
    }
  }, [account, chainId, contract, isNative, addTransaction])
}

export function useReleaseLink(depositHash?: string, sourceChainId?: number, targetChainId?: number) {
  const queryString = useParsedQueryString()

  const link = useMemo(() => {
    return depositHash && sourceChainId && targetChainId
      ? `/bridge/tokens?release=${sourceChainId},${targetChainId},${depositHash}`
      : undefined
  }, [depositHash, sourceChainId, targetChainId])

  if (queryString.release) {
    // Validate the URL parts
    const parts = (queryString.release as string).split(',')
    const error = !validChainId(Number.parseInt(parts[0]))
      ? `the deposit chain id (${parts[0]}) is not valid.`
      : !validChainId(Number.parseInt(parts[1]))
      ? `the release chain id (${parts[1]}) is not valid.`
      : !isHexString(parts[2])
      ? 'the transaction hash is not in a valid hex format.'
      : false

    return {
      isInUrl: true,
      link,
      error,
    }
  }

  return {
    isInUrl: false,
    link,
    error: false,
  }
}
