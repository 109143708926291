import React from 'react'
import styled from 'styled-components'
import { Box, Text } from '@pancakeswap/uikit'
import HaetchiIcon from './Haetchi-Audit-Icon-400x400.jpeg'
import ChainsultingIcon from './Chainsulting-Audit-Icon-739x700.png'

const Wrapper = styled(Box)`
  @media only screen and (min-width: 1024px) {
    top: 2.5%;
  }
  @media only screen and (min-width: 768px) and (orientation: landscape) {
    top: 2.5%;
  }
  z-index: 5;
  position: fixed;
  top: 10px;
  right: 0;
  width: 195px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  & a {
    transform: translate(160px, 0px);
    border-radius: 50px 0 0 50px;
    text-align: left;
    margin: 2px 0 2px 0;
    text-decoration: none;
    text-transform: uppercase;
    padding: 3px;
    transition: all 0.8s;
  }
  & a:hover {
    transform: translate(0px, 0px);
  }
  & a:hover img {
    transform: rotate(360deg);
  }
  & a img {
    background-color: #fff;
    height: 30px;
    width: 30px;
    color: #000;
    text-align: center;
    line-height: 40px;
    border-radius: 50%;
    margin-right: 20px;
    transition: all 0.5s;
  }
`

const ChainsultingLink = styled.a`
  background-color: white;
  color: #2a87d0;
  display: flex;
  align-items: center;
`
const HaechiLink = styled.a`
  background-color: white;
  color: black;
  display: flex;
  align-items: center;
`

const AuditIcon: React.FC = () => {
  return (
    <Wrapper>
      <ChainsultingLink href="https://drive.google.com/file/d/1cei3INZN9lAjclgOYdnSj1_rULqMLXlC/view" target="_blank">
        <img src={ChainsultingIcon} alt="Chainsulting Audit Icon" />
        <Text textAlign="center" color="#2a87d0" fontSize="12px" bold>
          Chainsulting Audit
        </Text>
      </ChainsultingLink>
      <HaechiLink
        href="https://docs.google.com/document/d/1kYcVnRgmmxl8fGC7NGMRGD4F94k2Bh63OlCCE2Ni88k/edit?usp=sharing"
        target="_blank"
      >
        <img src={HaetchiIcon} alt="Haetchi Audit Icon" />
        <Text textAlign="center" fontSize="12px" bold>
          Haechi Audit
        </Text>
      </HaechiLink>
    </Wrapper>
  )
}

export default AuditIcon
