import { createReducer } from '@reduxjs/toolkit'
import { selectCurrency, selectSourceChain, setTransactionHash, resetCurrency } from './actions'
import { reset } from '../liquidity/actions'

export interface BuyBackAndBurnState {
  readonly currencyId: string
  readonly sourceChainId: number | null
  readonly transactionHash: string
}

const initialState: BuyBackAndBurnState = {
  currencyId: '',
  sourceChainId: null,
  transactionHash: '',
}

export default createReducer<BuyBackAndBurnState>(initialState, (builder) =>
  builder
    .addCase(selectCurrency, (state, { payload: { currencyId } }) => {
      state.currencyId = currencyId
    })
    .addCase(resetCurrency, (state) => {
      state.currencyId = ''
    })
    .addCase(reset, () => {
      return {
        currencyId: '',
        sourceChainId: null,
        transactionHash: '',
      }
    })
    .addCase(selectSourceChain, (state, { payload: { sourceChainId } }) => {
      return {
        ...state,
        sourceChainId,
      }
    })
    .addCase(setTransactionHash, (state, { payload: { transactionHash, sourceChainId } }) => {
      return {
        ...state,
        sourceChainId,
        transactionHash,
      }
    }),
)
