import { ContextApi } from 'contexts/Localization/types'
import { PageMeta } from './types'

export const DEFAULT_META: PageMeta = {
  title: 'Cross-Chain Bridge by Tixl | Connecting Blockchains',
  description:
    'The Cross-Chain Bridge is a decentralized & security-focused token & NFT bridge developed by the German Tixl Organization. Projects self-list their token; users provide liquidity, bridge or yield farm.',
  image: 'https://crosschainbridge.org/images/CrossChainBridge5Chains_OG.png',
}

export const getCustomMeta = (path: string, t: ContextApi['t']): PageMeta => {
  switch (path) {
    case '/':
      return {
        title: `${t('Cross-Chain Bridge')} | ${t('Connecting Blockchains')}`,
      }
    case '/home':
      return {
        title: `${t('Cross-Chain Bridge')} | ${t('Connecting Blockchains')}`,
      }
    case '/bridge/tokens':
      return {
        title: `${t('Bridge Tokens')} | ${t('Cross-Chain Bridge')}`,
      }
    case '/bridge/nfts':
      return {
        title: `${t('Bridge NFTs')} | ${t('Cross-Chain Bridge')}`,
      }
    case '/liquidity/manage':
      return {
        title: `${t('Add/Manage Liquidity')} | ${t('Cross-Chain Bridge')}`,
      }
    case '/mining':
      return {
        title: `${t('Liquidity Mining')} | ${t('Cross-Chain Bridge')}`,
      }
    case '/farms':
      return {
        title: `${t('Farms')} | ${t('Cross-Chain Bridge')}`,
      }
    case '/pools':
      return {
        title: `${t('Reward Pools')} | ${t('Cross-Chain Bridge')}`,
      }
    default:
      return null
  }
}
