import React from 'react'
import { useWeb3React } from '@web3-react/core'
import Button from '../../../theme/components/Button/Button'
import Text from '../../../theme/components/Text/Text'
import LinkExternal from '../../../theme/components/Link/LinkExternal'
import Flex from '../../../theme/components/Box/Flex'
import { Modal } from '../Modal'
import CopyToClipboard from './CopyToClipboard'
import { connectorLocalStorageKey } from './config'
import { getExplorerUri } from '../../../utils/getExplorerUri'

interface Props {
  account: string
  logout: () => void
  onDismiss?: () => void
  t: (key: string) => string
}

const AccountModal: React.FC<Props> = ({ account, logout, onDismiss = () => null, t }) => {
  const { chainId } = useWeb3React()

  return (
    <Modal title={t('Your wallet')} onDismiss={onDismiss}>
      <Text
        fontSize="20px"
        bold
        style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '8px' }}
      >
        {account}
      </Text>
      <Flex mb="32px">
        <LinkExternal small href={`${getExplorerUri(chainId, account)}`} mr="16px">
          {t('View on Explorer')}
        </LinkExternal>
        <CopyToClipboard toCopy={account}>{t('Copy Address')}</CopyToClipboard>
      </Flex>
      <Flex justifyContent="center">
        <Button
          scale="sm"
          variant="secondary"
          onClick={() => {
            logout()
            window.localStorage.removeItem(connectorLocalStorageKey)
            onDismiss()
          }}
        >
          {t('Logout')}
        </Button>
      </Flex>
    </Modal>
  )
}

export default AccountModal
