import { createReducer } from '@reduxjs/toolkit'
import { addClaimLink } from 'state/claims/actions'
import { speedUpTransaction } from 'state/transactions/actions'
import {
  reset,
  selectCollection,
  selectSourceChain,
  selectTargetChain,
  setDeposit,
  typeToken,
  typeRecipient,
  setApproval,
} from './actions'

export interface SwapState {
  readonly typedToken: string
  readonly typedRecipient: string
  readonly targetChainId: number | null
  readonly sourceChainId: number | null
  readonly collectionId: string
  readonly depositHash: string
  readonly approvalHash: string
}

const initialState = {
  typedToken: '',
  typedRecipient: '',
  collectionId: '',
  targetChainId: null,
  sourceChainId: null,
  depositHash: '',
  approvalHash: '',
}

export default createReducer<SwapState>(initialState, (builder) =>
  builder
    .addCase(reset, () => initialState)
    .addCase(addClaimLink, () => initialState)
    .addCase(typeToken, (state, { payload: { typedToken } }) => {
      return {
        ...state,
        typedToken,
      }
    })
    .addCase(typeRecipient, (state, { payload: { typedRecipient } }) => {
      return {
        ...state,
        typedRecipient,
      }
    })
    .addCase(selectCollection, (state, { payload: { collectionId } }) => {
      return {
        ...state,
        collectionId,
      }
    })
    .addCase(selectSourceChain, (state, { payload: { sourceChainId } }) => {
      return {
        ...state,
        sourceChainId,
      }
    })
    .addCase(selectTargetChain, (state, { payload: { targetChainId } }) => {
      return {
        ...state,
        targetChainId,
      }
    })
    .addCase(setDeposit, (state, { payload: { depositHash, sourceChainId } }) => {
      return {
        ...state,
        sourceChainId,
        depositHash,
      }
    })
    .addCase(setApproval, (state, { payload: { approvalHash } }) => {
      state.approvalHash = approvalHash
    })
    .addCase(speedUpTransaction, (state, { payload: { hash, toHash } }) => {
      if (state.depositHash === hash) {
        state.depositHash = toHash
      }
    }),
)
