import { useEffect, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useMoralisWeb3Api, useMoralis } from 'react-moralis'

export const useGetNftAssetsData = (collectionId: string) => {
  const [data, setData] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [fetchError, setFetchError] = useState(false)
  const { enableWeb3, isWeb3Enabled, chainId } = useMoralis()
  const { account } = useWeb3React()
  const Web3Api = useMoralisWeb3Api()

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (collectionId && chainId && account) {
          const options = {
            chain: chainId,
            address: account,
            // account that has nfts for testing: '0xf417ec5191575fa6e2d9f35911a659bdc040de7d',
            // address with lepasa collection: '0x79359AA2c520EF81Bd3a299b76AF62E8ba20ED70'
            token_address: collectionId,
            // limit must be less than 100
            limit: 99,
          }
          setIsLoading(true)
          // @ts-ignore
          const response = await Web3Api.account.getNFTsForContract(options)
          setData(response?.result)
          setIsLoading(false)
        }
      } catch (error) {
        console.error('Unable to fetch data:', error)
        setFetchError(true)
        setIsLoading(false)
      }
    }

    if (!isWeb3Enabled) {
      enableWeb3()
    } else {
      fetchData()
    }
  }, [collectionId, chainId, account, setData, setIsLoading, Web3Api.account, Web3Api.token, isWeb3Enabled, enableWeb3])
  return { data, isLoading, fetchError }
}
