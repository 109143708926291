import React from 'react'
import { Container, CurrencySelectButton, InputPanel, InputRow, LabelRow } from 'components/CurrencyInputPanel'
import styled from 'styled-components'
import { RowBetween } from 'components/Layout/Row'
import { ChevronDownIcon, Flex, useModal, Text } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { ChainId } from 'config/constants/chainId'
import { Collection } from 'config/constants/nftCollections'
import CollectionSearchModal from 'components/SearchModal/CollectionSearchModal'
import useCollection from 'hooks/useCollection'
import { Input as TokenInput } from './TokenInput'

interface CollectionInputPanelProps {
  id: string
  value: string
  onUserInput: (value: string) => void
  onCollectionSelect: (collection: Collection) => void
  label?: string
  chainId?: ChainId
  collectionId?: string
  disabled?: boolean
}

const StyledText = styled(Text)`
  @media only screen and (min-width: 430px) {
    font-size: 15px;
  }
  font-size: 11px;
`

export default function CollectionInputPanel({
  id,
  value,
  onUserInput,
  onCollectionSelect,
  label,
  chainId,
  disabled,
  collectionId,
}: CollectionInputPanelProps) {
  const { t } = useTranslation()
  const collection = useCollection(chainId, collectionId)
  const translatedLabel = label || t('Input')

  const [onPresentNetworkModal] = useModal(
    <CollectionSearchModal onCollectionSelect={onCollectionSelect} selectedCollection={collection} chainId={chainId} />,
  )

  return (
    <InputPanel id={id}>
      <Container hideInput={false}>
        <LabelRow>
          <RowBetween>
            <Text fontSize="14px" fontWeight="bold">
              {translatedLabel}
            </Text>
          </RowBetween>
        </LabelRow>
        <InputRow selected={false}>
          <TokenInput
            className="from-collection-tokenId-input"
            value={value || ''}
            onUserInput={(val) => {
              onUserInput(val)
            }}
          />

          <CurrencySelectButton
            disabled={disabled}
            selected={!!chainId}
            className="open-chain-select-button"
            onClick={() => {
              onPresentNetworkModal()
            }}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <StyledText id="pair">{collection ? collection.name : t('Select a collection')}</StyledText>
              <ChevronDownIcon />
            </Flex>
          </CurrencySelectButton>
        </InputRow>
      </Container>
    </InputPanel>
  )
}
