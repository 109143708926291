import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Text, InfoIcon } from '@pancakeswap/uikit'
import { Container, InputPanel, InputRow, LabelRow } from 'components/CurrencyInputPanel'
import { RowBetween } from 'components/Layout/Row'
import { useTranslation } from 'contexts/Localization'
import { isAddress, shortenAddress } from '../../utils'
import Button from '../../theme/components/Button/Button'
import { Input as AddressInput } from './AddressInput'
import { useBridgeActionHandlers } from '../../state/bridgeERC20/hooks'

interface AddressInputPanelProps {
  id: string
  value: string
  onUserInput?: (value: string) => void
  label?: string
  disabled?: boolean
  account?: string
  recipientWarning?: boolean
}

const RecipientAddress = styled.div`
  font-size: 16px;
  cursor: pointer;
`

const LabelButton = styled.button`
  border: 0;
  background-color: transparent;
  color: #0051ff;
  cursor: pointer;
  display: inline-block;
`

export const WarningLabelRow = styled(LabelRow)`
  border-radius: 12px;
  background-color: rgba(255, 0, 0, 0.1);
  padding: 12px;
  margin-top: 12px;
`

export default function AddressInputPanel({
  id,
  value,
  onUserInput,
  label,
  disabled,
  account,
  recipientWarning,
}: AddressInputPanelProps) {
  const { t } = useTranslation()
  // state to hide/unhide the form
  const [open, setOpen] = useState(false)

  // form has own local text state, that is updated when the user types
  const [localType, setLocalType] = useState(value)
  const translatedLabel = label || t('Input')

  const { onRecipientInput } = useBridgeActionHandlers()

  const customRecipientAddress = (address) => {
    if (isAddress(address)) {
      // only update parent state with valid address
      onUserInput(address)
      // update redux state directly
      onRecipientInput(address)
      setLocalType(address)
      setOpen(false)
    } else if (localType === '') {
      // default to own address if empty string is submitted
      onUserInput(account)
      setLocalType(account)
    }
  }

  // hide form on wallet disconnect
  useEffect(() => {
    if (!account) {
      setOpen(false)
    }
    setLocalType(account)
  }, [account])

  const showRecipientWarning = !open && recipientWarning && localType && account && account !== localType

  return (
    <InputPanel id={id}>
      <Container hideInput={false}>
        <LabelRow>
          <RowBetween>
            <Text fontSize="14px" fontWeight="bold">
              {translatedLabel}
              {!disabled && <LabelButton onClick={() => setOpen(!open)}>Change</LabelButton>}
            </Text>
            <RecipientAddress onClick={() => !disabled && setOpen(!open)}>
              {localType ? shortenAddress(localType) : '-'}
            </RecipientAddress>
          </RowBetween>
        </LabelRow>
        {open && (
          <InputRow selected>
            <AddressInput
              className="from-address-input"
              value={localType || ''}
              disabled={disabled}
              onUserInput={setLocalType}
            />
            <Button onClick={() => customRecipientAddress(localType)} height={25} px={2} ml={1}>
              Ok
            </Button>
          </InputRow>
        )}
        {showRecipientWarning && (
          <WarningLabelRow>
            <InfoIcon mr="10px" color="#FF0000" />
            <Text fontSize="14px">Recipient is not equal to your account address!</Text>
          </WarningLabelRow>
        )}
      </Container>
    </InputPanel>
  )
}
