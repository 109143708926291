import { useMemo } from 'react'
import { getBridgeSourceContract } from 'utils'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useAsync } from 'react-async-hook'

// Return the address of the wrapped native token for the active chain currency
export default function useWrappedNative(): { wrappedNative?: string } {
  const { account, chainId, library } = useActiveWeb3React()

  const contract = useMemo(
    () => getBridgeSourceContract(library, chainId, account || undefined),
    [library, chainId, account],
  )
  const { result: wrappedNative } = useAsync(async () => contract.wrappedNative(), [contract])

  return { wrappedNative }
}
