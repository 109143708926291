import { createReducer } from '@reduxjs/toolkit'
import { addAprForRewardPool, addAprForLiquidityMiningPool, clear, reset } from './actions'

export interface AprState {
  readonly liquidityMiningPoolsAprs: Record<string, number>
  readonly rewardPoolsAprs: Record<string, number>
}

const initialState = {
  liquidityMiningPoolsAprs: {},
  rewardPoolsAprs: {},
}

export default createReducer<AprState>(initialState, (builder) =>
  builder
    .addCase(clear, () => initialState)
    .addCase(reset, (state) => {
      return {
        liquidityMiningPoolsAprs: state.liquidityMiningPoolsAprs,
        rewardPoolsAprs: state.rewardPoolsAprs,
      }
    })
    .addCase(addAprForLiquidityMiningPool, (state, { payload }) => {
      return {
        liquidityMiningPoolsAprs: {
          ...state.liquidityMiningPoolsAprs,
          [payload.address]: payload.apr,
        },
        rewardPoolsAprs: state.rewardPoolsAprs,
      }
    })
    .addCase(addAprForRewardPool, (state, { payload }) => {
      return {
        liquidityMiningPoolsAprs: state.liquidityMiningPoolsAprs,
        rewardPoolsAprs: {
          ...state.rewardPoolsAprs,
          [payload.address]: payload.apr,
        },
      }
    }),
)
