import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('BridgeERC20'),
    icon: 'TradeIcon',
    href: '/bridge/tokens',
  },
  {
    label: t('BridgeNFTs'),
    icon: 'NftIcon',
    href: '/bridge/nfts',
  },
  {
    label: t('Manage Liquidity'),
    icon: 'LiquidityIcon',
    href: '/liquidity/manage',
  },
  {
    label: t('Liquidity Mining'),
    icon: 'GroupsIcon',
    href: '/mining',
  },
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: t('RewardPools'),
    icon: 'PoolIcon',
    href: '/pools',
  },
  {
    label: t('Buy Back & Burn'),
    icon: 'BurnIcon',
    href: '/buyback/stats',
  },
  {
    label: t('More'),
    icon: 'MoreIcon',
    items: [
      {
        label: t('Community'),
        href: 'https://discord.gg/5gMJpWQfSX',
      },
      {
        label: t('Support'),
        href: 'https://discord.gg/MMjK6gQZzs',
      },
      {
        label: t('Github'),
        href: 'https://github.com/crosschainbridge',
      },
      {
        label: t('Docs'),
        href: 'https://docs.crosschainbridge.org',
      },
      {
        label: t('Terms and Conditions'),
        href: 'https://drive.google.com/file/d/1k76mPutQmkW0erIFRObHY-B3zBS7PsU9/view',
      },
    ],
  },
]

export default config
