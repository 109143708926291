import { DYNAMIC_TOKENS_URL } from 'config'
import { ChainId } from './chainId'

export class Collection {
  public readonly name: string

  public readonly address: string

  public constructor(name: string, address: string) {
    this.name = name
    this.address = address
  }
}

const empty: Collection[] = []

const mainnet: Collection[] = [
  new Collection('Polychain Monsters (ETH)', '0x85f0e02cb992aa1f9f47112f815f519ef1a59e2d'),
  new Collection('Polychain Monsters (BSC)', '0x95c03e99076F222dA244E5cB29af355F989c6dD7'),
  new Collection('Meebits', '0x7Bd29408f11D2bFC23c34f18275bBf23bB716Bc7'),
  new Collection('Pudgy Penguins', '0xBd3531dA5CF5857e7CfAA92426877b022e612cf8'),
  new Collection('Genzees', '0x201675fBFAAAC3A51371E4C31FF73Ac14ceE2A5A'),
]

const bsc: Collection[] = [
  new Collection('Polychain Monsters (ETH)', '0x1d5da38BBe0dAf9bEe2f56C7483532D274527574'),
  new Collection('Polychain Monsters (BSC)', '0x85f0e02cb992aa1f9f47112f815f519ef1a59e2d'),
  new Collection('Polychain Monsters (POLY)', '0x6371cf15C5C9108BB0391bDF50E926A96e715966'),
  new Collection('Meebits', '0x31e5175481716C9BE2CCAF7a1954Db2D105a7993'),
  new Collection('Pudgy Penguins', '0xAEbC36e11e1B7020dFB14bf85Ab31646AcDD1a94'),
  new Collection('Genzees', '0xfb400B58E2051436EE489C04D0Ea5dAA4aAdfa53'),
  new Collection('Lepasa', '0x94e8b6373091b7569faf56c8c96b7dffedbc2d31'),
  new Collection('Newsverse', '0xbbC0F6422568b81F7EA87E60fbC3A95BecB8Db88'),
]

const polygon: Collection[] = [
  new Collection('Polychain Monsters (ETH)', '0xe5Bd4AEcBF9b1950f895DdB9173b1ac791581C76'),
  new Collection('Polychain Monsters (BSC)', '0x980C0B52b9681b961dBa9ba8695561de0f531de4'),
  new Collection('Polychain Monsters (POLY)', '0x85f0e02cb992aa1f9f47112f815f519ef1a59e2d'),
  new Collection('Meebits', '0x084310Bc7dB48eC8b57A22420Bd61181cF484f90'),
  new Collection('Pudgy Penguins', '0x34C9Aff1A1111BBd52E3BD207b3dC582dE94c2a7'),
  new Collection('Genzees', '0xeba6553e5017bc29903DcF950f0cdcfAcdB7ED1E'),
  new Collection('Lepasa', '0xab68703a01cf96e63d0c9975df250ae7b3118fe2'),
  new Collection('Newsverse', '0xa1d47c5c55e94764930b27a6185e83db372c116d'),
]

const fantom: Collection[] = [new Collection('Newsverse', '0x055F7b233544B9C952F50a7bB195CBCbB53be748')]

// todo: change to actual collection adresses
const local: Collection[] = [
  new Collection('Cryptopunks', '0x6DE20A08CE4A96dF381F8acD005E5Ce99Aff3D7D'),
  new Collection('Megamonsters', '0x6Caf628367f7AFd413EC2505d6C750312C954917'),
]
const rinkeby: Collection[] = [
  new Collection('Angry Apes', '0x027B77930182230FffA5D536a1dA829de8d27a50'),
  new Collection('Bored Bananas', '0x52f833cCA80C494956eCf418ae1623643979Dc52'),
  new Collection('Poly Punks', '0x58EAafA4d040e09388131a027BDE6a7595F3dE9c'),
  new Collection('Ether Rocks', '0xBc38B84e3019fD2518B0874f4F018526900D1DdE'),
  new Collection('Binablobs', '0x82Bb4942fb0f30818c47C55E77Fe0F17DA26B5Da'),
]
const bsctest: Collection[] = [
  new Collection('Angry Apes', '0x593823838e3676a1a2AEE0d3A9597798eBd0Cc84'),
  new Collection('Bored Bananas', '0x2e087c20b2540975A8E165b5af796aB6953982e3'),
  new Collection('Poly Punks', '0xe7ECA61b7917191b45A22dCa97734244d680d25A'),
  new Collection('Ether Rocks', '0xBd0843D815736E7531BE690C1851fA7beDC0841c'),
  new Collection('Binablobs', '0xDdF1fce9cdE0ee453C7859590AD06089F4962DE2'),
]

/*
const mumbai: Collection[] = [
  new Collection('Poly Punks', '0x3f23A65F12F56e8bc930fBF8D7758999Fe7b3E06'),
  new Collection('Ether Rocks', '0x9Ca4902B7903a082550A492005132cad6287e002'),
  new Collection('Binablobs', '0xdc74BACf8970E190707040a7f8AC24B18F52C057'),
]
*/

const collections: Record<ChainId, Collection[]> = {
  [ChainId.UNDEFINED]: empty,
  [ChainId.ETHEREUM]: mainnet,
  [ChainId.RINKEBY]: rinkeby,
  [ChainId.BSC]: bsc,
  [ChainId.BSCTEST]: bsctest,
  [ChainId.LETH]: local,
  [ChainId.LBSC]: local,
  [ChainId.POLYGON]: polygon,
  [ChainId.AVALANCHE]: empty,
  [ChainId.AVALANCHETEST]: empty,
  [ChainId.FANTOM]: fantom,
  [ChainId.AUTOBAHN]: empty,
}

export default function getCollections() {
  return collections
}

export function getCollection(sourceChainId: ChainId, addressOrName: string): Collection | undefined {
  if (!sourceChainId) return undefined

  return collections[sourceChainId].find((map) => map.address === addressOrName || map.name === addressOrName)
}

declare const ethereum: any

// Extend tokenlist from endpoint
async function extendCollectionsAsync() {
  const chainId = Number(ethereum?.chainId)

  if (!chainId) {
    console.error('no chainId found to extend collections')
    return
  }

  const url = `${DYNAMIC_TOKENS_URL}/nftCollections.json?chainId=${chainId}`
  const res = await fetch(url)

  if (!res.ok) {
    console.error(`Fetch ${url} failed with ${res.status}: ${await res.text()}`)
    return
  }

  const json = await res.json()

  if (!json.collections) {
    console.error('Dynamic collections returned empty response (no collections)')
    return
  }

  const addressMap = collections[chainId].map((coll: Collection) => coll.address)

  Object.keys(json.collections).forEach((item) => {
    const collection = json.collections[item]

    if (addressMap.contains(collection.address)) {
      console.error(`Cannot extend collections with ${collection.name} ${collection.address}: already exists`)
      return
    }

    console.info(`Add dynamic collection: ${collection.name} ${collection.address}`)
    collections[chainId].push(new Collection(collection.name, collection.address))
  })
}

// Calling this once per module load
extendCollectionsAsync().catch((err) => console.error('Dynamic collections', err))
