import BigNumber from 'bignumber.js'
import getPoolsConfig from 'config/constants/pools'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getStakingPoolsContract } from 'utils/contractHelpers'
import { ChainId } from '../../config/constants/chainId'
import { getRpcProvider } from '../../utils/providers'
import rewardPoolsABI from '../../config/abi/rewardPools.json'

export const fetchPoolsTotalStaking = async (chainId: ChainId) => {
  const callsPools = getPoolsConfig(chainId)
    .map((poolConfig) => {
      return {
        address: getAddress(poolConfig.contractAddress, chainId),
        name: 'rewardPools',
        params: [getAddress(poolConfig.earningToken.address, chainId)],
        pool: poolConfig,
      }
    })
    .filter((callParams) => callParams.address && callParams.address.length > 0 && callParams.params[0].length > 0)

  if (callsPools.length === 0) return []
  const provider = await getRpcProvider(chainId)

  let rewardPools
  if (provider.connection.url === 'metamask') {
    rewardPools = await multicall(rewardPoolsABI, callsPools, chainId)
  } else {
    rewardPools = await multicall(rewardPoolsABI, callsPools, chainId, provider)
  }

  return [
    ...callsPools.map((call, index) => ({
      rewardPoolId: call.pool.rewardPoolId,
      totalStaked: rewardPools[index].totalStakedAmount.toString(),
    })),
  ]
}

export const fetchPoolStakingLimit = async (rewardPoolId: number, chainId: ChainId): Promise<BigNumber> => {
  try {
    const defaultSigner = await getRpcProvider(chainId)
    const contract = getStakingPoolsContract(rewardPoolId, chainId, defaultSigner)
    const stakingLimit = await contract.poolLimitPerUser()
    return new BigNumber(stakingLimit.toString())
  } catch (error) {
    return BIG_ZERO
  }
}

export const fetchPoolsStakingLimits = async (
  poolsWithStakingLimit: number[],
  chainId: ChainId,
): Promise<{ [key: string]: BigNumber }> => {
  const validPools = getPoolsConfig(chainId)
    .filter((p) => p.stakingToken.symbol !== 'BNB' && !p.isFinished)
    .filter((p) => !poolsWithStakingLimit.includes(p.rewardPoolId))

  // Get the staking limit for each valid pool
  // Note: We cannot batch the calls via multicall because V1 pools do not have "poolLimitPerUser" and will throw an error
  const stakingLimitPromises = validPools.map((validPool) => fetchPoolStakingLimit(validPool.rewardPoolId, chainId))
  const stakingLimits = await Promise.all(stakingLimitPromises)

  return stakingLimits.reduce((accum, stakingLimit, index) => {
    return {
      ...accum,
      [validPools[index].rewardPoolId]: stakingLimit,
    }
  }, {})
}
