import React, { useRef, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Box, Heading, InjectedModalProps, ModalCloseButton, ModalHeader, ModalTitle } from '@pancakeswap/uikit'
import { FixedSizeList } from 'react-window'
import { ChainId } from 'config/constants/chainId'
import { useTranslation } from 'contexts/Localization'
import { networks } from 'config/constants/networks'
import styled from 'styled-components'
import { StyledModalBody, StyledModalContainer } from './CurrencySearchModal'
import NetworkList from './NetworkList'

export const StyledSubheading = styled.div`
  margin-top: 1rem;
  margin-left: 1rem;
`

interface NetworkSearchModalProps extends InjectedModalProps {
  availableNetworkIds?: ChainId[]
  selectedNetwork?: ChainId | null
  onNetworkSelect: (network: ChainId) => void
}

export default function NetworkSearchModal({
  availableNetworkIds,
  onDismiss = () => null,
  onNetworkSelect,
  selectedNetwork,
}: NetworkSearchModalProps) {
  const { t } = useTranslation()

  const { chainId } = useWeb3React()

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const handleSelection = (network: ChainId) => {
    onDismiss()
    onNetworkSelect(network)
  }

  const networksList =
    availableNetworkIds && availableNetworkIds.length > 0
      ? networks.filter((netw) => availableNetworkIds.indexOf(netw.chainId) > -1)
      : networks

  const networksSortedAlphabetically =
    networksList && networksList.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()))

  const networkName = useMemo(() => networks.find((netw) => netw.chainId === chainId), [chainId])

  return (
    <StyledModalContainer minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Select a network')}</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <StyledSubheading>
        You are currently using <span className="mx-auto">Crosschainbridge</span>
        <br /> on the <span className="font-bold">{networkName?.name}</span> network
      </StyledSubheading>
      <StyledModalBody>
        <Box margin="0 -24px">
          <NetworkList
            height={390}
            networks={networksSortedAlphabetically}
            onNetworkSelect={handleSelection}
            selectedNetwork={selectedNetwork}
            fixedListRef={fixedList}
          />
        </Box>
      </StyledModalBody>
    </StyledModalContainer>
  )
}
