import React from 'react'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import config from './config'
import { UserMenu } from './UserMenu'
import GlobalSettings from './GlobalSettings'
import { UikitMenu } from './UikitMenu'

export const Menu = ({ onNetworkSelect, id, ...props }) => {
  const { isDark, toggleTheme } = useTheme()

  const { currentLanguage, setLanguage, t } = useTranslation()

  return (
    <UikitMenu
      userMenu={<UserMenu id={id} onNetworkSelect={onNetworkSelect} />}
      globalMenu={<GlobalSettings />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      links={config(t)}
      {...props}
    />
  )
}
