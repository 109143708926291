import { StakingPoolConfig } from '../types'
import { ChainId } from '../chainId'
import getLPTokens, { getLPTokensList } from '../tokensLP'
import getTokens, { getTokensList } from '../tokens'
import { asyncExtendedPools } from '../asyncExtendedPools'

const contractAddress = {
  [ChainId.ETHEREUM]: '0x306fD816e0DeED33439Eb495F1857E1727A869D8',
  [ChainId.BSC]: '0x306fD816e0DeED33439Eb495F1857E1727A869D8',
  [ChainId.POLYGON]: '0x306fD816e0DeED33439Eb495F1857E1727A869D8',
  [ChainId.AVALANCHE]: '0xdb4Fa947191a2d4C77475797759662d5fAB9B81B',
  [ChainId.FANTOM]: '0x3FD1EcbFd9ec0c3bdEA196657fD917b1998Ecce4',
  [ChainId.AUTOBAHN]: '0x3FD1EcbFd9ec0c3bdEA196657fD917b1998Ecce4',
}

let pools

function initialPools() {
  const lpTokens = getLPTokens()
  const tokens = getTokens()

  pools = [
    {
      stakingPoolId: 1,
      stakingToken: lpTokens.usdc,
      earningToken: tokens.usdc,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 2,
      stakingToken: lpTokens.usdt,
      earningToken: tokens.usdt,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 0,
      stakingToken: lpTokens.bridge,
      earningToken: tokens.bridge,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 3,
      stakingToken: lpTokens.weth,
      earningToken: tokens.weth,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 18,
      stakingToken: lpTokens.daf,
      earningToken: tokens.daf,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
  ]
}

export default function getPools(): StakingPoolConfig[] {
  if (!pools) {
    initialPools()
  }

  return pools
}

asyncExtendedPools(
  'liquidityMining',
  'stakingPoolId',
  ChainId.FANTOM,
  contractAddress,
  getPools,
  getLPTokensList,
  getTokensList,
  (pool) => pools.push(pool),
).catch((err) => console.error('Dynamic pools', err))
