import BigNumber from 'bignumber.js'
import getStakingPools from 'config/constants/stakingPools'
// import liquidityMiningABI from 'config/abi/liquidityMining.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import { BIG_ZERO } from 'utils/bigNumber'
import { getStakingPoolsContract } from 'utils/contractHelpers'
import { ChainId } from '../../config/constants/chainId'
import { getRpcProvider } from '../../utils/providers'

export const fetchPoolsTotalStaking = async (chainId: ChainId) => {
  const calls = getStakingPools(chainId)
    .map((poolConfig) => {
      return {
        address: getAddress(poolConfig.stakingToken.address, chainId),
        name: 'balanceOf',
        params: [getAddress(poolConfig.contractAddress, chainId)],
        pool: poolConfig,
      }
    })
    .filter((callParams) => callParams.address && callParams.address.length > 0 && callParams.params[0].length > 0)

  if (calls.length === 0) return []
  const provider = await getRpcProvider(chainId)

  let poolsTotalStaked
  if (provider.connection.url === 'metamask') {
    poolsTotalStaked = await multicall(erc20ABI, calls, chainId)
  } else {
    poolsTotalStaked = await multicall(erc20ABI, calls, chainId, provider)
  }

  return [
    ...calls.map((call, index) => ({
      stakingPoolId: call.pool.stakingPoolId,
      totalStaked: new BigNumber(poolsTotalStaked[index]).toJSON(),
    })),
  ]
}

export const fetchPoolStakingLimit = async (stakingPoolId: number, chainId: ChainId): Promise<BigNumber> => {
  try {
    const defaultSigner = await getRpcProvider(chainId)
    const contract = getStakingPoolsContract(stakingPoolId, chainId, defaultSigner)
    const stakingLimit = await contract.poolLimitPerUser()
    return new BigNumber(stakingLimit.toString())
  } catch (error) {
    return BIG_ZERO
  }
}

export const fetchPoolsStakingLimits = async (
  poolsWithStakingLimit: number[],
  chainId: ChainId,
): Promise<{ [key: string]: BigNumber }> => {
  const validPools = getStakingPools(chainId)
    .filter((p) => p.stakingToken.symbol !== 'BNB' && !p.isFinished)
    .filter((p) => !poolsWithStakingLimit.includes(p.stakingPoolId))

  // Get the staking limit for each valid pool
  // Note: We cannot batch the calls via multicall because V1 pools do not have "poolLimitPerUser" and will throw an error
  const stakingLimitPromises = validPools.map((validPool) => fetchPoolStakingLimit(validPool.stakingPoolId, chainId))
  const stakingLimits = await Promise.all(stakingLimitPromises)

  return stakingLimits.reduce((accum, stakingLimit, index) => {
    return {
      ...accum,
      [validPools[index].stakingPoolId]: stakingLimit,
    }
  }, {})
}
