import { createAction } from '@reduxjs/toolkit'

export type ClaimLinkInfo = {
  type: 'erc20' | 'erc721'
  depositHash: string
  sourceChainId: number
  targetChainId: number
  currencyId?: string
  typedAmount?: string
  createdAt?: number
}

export const reset = createAction('claims/reset')
export const addClaimLink = createAction<{ info: ClaimLinkInfo }>('claims/addClaimLink')
export const removeClaimLink = createAction<{ depositHash: string }>('claims/removeClaimLink')
