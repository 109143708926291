import React, { CSSProperties, MutableRefObject, useCallback } from 'react'
import { Text } from '@pancakeswap/uikit'
import { FixedSizeList } from 'react-window'
import Column from 'components/Layout/Column'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import getCollections, { Collection } from 'config/constants/nftCollections'
import { MenuItem } from './CurrencyList'

function CollectionRow({
  collection,
  onSelect,
  isSelected,
  style,
}: {
  collection: Collection
  onSelect: () => void
  isSelected: boolean
  style: CSSProperties
}) {
  return (
    <MenuItem
      style={style}
      className={`network-item-${collection.address}`}
      onClick={() => (isSelected ? null : onSelect())}
      disabled={isSelected}
      selected={false}
    >
      <Column>
        <Text bold>{collection.name}</Text>
      </Column>
    </MenuItem>
  )
}

interface CollectionListProps {
  height: number
  chainId: number
  selectedCollection?: Collection | null
  onCollectionSelect: (collection: Collection) => void
  fixedListRef?: MutableRefObject<FixedSizeList | undefined>
}

export default function CollectionList({
  height,
  chainId,
  selectedCollection,
  onCollectionSelect,
  fixedListRef,
}: CollectionListProps) {
  const { chainId: connectedChainId } = useActiveWeb3React()

  // fallback to connected chain id to show some collections
  const collections = getCollections()
  const itemData = collections[chainId || connectedChainId]

  const Row = useCallback(
    ({ data, index, style }) => {
      const collection: any = data[index]
      const isSelected = Boolean(selectedCollection && selectedCollection === collection)
      const handleSelect = () => onCollectionSelect(collection)

      return <CollectionRow style={style} collection={collection} isSelected={isSelected} onSelect={handleSelect} />
    },
    [onCollectionSelect, selectedCollection],
  )

  const itemKey = useCallback((index: number) => itemData[index].address, [itemData])

  return (
    <FixedSizeList
      height={height}
      ref={fixedListRef as any}
      width="100%"
      itemData={itemData}
      itemCount={itemData?.length}
      itemSize={56}
      itemKey={itemKey}
    >
      {Row}
    </FixedSizeList>
  )
}
