import { useMemo } from 'react'
import { ChainId } from 'config/constants/chainId'
import { CurrencyAmount } from '@pancakeswap/sdk'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { TokenAmount } from 'sdk/entities/tokenAmount'
import { getTokenAddress } from 'config/constants/tokens'
import { isNativeSymbol } from 'sdk/entities/currency'
import { calculateGasMargin, getBridgeSourceContract } from '../../utils'
import { useTransactionAdder } from '../transactions/hooks'
import { useBridgingFees } from './useBridgingFees'
import { useBridgingLiquidity } from './useBridgingLiquidity'
import { Token } from '../../sdk/entities/token'
import useWrappedNative from './useWrappedNative'

interface ReturnValue {
  bridgingFees: TokenAmount | undefined
  bridgingLiquidity: TokenAmount | string | undefined
  callback: null | (() => Promise<string>)
  error: string | null
}

export function useBridgeDepositCallback(
  token: Token,
  amount: CurrencyAmount | undefined,
  targetAccountAddress: string,
  targetChainId: ChainId,
): ReturnValue {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const { wrappedNative } = useWrappedNative()
  const isNative = isNativeSymbol(token?.symbol)

  const sourceTokenAddress = useMemo(() => {
    if (isNative) {
      return wrappedNative
    }

    return token ? token.address : undefined
  }, [token, isNative, wrappedNative])
  const targetTokenAddress = useMemo(() => getTokenAddress(token, targetChainId), [token, targetChainId])

  const contract = getBridgeSourceContract(library, chainId, account || undefined)
  const bridgingFees = useBridgingFees(amount, targetTokenAddress, targetChainId)
  const bridgingLiquidity = useBridgingLiquidity(sourceTokenAddress, targetChainId)

  return useMemo(() => {
    if (!account || !chainId) {
      return {
        bridgingFees: undefined,
        bridgingLiquidity: undefined,
        callback: null,
        error: 'No wallet connected.',
      }
    }

    if (!contract) {
      return {
        bridgingFees: undefined,
        bridgingLiquidity: undefined,
        callback: null,
        error: 'Bridge contract not found',
      }
    }

    return {
      bridgingFees,
      bridgingLiquidity,
      error: null,
      callback: async function onBridgeDeposit(): Promise<string> {
        const callMethod = isNative ? 'depositNative' : 'depositERC20'
        const value = amount?.raw.toString()
        const inputs = isNative
          ? [value, targetAccountAddress, targetChainId]
          : [sourceTokenAddress, value, targetAccountAddress, targetChainId]
        const args = isNative ? { value, from: account } : { from: account }

        try {
          const gasEstimate = await contract.estimateGas[callMethod](...inputs, args)
          const gasLimit = calculateGasMargin(gasEstimate)

          return contract[callMethod](...inputs, {
            gasLimit,
            ...args,
          }).then((response: any) => {
            addTransaction(response)
            return response.hash
          })
        } catch (gasError) {
          return contract.callStatic[callMethod](...inputs, args)
            .catch((error) => {
              console.debug(error)
              throw new Error(error.data.message)
            })
            .then((result) => {
              console.debug('Unexpected successful call after failed estimate gas', gasError, result)
              throw new Error('Unexpected issue with estimating the gas. Please try again.')
            })
        }
      },
    }
  }, [
    account,
    bridgingFees,
    chainId,
    contract,
    sourceTokenAddress,
    bridgingLiquidity,
    isNative,
    amount,
    targetAccountAddress,
    targetChainId,
    addTransaction,
  ])
}
