import { Currency } from '@pancakeswap/sdk'
import { useEffect, useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { Token } from 'sdk/entities/token'
import { TokenAmount } from 'sdk/entities/tokenAmount'
import { addPosition } from 'state/liquidity/actions'
import { useSingleCallResult } from 'state/multicall/hooks'
import { useTokenBalance } from 'state/wallet/hooks'
import { getLiquidityManagerContract, isAddress } from 'utils'
import isZero from 'utils/isZero'
import { useToken } from './Tokens'
import useActiveWeb3React from './useActiveWeb3React'

export enum LiquidityPositionState {
  LOADING,
  NOT_EXISTS,
  EXISTS,
}

export default function useLiquidityPosition(
  currency: Currency,
  onlyAddWithPosition = false,
): [LiquidityPositionState, Token | null, TokenAmount | null] {
  const { account, chainId, library } = useActiveWeb3React()
  const dispatch = useDispatch()
  const selectedToken = currency as Token

  const contract = getLiquidityManagerContract(library, chainId, account)

  const inputs = useMemo(() => [selectedToken?.address], [selectedToken])
  const lpLookup = useSingleCallResult(contract, 'lpTokens', inputs)

  const lpTokenAddress = useMemo(() => {
    if (!lpLookup || !Array.isArray(lpLookup.result)) return undefined

    const lpLookupRes = lpLookup.result[1]

    if (!lpLookupRes) return undefined
    if (!isAddress(lpLookupRes)) return undefined
    if (isZero(lpLookupRes)) return undefined

    return lpLookupRes
  }, [lpLookup])

  const lpToken = useToken(lpTokenAddress)

  const position: TokenAmount | undefined = useTokenBalance(account ?? undefined, lpToken)

  useEffect(() => {
    if (!position || !lpToken) return

    // flag to only add the position to the watch list, if the position is greater than 0
    if (onlyAddWithPosition && !position.greaterThan('0')) return

    // add to watch list for positions
    dispatch(
      addPosition({
        chainId,
        lpToken: lpToken.address,
        token: selectedToken.address,
      }),
    )
  }, [dispatch, chainId, lpToken, position, selectedToken, onlyAddWithPosition])

  return useMemo(() => {
    if (lpLookup.loading) return [LiquidityPositionState.LOADING, null, null]

    if (lpToken) {
      // lp token found
      return [LiquidityPositionState.EXISTS, lpToken, position]
    }

    // no lp token issued yed
    return [LiquidityPositionState.NOT_EXISTS, null, null]
  }, [lpLookup, lpToken, position])
}
