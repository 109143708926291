import fetch from 'node-fetch'

import { ChainId } from '../config/constants/chainId'
import getAddresses from './getAddresses'

export async function getReleaseParamsERC721(chainId: ChainId, txHash: string) {
  return getReleaseParams(chainId, txHash, 'BridgeV2ERC721')
}

export async function getReleaseParamsERC20(chainId: ChainId, txHash: string) {
  return getReleaseParams(chainId, txHash, 'BridgeV2ERC20')
}

export async function getReleaseParams(
  chainId: ChainId,
  txHash: string,
  bridgeType: 'BridgeV2ERC721' | 'BridgeV2ERC20',
): Promise<any> {
  const addresses = getAddresses(chainId)

  if (!addresses) return undefined

  const bridgeAddress = addresses[bridgeType]
  const nodeUrls = addresses.Oracles[bridgeType]
  const errors = []

  const unsortedResults = await Promise.all(
    nodeUrls.map(async (url: string) => {
      const res = await fetch(`${url.replace(/\/$/, '')}/${chainId}/${bridgeAddress}/release-signature/${txHash}`)

      if (!res.ok) {
        const err = new Error(`node ${url} failed with ${res.status}: ${await res.text()}`)

        console.error(err)
        errors.push(err)

        return err
      }

      return (await res.json()).result
    }),
  )

  if (errors.length) {
    // pick first, all oracles return the same error
    throw new Error(`Oracle error: ${errors[0]}`)
  }

  const results: any = unsortedResults
    // @ts-ignore
    .filter(({ txParams }) => txParams)
    .sort((a: any, b: any) => {
      if (a.oracleAddress > b.oracleAddress) {
        return 1
      }
      if (a.oracleAddress < b.oracleAddress) {
        return -1
      }
      return 0
    })

  if (results.length === 0) {
    throw new Error('Oracles did not return a result.')
  }

  const params = results[0].txParams

  // eslint-disable-next-line
  for (const { txParams } of results.slice(1)) {
    params[0].push(...txParams[0])
    params[1].push(...txParams[1])
    params[2].push(...txParams[2])
    for (let i = 3; i < params.length; i += 1) {
      if (params[i] !== txParams[i]) {
        throw new Error(`mismatched tx params`)
      }
    }
  }

  return params
}

export function rewordError(err?: string): string | undefined {
  if (!err) return undefined

  // NFT
  if (err.includes('owner query for nonexistent token'))
    return 'You either entered a wrong ID or Collection or this NFT is not in your wallet.'
  if (err.includes('approve caller is not owner nor approved'))
    return 'You either entered a wrong ID or Collection or this NFT is not in your wallet.'

  // ERC20
  if (err.includes('already processed')) return 'Deposit was already processed and released.'
  if (err.includes('Oracle') && err.includes('transaction not found'))
    return 'The oracles reported an error: The transaction was not found.'
  if (err.includes('Oracle') && err.includes('invalid hash'))
    return 'The oracles reported an error: The transaction hash is invalid.'
  if (err.includes('Oracle') && err.includes('not enough confirmations'))
    return 'The oracles reported an error: The transaction has not enough confirmations.'
  if (err.includes('Oracle') && err.includes('transaction is not whitelisted. Please contact the support'))
    return 'The oracles reported an error: The transaction is not whitelisted. Please contact the support.'

  return err
}
