import React from 'react'
import { Web3ReactProvider } from '@web3-react/core'
import { ModalProvider as PancakeModalProvider } from '@pancakeswap/uikit'
import { MoralisProvider } from 'react-moralis'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { ThemeProvider } from 'styled-components'
import { useThemeManager } from './state/user/hooks'
import { getLibrary } from './utils/web3React'
import { LanguageProvider } from './contexts/Localization'
import { RefreshContextProvider } from './contexts/RefreshContext'
import { ToastsProvider } from './contexts/ToastsContext'
import store from './state'
import ModalProvider from './components/ConnectWalletModal/Modal/ModalContext'
import { light, dark } from './theme'

const ThemeProviderWrapper = (props) => {
  const [isDark] = useThemeManager()
  return <ThemeProvider theme={isDark ? dark : light} {...props} />
}

const MORALIS_APP_ID = process.env.REACT_APP_MORALIS_APPLICATION_ID
const MORALIS_SERVER_URL = process.env.REACT_APP_MORALIS_SERVER_URL

const Providers: React.FC = ({ children }) => {
  return (
    <Provider store={store}>
      <Web3ReactProvider getLibrary={getLibrary}>
        <ToastsProvider>
          <HelmetProvider>
            <MoralisProvider appId={MORALIS_APP_ID} serverUrl={MORALIS_SERVER_URL}>
              <ThemeProviderWrapper>
                <LanguageProvider>
                  <RefreshContextProvider>
                    <PancakeModalProvider>
                      <ModalProvider>{children}</ModalProvider>
                    </PancakeModalProvider>
                  </RefreshContextProvider>
                </LanguageProvider>
              </ThemeProviderWrapper>
            </MoralisProvider>
          </HelmetProvider>
        </ToastsProvider>
      </Web3ReactProvider>
    </Provider>
  )
}

export default Providers
