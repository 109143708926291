import React from 'react'
import styled from 'styled-components'
import { Flex } from '@pancakeswap/uikit'
import { useLocation } from 'react-router'
import { Helmet } from 'react-helmet-async'
import HomeBackground from './Home/images/Leicht.svg'
import { useTranslation } from '../contexts/Localization'
import { DEFAULT_META, getCustomMeta } from '../config/constants/meta'

const StyledPage = styled.div`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url(${HomeBackground});
    background-position: center;
    background-size: 3000px 4000px;
    opacity: 0.3;
    z-index: -1;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  padding-bottom: 0;
  padding-top: 32px;
  min-height: calc(100vh - 64px);

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 48px;
    padding-bottom: 0;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding-top: 64px;
    min-height: calc(100vh - 64px);
  }
`
const PageMeta = () => {
  const { t } = useTranslation()

  let { title, description, image } = DEFAULT_META
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { pathname } = useLocation()
    const pageMeta = getCustomMeta(pathname, t) || {}
    const mergedMeta = { ...DEFAULT_META, ...pageMeta }

    // eslint-disable-next-line prefer-destructuring
    title = mergedMeta.title
    // eslint-disable-next-line prefer-destructuring
    description = mergedMeta.description
    // eslint-disable-next-line prefer-destructuring
    image = mergedMeta.image
  } catch (err) {
    console.debug('PageMeta failed')
  }
  const pageTitle = title

  return (
    <Helmet>
      <title>{pageTitle}</title>
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
    </Helmet>
  )
}

const Page: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({ children, ...props }) => {
  return (
    <StyledPage {...props}>
      <PageMeta />
      {children}
      <Flex flexGrow={1} />
    </StyledPage>
  )
}

export default Page
