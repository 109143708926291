import { useMemo } from 'react'
import { Contract, getDefaultProvider } from 'ethers'
import { AddressZero } from '@ethersproject/constants'
import { useAsync } from 'react-async-hook'
import { TokenAmount } from '../../sdk/entities/tokenAmount'
import { ChainId } from '../../config/constants/chainId'
import networkUrls from '../../config/constants/networks'
import getAddresses from '../../utils/getAddresses'
import ERC20_ABI from '../../config/abi/erc20.json'
import { getToken } from '../../config/constants/tokens'
import CROSSCHAIN_BRIDGE_ERC20_ABI from '../../config/abi/CrossChainBridgeERC20.json'
import { Token } from '../../sdk/entities/token'

export function useBridgingLiquidity(tokenAddress?: string, targetChainId?: ChainId): TokenAmount | string | undefined {
  // setup an RPC provider for the target chain
  const targetNetworkProvider = useMemo(() => {
    if (!targetChainId) {
      return undefined
    }
    return getDefaultProvider(networkUrls[targetChainId])
  }, [targetChainId])

  // we need the ID of the token on the target chain
  const bridgeContract = useMemo(() => {
    if (!targetChainId) {
      return undefined
    }

    return new Contract(getAddresses(targetChainId).BridgeV2ERC20, CROSSCHAIN_BRIDGE_ERC20_ABI, targetNetworkProvider)
  }, [targetChainId, targetNetworkProvider])

  const { result: targetChainTokenAddress } = useAsync(async () => {
    if (!tokenAddress) return undefined

    try {
      const mappedTokenAddress = await bridgeContract.callStatic.outsidePeggedTokens(tokenAddress)
      return mappedTokenAddress === AddressZero ? tokenAddress : mappedTokenAddress
    } catch (err) {
      console.warn('ERROR/useBridgingLiquidity/targetChainTokenAddress', err)
      try {
        const mappedTokenAddress2 = await bridgeContract.callStatic.outsidePeggedTokens(tokenAddress)
        return mappedTokenAddress2 === AddressZero ? tokenAddress : mappedTokenAddress2
      } catch (retryError) {
        console.error('ERROR/useBridgingLiquidity/targetChainTokenAddress/retry', retryError)
        return undefined
      }
    }
  }, [bridgeContract, tokenAddress])

  // let's prepare the token
  const token = useMemo(() => {
    if (!targetChainTokenAddress) {
      return undefined
    }
    return (
      getToken(targetChainId, targetChainTokenAddress) ||
      new Token(targetChainId, targetChainTokenAddress, 18, '<SYMBOL>', '')
    )
  }, [targetChainId, targetChainTokenAddress])

  // build up the contract for the target chain
  const tokenContract = useMemo(() => {
    if (!token) {
      return undefined
    }
    return new Contract(token.address, ERC20_ABI, targetNetworkProvider)
  }, [token, targetNetworkProvider])

  const { result: tokenBalanceOfBridge } = useAsync(async () => {
    if (!tokenContract) {
      return `⚠️ Couldn't fetch balance of ${token.symbol} on target network. Please verify yourself that there is a sufficient liquidity available: `
    }
    const bridgeAddress = getAddresses(targetChainId).BridgeV2ERC20

    try {
      const balance = await tokenContract.callStatic.balanceOf(bridgeAddress)

      return new TokenAmount(token, balance)
    } catch (err) {
      console.warn('ERROR/useBridgingLiquidity/tokenBalanceOfBridge', err)
      try {
        const balance = await tokenContract.callStatic.balanceOf(bridgeAddress)
        return new TokenAmount(token, balance)
      } catch (retryError) {
        console.error('ERROR/useBridgingLiquidity/tokenBalanceOfBridge/retry', retryError)
        return `⚠️ Couldn't fetch balance of ${token.symbol} on target network. Please verify yourself that there is a sufficient liquidity available: `
      }
    }
  }, [tokenContract])

  // let's get the collectedUnsentFees from bridge contract
  // for the token and LiquidityMining

  const { result: collectedUnsentFeesFromLiquidityMiningThisToken } = useAsync(async () => {
    if (!bridgeContract) {
      return undefined
    }
    if (!targetChainTokenAddress) {
      return undefined
    }

    const bridgeV2LiquidityMiningPoolsAddress = getAddresses(targetChainId).BridgeV2LiquidityMiningPools

    try {
      const collectedUnsentFeeOfToken = await bridgeContract.callStatic.collectedUnsentFees(
        targetChainTokenAddress,
        bridgeV2LiquidityMiningPoolsAddress,
      )
      return new TokenAmount(token, collectedUnsentFeeOfToken)
    } catch (err) {
      console.warn('ERROR/useBridgingLiquidity/collectedUnsentFeesFromLiquidityMiningThisToken', err)
      try {
        const collectedUnsentFeeOfToken = await bridgeContract.callStatic.collectedUnsentFees(
          targetChainTokenAddress,
          bridgeV2LiquidityMiningPoolsAddress,
        )
        return new TokenAmount(token, collectedUnsentFeeOfToken)
      } catch (retryError) {
        console.error('ERROR/useBridgingLiquidity/collectedUnsentFeesFromLiquidityMiningThisToken/retry', retryError)
        return `⚠️ Couldn't fetch balance of ${token.symbol} on target network. Please verify yourself that there is a sufficient liquidity available: `
      }
    }
  }, [tokenContract, bridgeContract])

  // let's get the collectedUnsentFees from bridge contract
  // for the token and Reward Pools

  const { result: collectedUnsentFeesFromRewardPoolsThisToken } = useAsync(async () => {
    if (!bridgeContract) {
      return undefined
    }
    if (!targetChainTokenAddress) {
      return undefined
    }

    const bridgeV2RewardPoolsAddress = getAddresses(targetChainId).BridgeV2RewardPools

    try {
      const collectedUnsentFeeOfToken = await bridgeContract.callStatic.collectedUnsentFees(
        targetChainTokenAddress,
        bridgeV2RewardPoolsAddress,
      )
      return new TokenAmount(token, collectedUnsentFeeOfToken)
    } catch (err) {
      console.warn('ERROR/useBridgingLiquidity/collectedUnsentFeesFromRewardPoolsThisToken', err)
      try {
        const collectedUnsentFeeOfToken = await bridgeContract.callStatic.collectedUnsentFees(
          targetChainTokenAddress,
          bridgeV2RewardPoolsAddress,
        )
        return new TokenAmount(token, collectedUnsentFeeOfToken)
      } catch (retryError) {
        console.error('ERROR/useBridgingLiquidity/collectedUnsentFeesFromRewardPoolsThisToken/retry', retryError)
        return `⚠️ Couldn't fetch balance of ${token.symbol} on target network. Please verify yourself that there is a sufficient liquidity available: `
      }
    }
  }, [tokenContract, bridgeContract])

  if (!collectedUnsentFeesFromLiquidityMiningThisToken) {
    return undefined
  }
  if (!collectedUnsentFeesFromRewardPoolsThisToken) {
    return undefined
  }
  // let's subtract both fees from the overall liquidity
  // this will reflect more accurately the actual liquidity a user can use
  let subtractedFeesFromTokenBalanceOfBridge

  if (
    collectedUnsentFeesFromLiquidityMiningThisToken instanceof TokenAmount &&
    collectedUnsentFeesFromRewardPoolsThisToken instanceof TokenAmount &&
    tokenBalanceOfBridge instanceof TokenAmount
  ) {
    const sumOfUnsentFeesLiqudityMiningAndRewardPools = collectedUnsentFeesFromLiquidityMiningThisToken.add(
      collectedUnsentFeesFromRewardPoolsThisToken,
    )
    subtractedFeesFromTokenBalanceOfBridge = tokenBalanceOfBridge.subtract(sumOfUnsentFeesLiqudityMiningAndRewardPools)
  }

  return subtractedFeesFromTokenBalanceOfBridge
}
