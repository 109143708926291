import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

interface LogoProps extends SvgProps {
  isDark: boolean
}

const Logo: React.FC<LogoProps> = () => {
  return (
    <Svg viewBox="0 0 210.448 210.448" width="120">
      <g>
        <g>
          <rect
            x="76.245"
            y="39.46"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -63.2809 97.0656)"
            fill="#0050FF"
            width="18.567"
            height="170.918"
          />
        </g>
        <g>
          <rect
            x="115.636"
            y="0.069"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -23.8894 113.3821)"
            fill="#0050FF"
            width="18.567"
            height="170.918"
          />
        </g>
        <g>
          <rect
            x="11.229"
            y="103.187"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -62.3691 74.3683)"
            fill="#1A1A1E"
            width="94.714"
            height="18.567"
          />
        </g>
        <g>
          <rect
            x="104.479"
            y="88.705"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -24.8116 136.0751)"
            fill="#1A1A1E"
            width="94.744"
            height="18.567"
          />
        </g>
        <g>
          <rect
            x="112.617"
            y="29.626"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 11.1921 104.8384)"
            fill="#1A1A1E"
            width="39.06"
            height="18.567"
          />
        </g>
        <g>
          <rect
            x="58.773"
            y="162.261"
            transform="matrix(0.7071 -0.7071 0.7071 0.7071 -98.3684 105.6068)"
            fill="#1A1A1E"
            width="39.043"
            height="18.567"
          />
        </g>
      </g>
    </Svg>
  )
}

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark)
