import React, { useMemo, useEffect } from 'react'
import { useTranslation } from 'contexts/Localization'
// import Swiper core and required modules
import SwiperCore, { Navigation, Pagination } from 'swiper'

import { Swiper, SwiperSlide } from 'swiper/react'
// swiper bundle styles
import 'swiper/swiper-bundle.min.css'

// swiper core styles
import 'swiper/swiper.min.css'

// modules styles
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'

// custom styles
import './swiperSliedesStyles.css'

import styled from 'styled-components'
import { Flex, InfoIcon, Text } from '@pancakeswap/uikit'
import { AutoRow } from 'components/Layout/Row'
import CircleLoader from '../../../components/Loader/CircleLoader'
import { useGetNftAssetsData } from '../../../hooks/useGetNftAssetsData'
import { ChainId } from '../../../config/constants/chainId'
import { networks } from '../../../config/constants/networks'
import { LabelRow } from '../../../components/CurrencyInputPanel'
import imagePlaceholder from './no-image-placeholder.png'

export const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
`

interface NftImgContainerProps {
  isActive: boolean
}

export const NftImgContainer = styled.img<NftImgContainerProps>`
  border-color: #0050ff;
  border-radius: 10px;
  border-style: solid;
  border-width: ${({ isActive }) => (isActive ? '3px' : '1px')};
  height: 100px;
  width: 100px;
  object-fit: cover;
  display: block;
  opacity: ${({ isActive }) => (isActive ? '1' : '0.5')};
`

SwiperCore.use([Navigation, Pagination])

interface EachNftAssetProps {
  isActive: boolean
  imageUrl: string
  nftAssetId: string
  onNftAssetSelect: (value: string) => void
}

function EachNftAsset({ isActive, imageUrl, nftAssetId, onNftAssetSelect }: EachNftAssetProps) {
  useEffect(() => {
    if (nftAssetId && isActive) {
      onNftAssetSelect(isActive && nftAssetId)
    }
  }, [isActive, nftAssetId, onNftAssetSelect])

  return <NftImgContainer isActive={isActive} src={imageUrl} />
}

const WarningLabelRow = styled(LabelRow)`
  border-radius: 12px;
  background-color: rgba(255, 0, 0, 0.1);
  padding: 12px;
`

interface NftCollectionAssetsProps {
  onNftAssetSelect: (value: string) => void
  collectionId?: any
  chainId?: ChainId
}

const NftCollectionAssetsSlides = ({ onNftAssetSelect, collectionId, chainId }: NftCollectionAssetsProps) => {
  const { data, isLoading, fetchError } = useGetNftAssetsData(collectionId)
  const { t } = useTranslation()
  const networkName = useMemo(() => networks.find((netw) => netw.chainId === chainId), [chainId])

  return (
    <>
      {isLoading ? (
        <div style={{ minHeight: '125px' }}>
          <AutoRow justify="center" style={{ padding: '0.2rem 1rem' }}>
            <Flex mb="20px" mt="20px">
              <CircleLoader size="48px" />
            </Flex>
          </AutoRow>
        </div>
      ) : fetchError ? (
        <div style={{ minHeight: '125px' }}>
          <AutoRow style={{ padding: '0.2rem 1rem' }}>
            <Flex mb="20px" mt="20px">
              <WarningLabelRow>
                <InfoIcon mr="10px" color="#FF0000" />
                <Text fontSize="14px" textAlign="center">
                  {t('We have encountered an error while getting your NFTs')}
                </Text>
              </WarningLabelRow>
            </Flex>
          </AutoRow>
        </div>
      ) : data.length === 0 && chainId ? (
        <div style={{ minHeight: '125px' }}>
          <AutoRow style={{ padding: '0.2rem 1rem' }}>
            <Flex mb="20px" mt="20px">
              <WarningLabelRow>
                <InfoIcon mr="10px" color="#FF0000" />
                <Text fontSize="14px" textAlign="center">
                  {t('You have no NFTs in this collection on ')}
                  {networkName?.name}
                </Text>
              </WarningLabelRow>
            </Flex>
          </AutoRow>
        </div>
      ) : (
        <Swiper
          navigation
          centeredSlides
          spaceBetween={10}
          slidesPerView={3}
          pagination={{ clickable: true }}
          className="mySwiper"
          watchSlidesProgress
        >
          {data.map((eachAsset) => {
            const { token_id: tokenID, metadata, token_hash: tokenHash } = eachAsset
            const metadataObject = JSON.parse(metadata)
            return (
              <SwiperSlide key={tokenHash} data-swiper-slide-index={tokenHash}>
                {({ isActive }) => {
                  return (
                    <EachNftAsset
                      nftAssetId={tokenID}
                      imageUrl={metadataObject?.image ? metadataObject?.image : imagePlaceholder}
                      isActive={isActive}
                      onNftAssetSelect={(val) => {
                        onNftAssetSelect(val)
                      }}
                    />
                  )
                }}
              </SwiperSlide>
            )
          })}
        </Swiper>
      )}
    </>
  )
}

export default NftCollectionAssetsSlides
