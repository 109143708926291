import { StakingPoolConfig } from '../types'
import { ChainId } from '../chainId'
import getLPTokens, { getLPTokensList } from '../tokensLP'
import getTokens, { getTokensList } from '../tokens'
import { asyncExtendedPools } from '../asyncExtendedPools'

const contractAddress = {
  [ChainId.ETHEREUM]: '0x306fD816e0DeED33439Eb495F1857E1727A869D8',
  [ChainId.BSC]: '0x306fD816e0DeED33439Eb495F1857E1727A869D8',
  [ChainId.POLYGON]: '0x306fD816e0DeED33439Eb495F1857E1727A869D8',
  [ChainId.AVALANCHE]: '0xdb4Fa947191a2d4C77475797759662d5fAB9B81B',
  [ChainId.FANTOM]: '0x3FD1EcbFd9ec0c3bdEA196657fD917b1998Ecce4',
  [ChainId.AUTOBAHN]: '0x3FD1EcbFd9ec0c3bdEA196657fD917b1998Ecce4',
}

let pools

function initialPools() {
  const lpTokens = getLPTokens()
  const tokens = getTokens()

  pools = [
    {
      stakingPoolId: 2,
      stakingToken: lpTokens.usdc,
      earningToken: tokens.usdc,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '0.01261',
      isFinished: false,
    },
    {
      stakingPoolId: 13,
      stakingToken: lpTokens.usdt,
      earningToken: tokens.usdt,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 12,
      stakingToken: lpTokens.weth,
      earningToken: tokens.weth,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 1,
      stakingToken: lpTokens.txl,
      earningToken: tokens.txl,
      contractAddress,
      harvest: true,
      tokenPerBlock: '0.434027',
      sortOrder: 999,
      isFinished: false,
    },
    {
      stakingPoolId: 0,
      stakingToken: lpTokens.bridge,
      earningToken: tokens.bridge,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '0.01261',
      isFinished: false,
    },
    {
      stakingPoolId: 4,
      stakingToken: lpTokens.pmon,
      earningToken: tokens.pmon,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 20,
      stakingToken: lpTokens.pmlg,
      earningToken: tokens.pmlg,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 3,
      stakingToken: lpTokens.gfx,
      earningToken: tokens.gfx,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 5,
      stakingToken: lpTokens.bulk,
      earningToken: tokens.bulk,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 6,
      stakingToken: lpTokens.mny,
      earningToken: tokens.mny,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 8,
      stakingToken: lpTokens.island,
      earningToken: tokens.island,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 9,
      stakingToken: lpTokens.dax,
      earningToken: tokens.dax,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 10,
      stakingToken: lpTokens.realm,
      earningToken: tokens.realm,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 11,
      stakingToken: lpTokens.upr,
      earningToken: tokens.upr,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 15,
      stakingToken: lpTokens.uno,
      earningToken: tokens.uno,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 16,
      stakingToken: lpTokens.uct,
      earningToken: tokens.uct,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 17,
      stakingToken: lpTokens.cndl,
      earningToken: tokens.cndl,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 19,
      stakingToken: lpTokens.haka,
      earningToken: tokens.haka,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 21,
      stakingToken: lpTokens.shr,
      earningToken: tokens.shr,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 22,
      stakingToken: lpTokens.xgc,
      earningToken: tokens.xgc,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      stakingPoolId: 23,
      stakingToken: lpTokens.yop,
      earningToken: tokens.yop,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
  ]
}

export default function getPools(): StakingPoolConfig[] {
  if (!pools) {
    initialPools()
  }

  return pools
}

asyncExtendedPools(
  'liquidityMining',
  'stakingPoolId',
  ChainId.ETHEREUM,
  contractAddress,
  getPools,
  getLPTokensList,
  getTokensList,
  (pool) => pools.push(pool),
).catch((err) => console.error('Dynamic pools', err))
