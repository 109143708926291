import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import getStakingPools from 'config/constants/stakingPools'
import { BIG_ZERO } from 'utils/bigNumber'
import { AppThunk, StakingPool, StakingState } from 'state/types'
import { getPoolApr } from 'utils/apr'
import { getBalanceNumber } from 'utils/formatBalance'
import { getAddress } from 'utils/addressHelpers'
// import { fetchPoolsBlockLimits, fetchPoolsStakingLimits, fetchPoolsTotalStaking } from './fetchPools'
import { fetchPoolsStakingLimits, fetchPoolsTotalStaking } from './fetchPools'
import {
  fetchPoolsAllowance,
  fetchUserBalances,
  fetchUserPendingRewards,
  fetchUserStakeBalances,
} from './fetchPoolsUser'
import { getTokenPricesFromFarm } from './helpers'
import { ChainId } from '../../config/constants/chainId'

const initialState: StakingState = {
  data: [...getStakingPools(ChainId.UNDEFINED)],
  userDataLoaded: false,
  cakeVault: {
    totalShares: null,
    pricePerFullShare: null,
    totalCakeInVault: null,
    estimatedCakeBountyReward: null,
    totalPendingCakeHarvest: null,
    fees: {
      performanceFee: null,
      callFee: null,
      withdrawalFee: null,
      withdrawalFeePeriod: null,
    },
    userData: {
      isLoading: true,
      userShares: null,
      cakeAtLastUserAction: null,
      lastDepositedTime: null,
      lastUserActionTime: null,
    },
  },
}

// Thunks
export const fetchStakingPoolsPublicDataAsync = (chainId: ChainId) => async (dispatch, getState) => {
  // const blockLimits = await fetchPoolsBlockLimits(chainId)
  const totalStakings = await fetchPoolsTotalStaking(chainId)

  const prices = getTokenPricesFromFarm(getState().farms.data, chainId)

  const liveData = getStakingPools(chainId)
    .filter((pool) => {
      return pool.stakingToken.address[chainId]
    })
    .map((pool) => {
      const blockLimit = {} // blockLimits.find((entry) => entry.stakingPoolId === pool.stakingPoolId)
      const totalStaking = totalStakings.find((entry) => entry.stakingPoolId === pool.stakingPoolId)
      const isPoolFinished = pool.isFinished

      const stakingTokenAddress = pool.stakingToken.address
        ? getAddress(pool.stakingToken.address, chainId).toLowerCase()
        : null
      const stakingTokenPrice = stakingTokenAddress ? prices[stakingTokenAddress] : 0

      const earningTokenAddress = pool.earningToken.address
        ? getAddress(pool.earningToken.address, chainId).toLowerCase()
        : null
      const earningTokenPrice = earningTokenAddress ? prices[earningTokenAddress] : 0
      const apr =
        !isPoolFinished && window.ethereum
          ? getPoolApr(
              stakingTokenPrice,
              earningTokenPrice,
              getBalanceNumber(new BigNumber(totalStaking.totalStaked), pool.stakingToken.decimals[chainId]),
              parseFloat(pool.tokenPerBlock),
              chainId,
            )
          : 0

      return {
        ...blockLimit,
        ...totalStaking,
        stakingTokenPrice,
        earningTokenPrice,
        apr,
        isFinished: isPoolFinished,
      }
    })

  dispatch(setStakingPoolsPublicData(liveData))
}

export const fetchStakingPoolsStakingLimitsAsync = (chainId: ChainId) => async (dispatch, getState) => {
  const poolsWithStakingLimit = getState()
    .pools.data.filter(({ stakingLimit }) => stakingLimit !== null && stakingLimit !== undefined)
    .map((pool) => pool.stakingPoolId)

  const stakingLimits = await fetchPoolsStakingLimits(poolsWithStakingLimit, chainId)

  const stakingLimitData = getStakingPools(chainId).map((pool) => {
    if (poolsWithStakingLimit.includes(pool.stakingPoolId)) {
      return { stakingPoolId: pool.stakingPoolId }
    }
    const stakingLimit = stakingLimits[pool.stakingPoolId] || BIG_ZERO
    return {
      stakingPoolId: pool.stakingPoolId,
      stakingLimit: stakingLimit.toJSON(),
    }
  })

  dispatch(setStakingPoolsPublicData(stakingLimitData))
}

export const fetchStakingPoolsUserDataAsync =
  (account: string, chainId: ChainId): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchPoolsAllowance(account, chainId)
    const stakingTokenBalances = await fetchUserBalances(account, chainId)
    const stakedBalances = await fetchUserStakeBalances(account, chainId)
    const pendingRewards = await fetchUserPendingRewards(account, chainId)

    const userData = getStakingPools(chainId).map((pool) => ({
      stakingPoolId: pool.stakingPoolId,
      allowance: allowances[pool.stakingPoolId],
      stakingTokenBalance: stakingTokenBalances[pool.stakingPoolId],
      stakedBalance: stakedBalances[pool.stakingPoolId],
      pendingReward: pendingRewards[pool.stakingPoolId],
    }))

    dispatch(setStakingPoolsUserData(userData))
  }

export const updateStakingUserAllowance =
  (stakingPoolId: number, account: string, chainId: ChainId): AppThunk =>
  async (dispatch) => {
    const allowances = await fetchPoolsAllowance(account, chainId)
    dispatch(updateStakingPoolsUserData({ stakingPoolId, field: 'allowance', value: allowances[stakingPoolId] }))
  }

export const updateStakingUserBalance =
  (stakingPoolId: number, account: string, chainId: ChainId): AppThunk =>
  async (dispatch) => {
    const tokenBalances = await fetchUserBalances(account, chainId)
    dispatch(
      updateStakingPoolsUserData({ stakingPoolId, field: 'stakingTokenBalance', value: tokenBalances[stakingPoolId] }),
    )
  }

export const updateStakingUserStakedBalance =
  (stakingPoolId: number, account: string, chainId: ChainId): AppThunk =>
  async (dispatch) => {
    const stakedBalances = await fetchUserStakeBalances(account, chainId)
    dispatch(
      updateStakingPoolsUserData({ stakingPoolId, field: 'stakedBalance', value: stakedBalances[stakingPoolId] }),
    )
  }

export const updateStakingUserPendingReward =
  (stakingPoolId: number, account: string, chainId: ChainId): AppThunk =>
  async (dispatch) => {
    const pendingRewards = await fetchUserPendingRewards(account, chainId)
    dispatch(
      updateStakingPoolsUserData({ stakingPoolId, field: 'pendingReward', value: pendingRewards[stakingPoolId] }),
    )
  }

export const StakingPoolsSlice = createSlice({
  name: 'Staking',
  initialState,
  reducers: {
    setStakingPoolsPublicData: (state, action) => {
      const livePoolsData: StakingPool[] = action.payload
      state.data = state.data.map((pool) => {
        const livePoolData = livePoolsData.find((entry) => entry.stakingPoolId === pool.stakingPoolId)
        return { ...pool, ...livePoolData }
      })
    },
    setStakingPoolsUserData: (state, action) => {
      const userData = action.payload
      state.data = state.data.map((pool) => {
        const userPoolData = userData.find((entry) => entry.stakingPoolId === pool.stakingPoolId)
        return { ...pool, userData: userPoolData }
      })
      state.userDataLoaded = true
    },
    updateStakingPoolsUserData: (state, action) => {
      const { field, value, stakingPoolId } = action.payload
      const index = state.data.findIndex((p) => p.stakingPoolId === stakingPoolId)

      if (index >= 0) {
        state.data[index] = { ...state.data[index], userData: { ...state.data[index].userData, [field]: value } }
      }
    },
    resetStakingPools: (state, action) => {
      return {
        ...initialState,
        data: getStakingPools(action.payload.chainId),
      }
    },
  },
})

// Actions
export const { setStakingPoolsPublicData, setStakingPoolsUserData, updateStakingPoolsUserData, resetStakingPools } =
  StakingPoolsSlice.actions

export default StakingPoolsSlice.reducer
