import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { TransactionResponse } from '@ethersproject/providers'
import { Text, Flex, Link } from '@pancakeswap/uikit'
import axios from 'axios'
import { etherscanTxList } from 'state/transient/actions'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import useToast from 'hooks/useToast'
import useIsWindowVisible from 'hooks/useIsWindowVisible'
import { getBscScanLink } from 'utils'
import { AppDispatch, AppState } from '../index'
import { addTransaction, speedUpTransaction } from './actions'
import { TransactionDetails } from './reducer'
import { ChainId } from '../../config/constants/chainId'

// helper that can take a ethers library transaction response and add it to the list of transactions
export function useTransactionAdder(): (
  response: TransactionResponse,
  customData?: {
    summary?: string
    approval?: { tokenAddress: string; spender: string }
    claim?: { recipient: string }
  },
) => void {
  const { chainId, account } = useActiveWeb3React()
  const dispatch = useDispatch<AppDispatch>()

  return useCallback(
    (
      response: TransactionResponse,
      {
        summary,
        approval,
        claim,
      }: { summary?: string; claim?: { recipient: string }; approval?: { tokenAddress: string; spender: string } } = {},
    ) => {
      if (!account) return
      if (!chainId) return

      const { hash } = response
      if (!hash) {
        throw Error('No transaction hash found.')
      }
      dispatch(addTransaction({ hash, from: account, chainId, approval, summary, claim }))
    },
    [dispatch, chainId, account],
  )
}

// returns all the transactions for the current chain
export function useAllTransactions(): { [txHash: string]: TransactionDetails } {
  const { chainId } = useActiveWeb3React()

  const state = useSelector<AppState, AppState['transactions']>((s) => s.transactions)

  return chainId ? state[chainId] ?? {} : {}
}

export function useIsTransactionPending(transactionHash?: string): boolean {
  const transactions = useAllTransactions()

  if (!transactionHash || !transactions[transactionHash]) return false

  return !transactions[transactionHash].receipt
}

/**
 * Returns whether a transaction happened in the last day (86400 seconds * 1000 milliseconds / second)
 * @param tx to check for recency
 */
export function isTransactionRecent(tx: TransactionDetails): boolean {
  return new Date().getTime() - tx.addedTime < 86_400_000
}

// returns whether a token has a pending approval transaction
export function useHasPendingApproval(tokenAddress: string | undefined, spender: string | undefined): boolean {
  const allTransactions = useAllTransactions()
  return useMemo(
    () =>
      typeof tokenAddress === 'string' &&
      typeof spender === 'string' &&
      Object.keys(allTransactions).some((hash) => {
        const tx = allTransactions[hash]
        if (!tx) return false
        if (tx.receipt) {
          return false
        }
        const { approval } = tx
        if (!approval) return false
        return approval.spender === spender && approval.tokenAddress === tokenAddress && isTransactionRecent(tx)
      }),
    [allTransactions, spender, tokenAddress],
  )
}

export function useLoadSpeedUpTx() {
  const dispatch = useDispatch()
  const { chainId, library, account } = useActiveWeb3React()
  const { toastSuccess } = useToast()
  const isWindowVisible = useIsWindowVisible()

  const timer = useRef(null)
  const transactions = useSelector<AppState, AppState['transactions']>((s) => s.transactions)
  const transient = useSelector<AppState, AppState['transient']>((s) => s.transient)
  const [nonceMap, setNonceMap] = useState<Record<string, TransactionResponse>>({})
  const allTx = useMemo(() => Object.values(transactions[chainId] || {}), [transactions, chainId])

  // create a nonce2tx map
  const txHashs = useMemo(() => {
    return allTx.map((tx) => tx.hash)
  }, [allTx])

  useEffect(() => {
    Promise.all(txHashs.map((hash) => library.getTransaction(hash))).then((txs) => {
      const nextNonceMap = {}
      txs.forEach((tx) => {
        if (!tx) return
        nextNonceMap[String(tx.nonce)] = tx
      })
      setNonceMap(nextNonceMap)
    })
  }, [txHashs, library])

  // check against etherscan list
  useEffect(() => {
    const accountTransactions = Array.isArray(transient.etherscanTxList) ? transient.etherscanTxList : []

    accountTransactions.forEach((tx) => {
      // find local tx by nonce
      const txByNonce = nonceMap[tx.nonce]

      // if 'nonce' and 'to' match and 'hash' changed, then it must be sped up
      if (txByNonce && txByNonce.to === tx.to && txByNonce.hash !== tx.hash) {
        console.debug('tx speed up found', tx)
        dispatch(speedUpTransaction({ chainId, hash: txByNonce.hash, toHash: tx.hash }))

        toastSuccess(
          'Found sped up transaction',
          <Flex flexDirection="column">
            <Text>{`Hash: ${tx.hash.slice(0, 8)}...${tx.hash.slice(58, 65)}`}</Text>
            {chainId && (
              <Link external href={getBscScanLink(tx.hash, 'transaction', chainId)}>
                View on Explorer
              </Link>
            )}
          </Flex>,
        )
      }
    })
  }, [dispatch, nonceMap, chainId, transient.etherscanTxList, toastSuccess])

  // interval check the latest tx
  useEffect(() => {
    if (isWindowVisible) {
      timer.current = setInterval(() => {
        axios
          .get(
            chainId === ChainId.POLYGON
              ? `https://api.polygonscan.com/api?module=account&action=txlist&address=${account}&startblock=0&endblock=99999999&sort=desc`
              : chainId === ChainId.BSC
              ? `https://api.bscscan.com/api?module=account&action=txlist&address=${account}&startblock=0&endblock=99999999&sort=desc`
              : chainId === ChainId.FANTOM
              ? `https://api.ftmscan.com/api?module=account&action=txlist&address=${account}&startblock=0&endblock=99999999&sort=desc`
              : chainId === ChainId.AVALANCHE
              ? `https://api.snowtrace.io/api?module=account&action=txlist&address=${account}&startblock=1&endblock=99999999&sort=desc`
              : `https://api.etherscan.io/api?module=account&action=txlist&address=${account}&startblock=0&endblock=99999999&sort=desc`,
          )
          .then((res) => {
            if (res.status !== 200) {
              if (chainId === ChainId.POLYGON) {
                console.error('Polygonscan request not 200', res)
              }
              if (chainId === ChainId.BSC) {
                console.error('Bscscan request not 200', res)
              }
              if (chainId === ChainId.FANTOM) {
                console.error('Fantomscan request not 200', res)
              }
              if (chainId === ChainId.AVALANCHE) {
                console.error('Snowtrace/Avalanche Explorer request not 200', res)
              }
              console.error('Etherscan request not 200', res)
            }
            if (res.data && res.data.result) {
              dispatch(etherscanTxList({ result: res.data.result }))
            }
          })
          .catch((err) => {
            console.error(err)
          })
      }, 6000)
    } else {
      clearInterval(timer.current)
    }

    return () => clearInterval(timer.current)
  }, [chainId, dispatch, account, timer, isWindowVisible])
}
