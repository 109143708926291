import getStakingPools from 'config/constants/stakingPools'
import liquidityMiningPoolsABI from 'config/abi/liquidityMiningPools.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import { ChainId } from '../../config/constants/chainId'
import { getRpcProvider } from '../../utils/providers'

export const fetchPoolsAllowance = async (account, chainId: ChainId) => {
  const calls = getStakingPools(chainId)
    .map((p) => ({
      address: getAddress(p.stakingToken.address, chainId),
      name: 'allowance',
      params: [account, getAddress(p.contractAddress, chainId)],
      pool: p,
    }))
    .filter((callParams) => callParams.address && callParams.address.length > 0)

  const provider = await getRpcProvider(chainId)

  let allowances
  if (provider.connection.url === 'metamask') {
    allowances = await multicall(erc20ABI, calls, chainId)
  } else {
    allowances = await multicall(erc20ABI, calls, chainId, provider)
  }

  return calls.reduce(
    (acc, call, index) => ({ ...acc, [call.pool.stakingPoolId]: new BigNumber(allowances[index]).toJSON() }),
    {},
  )
}

export const fetchUserBalances = async (account, chainId: ChainId) => {
  const calls = getStakingPools(chainId)
    .map((p) => ({
      address: getAddress(p.stakingToken.address, chainId),
      name: 'balanceOf',
      params: [account],
    }))
    .filter((callParams) => callParams.address && callParams.address.length > 0)

  const defaultSigner = await getRpcProvider(chainId)
  const tokenBalancesRaw = await multicall(erc20ABI, calls, chainId, defaultSigner)
  const tokenBalances = getStakingPools(chainId).reduce(
    (acc, pool, index) => ({ ...acc, [pool.stakingPoolId]: new BigNumber(tokenBalancesRaw[index]).toJSON() }),
    {},
  )

  return tokenBalances
}

export const fetchUserStakeBalances = async (account, chainId: ChainId) => {
  const calls = getStakingPools(chainId)
    .map((p) => ({
      address: getAddress(p.contractAddress, chainId),
      name: 'stakes',
      params: [getAddress(p.earningToken.address, chainId), account],
      pool: p,
    }))
    .filter((callParams) => callParams.address && callParams.address.length > 0 && callParams.params[0].length > 0)

  const defaultSigner = await getRpcProvider(chainId)
  const userInfo = await multicall(liquidityMiningPoolsABI, calls, chainId, defaultSigner)

  const stakedBalances = calls.reduce(
    (acc, call, index) => ({
      ...acc,
      [call.pool.stakingPoolId]: new BigNumber(userInfo[index].balance._hex).toJSON(),
    }),
    {},
  )

  return stakedBalances
}

export const fetchUserPendingRewards = async (account, chainId: ChainId) => {
  const calls = getStakingPools(chainId)
    .map((p) => ({
      address: getAddress(p.contractAddress, chainId),
      name: 'pendingReward',
      params: [getAddress(p.earningToken.address, chainId), account],
      pool: p,
    }))
    .filter((callParams) => callParams.address && callParams.address.length > 0 && callParams.params[0].length > 0)

  try {
    const defaultSigner = await getRpcProvider(chainId)
    const res = await multicall(liquidityMiningPoolsABI, calls, chainId, defaultSigner)

    const pendingRewards = calls.reduce(
      (acc, call, index) => ({
        ...acc,
        [call.pool.stakingPoolId]: new BigNumber(res[index]).toJSON(),
      }),
      {},
    )

    return pendingRewards
  } catch (error) {
    return []
  }
}
