import BigNumber from 'bignumber.js'
import { BIG_ZERO } from 'utils/bigNumber'
// import { Farm } from 'state/types'

// @TODO handle with price data

/*
const getFarmFromTokenSymbol = (farms: Farm[], tokenSymbol: string): Farm => {
  const farmsWithTokenSymbol = farms.filter((farm) => farm.token.symbol === tokenSymbol)
  return farmsWithTokenSymbol[0]
}
 */

// const getFarmBaseTokenPrice = (farm: Farm, quoteTokenFarm: Farm, bnbPriceBusd: BigNumber): BigNumber => {
const getFarmBaseTokenPrice = (): BigNumber => {
  // Catch in case token does not have immediate or once-removed BUSD/wBNB quoteToken
  return BIG_ZERO
}

// const getFarmQuoteTokenPrice = (farm: Farm, quoteTokenFarm: Farm, bnbPriceBusd: BigNumber): BigNumber => {
const getFarmQuoteTokenPrice = (): BigNumber => {
  return BIG_ZERO
}

const fetchFarmsPrices = async (farms) => {
  // const bnbBusdFarm = farms.find((farm: Farm) => farm.pid === 252)
  // const bnbPriceBusd = bnbBusdFarm.tokenPriceVsQuote ? BIG_ONE.div(bnbBusdFarm.tokenPriceVsQuote) : BIG_ZERO

  const farmsWithPrices = farms.map((farm) => {
    // const quoteTokenFarm = getFarmFromTokenSymbol(farms, farm.quoteToken.symbol)
    const baseTokenPrice = getFarmBaseTokenPrice()
    const quoteTokenPrice = getFarmQuoteTokenPrice()
    const token = { ...farm.token, busdPrice: baseTokenPrice.toJSON() }
    const quoteToken = { ...farm.quoteToken, busdPrice: quoteTokenPrice.toJSON() }
    return { ...farm, token, quoteToken }
  })

  return farmsWithPrices
}

export default fetchFarmsPrices
