import { ChainId } from 'config/constants/chainId'

export const getExplorerUriBase = (chainId: number) => {
  switch (chainId) {
    case ChainId.ETHEREUM:
      return `https://etherscan.io`
    case ChainId.RINKEBY:
      return `https://rinkeby.etherscan.io`
    case ChainId.BSC:
      return `https://bscscan.com`
    case ChainId.BSCTEST:
      return `https://testnet.bscscan.com`
    case ChainId.POLYGON:
      return `https://polygonscan.com`
    default:
      return ''
  }
}

export const getExplorerUri = (chainId: number, tokenContractAddress: string) => {
  switch (chainId) {
    case ChainId.ETHEREUM:
      return `https://etherscan.io/token/${tokenContractAddress}`
    case ChainId.RINKEBY:
      return `https://rinkeby.etherscan.io/token/${tokenContractAddress}`
    case ChainId.BSC:
      return `https://bscscan.com/token/${tokenContractAddress}`
    case ChainId.BSCTEST:
      return `https://testnet.bscscan.com/token/${tokenContractAddress}`
    case ChainId.POLYGON:
      return `https://polygonscan.com/token/${tokenContractAddress}`
    default:
      return ''
  }
}
