import { ChainId } from './chainId'
import rewardPoolsAvalanche from './avalanche/rewardPools'
import rewardPoolsBsc from './bsc/rewardPools'
import rewardPoolsBscTest from './bsctest/rewardPools'
import rewardPoolsDevEnv from './devenv/rewardPools'
import rewardPoolsEthereum from './ethereum/rewardPools'
import rewardPoolsFantom from './fantom/rewardPools'
import rewardPoolsAutobahn from './autobahn/rewardPools'
import rewardPoolsPolygon from './polygon/rewardPools'
import rewardPoolsRinkeby from './rinkeby/rewardPools'

const getRewardPools = (chainId: ChainId) => {
  switch (chainId) {
    // Dev Environment
    case ChainId.LETH:
    case ChainId.LBSC:
      return rewardPoolsDevEnv()

    // Testnet
    case ChainId.RINKEBY:
      return rewardPoolsRinkeby()
    case ChainId.BSCTEST:
      return rewardPoolsBscTest()

    // Production
    case ChainId.ETHEREUM:
      return rewardPoolsEthereum()
    case ChainId.BSC:
      return rewardPoolsBsc()
    case ChainId.POLYGON:
      return rewardPoolsPolygon()
    case ChainId.AVALANCHE:
      return rewardPoolsAvalanche()
    case ChainId.FANTOM:
      return rewardPoolsFantom()
    case ChainId.AUTOBAHN:
      return rewardPoolsAutobahn()

    default:
      return []
  }
}

export default getRewardPools
