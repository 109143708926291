import React, { useMemo } from 'react'
import styled from 'styled-components'
import {
  TokenPairImage as UIKitTokenPairImage,
  TokenPairImageProps as UIKitTokenPairImageProps,
  ImageProps,
} from '@pancakeswap/uikit'
import { Token } from 'config/constants/types'
import { Token as BurnToken } from 'sdk/entities/token'
import { getAddress } from 'utils/addressHelpers'
import { ChainId } from 'config/constants/chainId'
import Logo from 'components/Logo/Logo'

const Border = styled('div')<{ size: string }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  position: relative;
  margin-right: 8px;

  &:before {
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.25);
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 7;
    content: '';
  }
`

const StyledLogo = styled(Logo)<{ size: string }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  min-width: ${({ size }) => size}px;
`

// Set a chain to undefined to fallback to local files
const trustwalletFolders: Record<ChainId, string> = {
  [ChainId.UNDEFINED]: undefined,
  [ChainId.AVALANCHE]: 'avalanchec',
  [ChainId.AVALANCHETEST]: undefined,
  [ChainId.BSC]: 'binance',
  [ChainId.BSCTEST]: undefined,
  [ChainId.ETHEREUM]: 'ethereum',
  [ChainId.FANTOM]: 'fantom',
  [ChainId.LBSC]: undefined,
  [ChainId.LETH]: undefined,
  [ChainId.MAINNET]: 'ethereum',
  [ChainId.POLYGON]: 'polygon',
  [ChainId.RINKEBY]: undefined,
  [ChainId.TESTNET]: undefined,
  [ChainId.AUTOBAHN]: undefined,
}

export function tokenToUrl(chainId: ChainId, addr: string): string {
  const folder = trustwalletFolders[chainId]

  if (folder)
    return `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${folder}/assets/${addr}/logo.png`

  return null
}

interface TokenImageProps extends ImageProps {
  chainId: ChainId
  token?: Token | BurnToken
  quoteToken?: Token | BurnToken
}

export const TokenImage: React.FC<TokenImageProps> = ({ chainId, token, ...props }) => {
  const address = getAddress(token.address, chainId)

  const urls = useMemo(() => {
    return [`/images/tokens/${address}.svg`, `/images/tokens/${address}.png`, tokenToUrl(chainId, address)]
  }, [address, chainId])

  if (!token) return <div />

  return (
    <Border size={`${props.width}`}>
      <StyledLogo size={`${props.width}`} srcs={urls} {...props} />
    </Border>
  )
}

interface TokenPairImageProps extends Omit<UIKitTokenPairImageProps, 'primarySrc' | 'secondarySrc'> {
  primaryToken: Token
  secondaryToken: Token
  chainId: ChainId
}

const getImageUrlFromToken = (token: Token, chainId) => {
  const address = getAddress(token.address, chainId)
  return `/images/tokens/${address}.png`
}

export const TokenPairImage: React.FC<TokenPairImageProps> = ({ primaryToken, secondaryToken, chainId, ...props }) => {
  if (!primaryToken || !secondaryToken) return <div />

  return (
    <UIKitTokenPairImage
      primarySrc={getImageUrlFromToken(primaryToken, chainId)}
      secondarySrc={getImageUrlFromToken(secondaryToken, chainId)}
      {...props}
      style={{ minWidth: props.width }}
    />
  )
}
