// import getTokens, { quoteTokens } from '../tokens'
// import getLPTokens from '../tokensLP'
import { FarmConfig } from '../types'

export default function getFarms(): FarmConfig[] {
  // const lpTokens = getLPTokens()
  // const tokens = getTokens()

  return []
}
