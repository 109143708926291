import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

interface LogoProps extends SvgProps {
  isDark: boolean
}

const Logo: React.FC<LogoProps> = () => {
  return (
    <Svg viewBox="0 0 211.189 78.193" width="150">
      <svg
        version="1.1"
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 779.181 210.448"
        enableBackground="new 0 0 779.181 210.448"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <rect
              x="86.153"
              y="39.46"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -60.3788 104.0718)"
              fill="#0050FF"
              width="18.567"
              height="170.918"
            />
          </g>
          <g>
            <rect
              x="125.545"
              y="0.069"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.9874 120.3883)"
              fill="#0050FF"
              width="18.567"
              height="170.918"
            />
          </g>
          <g>
            <rect
              x="21.137"
              y="103.187"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -59.4671 81.3745)"
              fill="#1A1A1E"
              width="94.714"
              height="18.567"
            />
          </g>
          <g>
            <rect
              x="114.388"
              y="88.705"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -21.9096 143.0812)"
              fill="#1A1A1E"
              width="94.744"
              height="18.567"
            />
          </g>
          <g>
            <rect
              x="122.525"
              y="29.626"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 14.0941 111.8446)"
              fill="#1A1A1E"
              width="39.06"
              height="18.567"
            />
          </g>
          <g>
            <rect
              x="68.681"
              y="162.261"
              transform="matrix(0.7071 -0.7071 0.7071 0.7071 -95.4664 112.613)"
              fill="#1A1A1E"
              width="39.043"
              height="18.567"
            />
          </g>
        </g>
        <g>
          <path
            fill="#1A1A1E"
            d="M258.023,53.445c2.26-4.095,5.411-7.284,9.456-9.571c4.043-2.285,8.629-3.428,13.753-3.428
		c6.278,0,11.654,1.657,16.124,4.973c4.473,3.315,7.462,7.837,8.968,13.563h-14.168c-1.053-2.21-2.549-3.892-4.483-5.049
		c-1.933-1.155-4.133-1.734-6.592-1.734c-3.97,0-7.186,1.383-9.647,4.145c-2.461,2.763-3.691,6.457-3.691,11.078
		c0,4.623,1.229,8.315,3.691,11.077c2.461,2.763,5.677,4.145,9.647,4.145c2.459,0,4.659-0.577,6.592-1.734
		c1.934-1.155,3.43-2.837,4.483-5.049h14.168c-1.506,5.728-4.496,10.237-8.968,13.527c-4.47,3.29-9.846,4.936-16.124,4.936
		c-5.124,0-9.71-1.143-13.753-3.429c-4.046-2.285-7.196-5.462-9.456-9.532c-2.26-4.069-3.392-8.716-3.392-13.94
		C254.632,62.199,255.763,57.54,258.023,53.445z"
          />
          <path
            fill="#1A1A1E"
            d="M332.034,53.445c2.26-1.331,4.772-1.998,7.535-1.998v13.639h-3.543c-3.213,0-5.627,0.691-7.234,2.073
		c-1.607,1.382-2.411,3.805-2.411,7.271v19.517h-12.886V51.9h12.886v7.007C327.89,56.598,329.774,54.777,332.034,53.445z"
          />
          <path
            fill="#1A1A1E"
            d="M353.305,91.914c-3.291-1.759-5.878-4.269-7.762-7.537c-1.883-3.265-2.826-7.083-2.826-11.454
		c0-4.32,0.953-8.125,2.864-11.416c1.908-3.29,4.521-5.814,7.837-7.573c3.316-1.757,7.032-2.637,11.153-2.637
		c4.118,0,7.837,0.88,11.151,2.637c3.316,1.759,5.929,4.283,7.837,7.573c1.908,3.291,2.864,7.097,2.864,11.416
		c0,4.322-0.968,8.127-2.901,11.416c-1.933,3.291-4.571,5.816-7.912,7.574c-3.341,1.759-7.07,2.637-11.189,2.637
		C360.299,94.551,356.593,93.673,353.305,91.914z M370.711,80.685c1.735-1.808,2.6-4.395,2.6-7.762c0-3.364-0.842-5.953-2.524-7.762
		c-1.682-1.808-3.756-2.713-6.215-2.713c-2.514,0-4.599,0.893-6.256,2.675c-1.657,1.784-2.487,4.384-2.487,7.799
		c0,3.367,0.817,5.954,2.449,7.762c1.632,1.809,3.681,2.713,6.142,2.713C366.879,83.398,368.979,82.494,370.711,80.685z"
          />
          <path
            fill="#1A1A1E"
            d="M399.701,92.667c-2.864-1.255-5.124-2.977-6.781-5.162s-2.587-4.634-2.788-7.347h12.735
		c0.151,1.457,0.83,2.637,2.034,3.541c1.207,0.904,2.688,1.356,4.448,1.356c1.607,0,2.849-0.313,3.729-0.942
		c0.877-0.629,1.32-1.444,1.32-2.449c0-1.207-0.629-2.098-1.886-2.675c-1.255-0.577-3.291-1.218-6.102-1.922
		c-3.015-0.703-5.526-1.443-7.535-2.223c-2.011-0.778-3.744-2.009-5.2-3.692c-1.458-1.683-2.185-3.956-2.185-6.82
		c0-2.411,0.664-4.609,1.996-6.594c1.33-1.984,3.289-3.553,5.876-4.709c2.587-1.155,5.665-1.734,9.232-1.734
		c5.275,0,9.431,1.307,12.471,3.919c3.04,2.612,4.785,6.08,5.237,10.399h-11.905c-0.201-1.457-0.842-2.611-1.923-3.466
		c-1.081-0.854-2.499-1.281-4.257-1.281c-1.506,0-2.663,0.289-3.467,0.866c-0.802,0.578-1.204,1.369-1.204,2.373
		c0,1.206,0.641,2.111,1.921,2.713c1.282,0.603,3.279,1.206,5.992,1.809c3.113,0.803,5.652,1.595,7.611,2.373
		c1.959,0.779,3.678,2.034,5.162,3.768c1.481,1.734,2.248,4.057,2.298,6.97c0,2.463-0.691,4.66-2.072,6.594
		c-1.383,1.935-3.367,3.455-5.954,4.56c-2.587,1.104-5.589,1.658-9.004,1.658C405.831,94.551,402.565,93.923,399.701,92.667z"
          />
          <path
            fill="#1A1A1E"
            d="M440.89,92.667c-2.864-1.255-5.124-2.977-6.781-5.162c-1.657-2.185-2.587-4.634-2.788-7.347h12.735
		c0.151,1.457,0.83,2.637,2.034,3.541c1.207,0.904,2.688,1.356,4.448,1.356c1.607,0,2.849-0.313,3.729-0.942
		c0.878-0.629,1.32-1.444,1.32-2.449c0-1.207-0.629-2.098-1.886-2.675c-1.255-0.577-3.291-1.218-6.102-1.922
		c-3.015-0.703-5.526-1.443-7.535-2.223c-2.011-0.778-3.744-2.009-5.2-3.692c-1.458-1.683-2.185-3.956-2.185-6.82
		c0-2.411,0.664-4.609,1.996-6.594c1.33-1.984,3.289-3.553,5.876-4.709c2.587-1.155,5.665-1.734,9.232-1.734
		c5.275,0,9.431,1.307,12.471,3.919c3.04,2.612,4.785,6.08,5.237,10.399h-11.905c-0.201-1.457-0.842-2.611-1.923-3.466
		c-1.081-0.854-2.499-1.281-4.257-1.281c-1.506,0-2.663,0.289-3.467,0.866c-0.802,0.578-1.204,1.369-1.204,2.373
		c0,1.206,0.641,2.111,1.921,2.713c1.282,0.603,3.279,1.206,5.992,1.809c3.113,0.803,5.652,1.595,7.611,2.373
		c1.959,0.779,3.678,2.034,5.162,3.768c1.481,1.734,2.248,4.057,2.298,6.97c0,2.463-0.691,4.66-2.072,6.594
		c-1.383,1.935-3.367,3.455-5.954,4.56c-2.587,1.104-5.589,1.658-9.004,1.658C447.02,94.551,443.754,93.923,440.89,92.667z"
          />
          <path fill="#1A1A1E" d="M507.657,62.149v10.7h-33.158v-10.7H507.657z" />
          <path
            fill="#1A1A1E"
            d="M520.814,53.445c2.26-4.095,5.411-7.284,9.456-9.571c4.043-2.285,8.629-3.428,13.753-3.428
		c6.278,0,11.654,1.657,16.124,4.973c4.473,3.315,7.462,7.837,8.968,13.563h-14.168c-1.053-2.21-2.549-3.892-4.483-5.049
		c-1.933-1.155-4.133-1.734-6.592-1.734c-3.97,0-7.186,1.383-9.647,4.145c-2.461,2.763-3.691,6.457-3.691,11.078
		c0,4.623,1.229,8.315,3.691,11.077c2.461,2.763,5.677,4.145,9.647,4.145c2.459,0,4.659-0.577,6.592-1.734
		c1.933-1.155,3.43-2.837,4.483-5.049h14.168c-1.506,5.728-4.496,10.237-8.968,13.527c-4.47,3.29-9.846,4.936-16.124,4.936
		c-5.124,0-9.71-1.143-13.753-3.429c-4.046-2.285-7.196-5.462-9.456-9.532c-2.26-4.069-3.392-8.716-3.392-13.94
		C517.422,62.199,518.553,57.54,520.814,53.445z"
          />
          <path
            fill="#1A1A1E"
            d="M613.739,56.232c2.911,3.191,4.37,7.573,4.37,13.15v24.566h-12.81V71.116c0-2.813-0.729-4.998-2.185-6.556
		c-1.458-1.556-3.417-2.336-5.878-2.336s-4.42,0.779-5.878,2.336c-1.456,1.558-2.185,3.743-2.185,6.556v22.832h-12.886V38.185
		h12.886v19.366c1.307-1.858,3.09-3.34,5.35-4.445c2.26-1.105,4.797-1.658,7.611-1.658
		C606.956,51.448,610.825,53.043,613.739,56.232z"
          />
          <path
            fill="#1A1A1E"
            d="M626.698,61.47c1.632-3.265,3.854-5.777,6.67-7.535c2.811-1.757,5.951-2.637,9.419-2.637
		c2.964,0,5.564,0.603,7.799,1.809c2.235,1.206,3.955,2.788,5.162,4.747V51.9h12.886v42.048h-12.886v-5.953
		c-1.257,1.959-3.002,3.541-5.237,4.747s-4.835,1.809-7.799,1.809c-3.417,0-6.532-0.891-9.343-2.675
		c-2.816-1.783-5.039-4.321-6.67-7.611c-1.632-3.29-2.449-7.095-2.449-11.417C624.249,68.529,625.066,64.736,626.698,61.47z
		 M653.073,65.313c-1.783-1.858-3.955-2.788-6.517-2.788c-2.562,0-4.737,0.918-6.52,2.751c-1.783,1.834-2.675,4.36-2.675,7.573
		c0,3.216,0.893,5.765,2.675,7.648c1.783,1.884,3.957,2.826,6.52,2.826c2.562,0,4.734-0.929,6.517-2.788
		c1.783-1.857,2.675-4.395,2.675-7.611C655.748,69.709,654.855,67.172,653.073,65.313z"
          />
          <path
            fill="#1A1A1E"
            d="M678.454,45.533c-1.433-1.331-2.147-2.977-2.147-4.937c0-2.008,0.714-3.678,2.147-5.011
		c1.431-1.33,3.279-1.996,5.539-1.996c2.21,0,4.03,0.666,5.464,1.996c1.431,1.333,2.147,3.003,2.147,5.011
		c0,1.96-0.717,3.605-2.147,4.937c-1.433,1.331-3.253,1.996-5.464,1.996C681.733,47.529,679.885,46.864,678.454,45.533z
		 M690.397,51.9v42.048h-12.886V51.9H690.397z"
          />
          <path
            fill="#1A1A1E"
            d="M736.688,56.232c2.937,3.191,4.408,7.573,4.408,13.15v24.566h-12.81V71.116
		c0-2.813-0.729-4.998-2.185-6.556c-1.458-1.556-3.417-2.336-5.878-2.336s-4.42,0.779-5.878,2.336
		c-1.456,1.558-2.185,3.743-2.185,6.556v22.832h-12.886V51.9h12.886v5.575c1.307-1.858,3.065-3.326,5.275-4.408
		c2.21-1.08,4.697-1.62,7.46-1.62C729.816,51.448,733.748,53.043,736.688,56.232z"
          />
          <path
            fill="#1A1A1E"
            d="M298.565,144.044c1.858,2.386,2.788,5.112,2.788,8.175c0,4.421-1.546,7.925-4.634,10.512
		c-3.09,2.588-7.4,3.881-12.923,3.881h-24.642v-52.899h23.813c5.376,0,9.582,1.232,12.622,3.692
		c3.04,2.463,4.558,5.803,4.558,10.022c0,3.116-0.817,5.702-2.449,7.762c-1.632,2.06-3.804,3.492-6.517,4.296
		C294.243,140.138,296.705,141.658,298.565,144.044z M272.039,135.113h8.44c2.109,0,3.729-0.464,4.86-1.393
		c1.131-0.929,1.695-2.299,1.695-4.107c0-1.809-0.563-3.189-1.695-4.145c-1.131-0.954-2.751-1.432-4.86-1.432h-8.44V135.113z
		 M286.547,154.743c1.179-0.979,1.77-2.399,1.77-4.257c0-1.858-0.616-3.316-1.845-4.371c-1.232-1.055-2.929-1.583-5.086-1.583
		h-9.346v11.68h9.494C283.693,156.213,285.365,155.723,286.547,154.743z"
          />
          <path
            fill="#1A1A1E"
            d="M327.404,126.109c2.26-1.331,4.772-1.998,7.535-1.998v13.639h-3.543c-3.213,0-5.627,0.691-7.234,2.073
		c-1.607,1.382-2.411,3.805-2.411,7.271v19.517h-12.886v-42.048h12.886v7.007C323.26,129.262,325.144,127.441,327.404,126.109z"
          />
          <path
            fill="#1A1A1E"
            d="M340.74,118.197c-1.433-1.331-2.147-2.977-2.147-4.937c0-2.008,0.714-3.678,2.147-5.011
		c1.431-1.33,3.279-1.996,5.539-1.996c2.21,0,4.03,0.666,5.464,1.996c1.431,1.333,2.147,3.003,2.147,5.011
		c0,1.96-0.717,3.605-2.147,4.937c-1.433,1.331-3.253,1.996-5.464,1.996C344.018,120.193,342.17,119.528,340.74,118.197z
		 M352.683,124.564v42.048h-12.886v-42.048H352.683z"
          />
          <path
            fill="#1A1A1E"
            d="M361.455,134.134c1.632-3.265,3.854-5.777,6.67-7.535c2.811-1.757,5.951-2.637,9.419-2.637
		c2.763,0,5.288,0.578,7.573,1.734c2.285,1.157,4.081,2.713,5.388,4.672v-19.517h12.886v55.763h-12.886v-6.028
		c-1.207,2.009-2.927,3.617-5.162,4.822s-4.835,1.809-7.799,1.809c-3.467,0-6.608-0.891-9.419-2.675
		c-2.816-1.783-5.039-4.321-6.67-7.611c-1.632-3.29-2.449-7.095-2.449-11.417C359.006,141.193,359.823,137.4,361.455,134.134z
		 M387.83,137.977c-1.783-1.858-3.955-2.788-6.517-2.788c-2.562,0-4.737,0.918-6.52,2.751c-1.783,1.834-2.675,4.36-2.675,7.573
		c0,3.216,0.893,5.765,2.675,7.648c1.783,1.884,3.957,2.826,6.52,2.826c2.562,0,4.734-0.929,6.517-2.788
		c1.783-1.857,2.675-4.395,2.675-7.611C390.505,142.373,389.612,139.836,387.83,137.977z"
          />
          <path
            fill="#1A1A1E"
            d="M436.313,125.77c2.235,1.206,3.955,2.788,5.162,4.747v-5.953h12.886v41.972
		c0,3.868-0.767,7.372-2.298,10.512c-1.534,3.139-3.869,5.638-7.007,7.498c-3.14,1.858-7.048,2.788-11.719,2.788
		c-6.23,0-11.279-1.47-15.146-4.409c-3.869-2.938-6.08-6.921-6.63-11.943h12.735c0.4,1.607,1.355,2.875,2.861,3.805
		c1.509,0.929,3.367,1.394,5.577,1.394c2.663,0,4.785-0.767,6.369-2.299c1.581-1.532,2.373-3.981,2.373-7.347v-5.953
		c-1.257,1.959-2.989,3.554-5.2,4.785c-2.21,1.232-4.797,1.847-7.762,1.847c-3.467,0-6.608-0.891-9.418-2.675
		c-2.816-1.783-5.039-4.321-6.67-7.611c-1.632-3.29-2.449-7.095-2.449-11.417c0-4.32,0.817-8.112,2.449-11.378
		c1.632-3.265,3.854-5.777,6.67-7.535c2.811-1.757,5.951-2.637,9.418-2.637C431.478,123.961,434.077,124.564,436.313,125.77z
		 M438.799,137.977c-1.783-1.858-3.955-2.788-6.517-2.788c-2.562,0-4.737,0.918-6.52,2.751c-1.783,1.834-2.675,4.36-2.675,7.573
		c0,3.216,0.893,5.765,2.675,7.648c1.783,1.884,3.957,2.826,6.52,2.826c2.562,0,4.734-0.929,6.517-2.788
		c1.783-1.857,2.675-4.395,2.675-7.611C441.474,142.373,440.582,139.836,438.799,137.977z"
          />
          <path
            fill="#1A1A1E"
            d="M502.79,148.678h-29.163c0.201,2.612,1.043,4.61,2.524,5.99c1.481,1.383,3.304,2.073,5.464,2.073
		c3.216,0,5.451-1.356,6.706-4.069h13.715c-0.704,2.763-1.971,5.25-3.804,7.46c-1.835,2.211-4.133,3.945-6.897,5.2
		c-2.763,1.256-5.853,1.884-9.268,1.884c-4.121,0-7.787-0.879-11.003-2.637c-3.216-1.759-5.728-4.269-7.535-7.537
		c-1.808-3.265-2.713-7.083-2.713-11.454c0-4.37,0.893-8.188,2.675-11.454c1.783-3.265,4.282-5.777,7.498-7.535
		c3.216-1.757,6.907-2.637,11.078-2.637c4.068,0,7.686,0.855,10.852,2.562c3.163,1.708,5.637,4.145,7.422,7.309
		c1.783,3.165,2.675,6.858,2.675,11.078C503.017,146.116,502.941,147.371,502.79,148.678z M489.829,141.519
		c0-2.21-0.754-3.969-2.26-5.275c-1.509-1.306-3.392-1.96-5.652-1.96c-2.16,0-3.983,0.629-5.464,1.884
		c-1.481,1.256-2.399,3.04-2.751,5.35H489.829z"
          />
        </g>
      </svg>
    </Svg>
  )
}

export default React.memo(Logo, (prev, next) => prev.isDark === next.isDark)
