import { ethers } from 'ethers'
import { FarmConfig } from 'config/constants/types'
import fetchFarm from './fetchFarm'
import { ChainId } from '../../config/constants/chainId'

const fetchFarms = async (
  farmsToFetch: FarmConfig[],
  chainId: ChainId,
  signer?: ethers.Signer | ethers.providers.Provider,
) => {
  const data = await Promise.all(
    farmsToFetch.map(async (farmConfig) => {
      const farm = await fetchFarm(farmConfig, chainId, signer)
      return farm
    }),
  )
  return data
}

export default fetchFarms
