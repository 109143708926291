import { ethers } from 'ethers'
import NETWORK_URLS from '../config/constants/networks'
import { ChainId } from '../config/constants/chainId'

const RPC_URL = 'https://bsc-dataseed.binance.org/'
const RPC_URLS = {
  [ChainId.ETHEREUM]: NETWORK_URLS[ChainId.ETHEREUM],
  [ChainId.BSC]: NETWORK_URLS[ChainId.BSC],
  [ChainId.POLYGON]: NETWORK_URLS[ChainId.POLYGON],
  [ChainId.AVALANCHE]: NETWORK_URLS[ChainId.AVALANCHE],
  [ChainId.FANTOM]: NETWORK_URLS[ChainId.FANTOM],
}

export const simpleRpcProvider = window.ethereum
  ? new ethers.providers.Web3Provider(window.ethereum, 'any')
  : new ethers.providers.StaticJsonRpcProvider(RPC_URL)

export const getRpcProvider = async (chainId) => {
  if (window.ethereum) {
    const ethereumProvider = new ethers.providers.Web3Provider(window.ethereum, 'any')
    const ethereumProviderNetwork = await ethereumProvider.getNetwork()

    if (ethereumProviderNetwork.chainId === chainId) {
      return ethereumProvider
    }
  }

  if (chainId > 0) {
    return new ethers.providers.StaticJsonRpcProvider(RPC_URLS[chainId], 'any')
  }

  return null
}

export const forceRefreshOnNetworkChange = () => {
  if (window.ethereum) {
    const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
    if (provider) {
      provider.on('network', (newNetwork, oldNetwork) => {
        // When a Provider makes its initial connection, it emits a "network"
        // event with a null oldNetwork along with the newNetwork. So, if the
        // oldNetwork exists, it represents a changing network
        if (oldNetwork) {
          window.location.reload()
        }
      })
    }
  }
}
