import React from 'react'
import styled from 'styled-components'
import { CogIcon, IconButton } from '@pancakeswap/uikit'
import { MENU_ENTRY_HEIGHT } from '../config'
import { PanelProps, PushedProps } from '../types'
// import BuyBridgePriceButton from './BuyBridgePriceButton'
// import ThemeSwitcher from './ThemeSwitcher'
import SocialLinks from './SocialLinks'
// import LangSelector from './LangSelector'

interface Props extends PanelProps, PushedProps {}

const Container = styled.div`
  flex: none;
  padding: 8px 4px 0;
  background-color: ${({ theme }) => theme.nav.background};
  border-top: solid 2px rgba(133, 133, 133, 0.1);
`

// const SettingsEntry = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: space-between;
//   height: ${MENU_ENTRY_HEIGHT}px;
//   padding: 0 8px;
// `

const SocialEntry = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${MENU_ENTRY_HEIGHT}px;
  padding: 0 16px;
`

const PanelFooter: React.FC<Props> = ({ isPushed, pushNav }) => {
  if (!isPushed) {
    return (
      <Container>
        <IconButton variant="text" onClick={() => pushNav(true)}>
          <CogIcon />
        </IconButton>
      </Container>
    )
  }

  return (
    // Disabled ThemeSwitcher, LangSelector & TixlPrice
    <>
      <Container>
        <SocialEntry>
          <div />
          <SocialLinks />
        </SocialEntry>
      </Container>
    </>
    // Disabled code:
    // <Container>
    //   <SocialEntry>
    //     <TixlPrice tixlPriceUsd={tixlPriceUsd} />
    //     <SocialLinks />
    //   </SocialEntry>
    //   <SettingsEntry>
    //     <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />
    //     <LangSelector currentLang={currentLang} langs={langs} setLang={setLang} />
    //   </SettingsEntry>
    // </Container>
  )
}

export default PanelFooter
