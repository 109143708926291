/* eslint global-require: off */
/**
 * Returns the correct addresses for the environment in process.env.HARDHAT_NETWORK
 * @param network: [optional] (string | number) network name or chain id that if passed overrides process.env.HARDHAT_NETWORK
 * @return {{PLGT: string, ProjectLombardV1Factory: string, ProjectLombardV1Referral: string, ProjectLombardV1Stake: string, ProjectLombardV1Router: string, ProjectLombardV1Reward: string}}
 */
import { ChainId } from '../config/constants/chainId'

function getAddresses(network: ChainId | string | undefined): Record<string, any> | undefined {
  const usedNetwork: ChainId | string | undefined = network
  switch (usedNetwork) {
    case 'localhost':
    case 'ganache':
      return require('../addresses/localhost/addresses.json')
    case 'rinkeby':
    case ChainId.RINKEBY:
      return require('../addresses/rinkeby/addresses.json')
    case 'bsc':
    case ChainId.BSC:
      return require('../addresses/bsc/addresses.json')
    case 'bsctest':
    case ChainId.BSCTEST:
      return require('../addresses/bsctest/addresses.json')
    case ChainId.ETHEREUM:
      return require('../addresses/mainnet/addresses.json')
    case ChainId.POLYGON:
      return require('../addresses/polygon/addresses.json')
    case ChainId.AVALANCHE:
      return require('../addresses/avalanche/addresses.json')
    case ChainId.AVALANCHETEST:
      return require('../addresses/avalancheFuji/addresses.json')
    case ChainId.FANTOM:
      return require('../addresses/fantom/addresses.json')
    case ChainId.AUTOBAHN:
      return require('../addresses/autobahn/addresses.json')
    case ChainId.LETH:
      return require('../addresses/localeth/addresses.json')
    case ChainId.LBSC:
      return require('../addresses/localbsc/addresses.json')
    default:
      return require('../addresses/localhost/addresses.json')
  }
}

export default getAddresses
