import { dark, light, darkColors, lightColors } from '@pancakeswap/uikit'

// light.colors.backgroundAlt = '#000000'
// light.card.background = light.colors.backgroundAlt

// Header
light.colors.text = '#3d434d'

// Font color Navbar
light.colors.textSubtle = '#3d434d'

light.colors.secondary = '#0051ff'

light.colors.primary = '#0050ff'

export { dark, light, darkColors, lightColors }
