import React, { useRef } from 'react'
import { Box, Heading, InjectedModalProps, ModalCloseButton, ModalHeader, ModalTitle } from '@pancakeswap/uikit'
import { FixedSizeList } from 'react-window'
import { useTranslation } from 'contexts/Localization'
import { Collection } from 'config/constants/nftCollections'
import { StyledModalBody, StyledModalContainer } from './CurrencySearchModal'
import CollectionList from './CollectionList'

interface CollectionSearchModalProps extends InjectedModalProps {
  selectedCollection?: Collection | null
  chainId: number
  onCollectionSelect: (collection: Collection) => void
}

export default function CollectionSearchModal({
  onDismiss = () => null,
  onCollectionSelect,
  chainId,
  selectedCollection,
}: CollectionSearchModalProps) {
  const { t } = useTranslation()

  // refs for fixed size lists
  const fixedList = useRef<FixedSizeList>()

  const handleSelection = (collection: Collection) => {
    onDismiss()
    onCollectionSelect(collection)
  }

  return (
    <StyledModalContainer minWidth="320px">
      <ModalHeader>
        <ModalTitle>
          <Heading>{t('Select a collection')}</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <StyledModalBody>
        <Box margin="0 -24px">
          <CollectionList
            height={390}
            chainId={chainId}
            onCollectionSelect={handleSelection}
            selectedCollection={selectedCollection}
            fixedListRef={fixedList}
          />
        </Box>
      </StyledModalBody>
    </StyledModalContainer>
  )
}
