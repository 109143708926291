import React, { useState } from 'react'
import styled, { useTheme } from 'styled-components'
import { Button } from '@pancakeswap/uikit'
import Grid from '../../../theme/components/Box/Grid'
import Box from '../../../theme/components/Box/Box'
import getThemeValue from '../../../theme/getThemeValue'
import Heading from '../../../theme/components/Heading/Heading'
import { ModalBody, ModalCloseButton, ModalContainer, ModalHeader, ModalTitle } from '../Modal'
import WalletCard from './WalletCard'
import config, { walletLocalStorageKey } from './config'
import { Config, Login } from './types'

interface Props {
  login: Login
  onDismiss?: () => void
  displayCount?: number
  t: (key: string) => string
}

const Label = styled.text`
  font-size: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: #ffffff;
  background-color: #0050ff;
  border-radius: 12px;
  padding: 2px 8px;
  margin-left: 50%;
`

const Warning = styled.div`
  font-size: 14px;
  background-color: #ffefba;
  border-radius: 12px;
  padding: 8px 12px;
  margin: 24px;
  line-height: 1.25;
`

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: center;
`

const WalletWrapper = styled(Box)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.cardBorder};
`

/**
 * Checks local storage if we have saved the last wallet the user connected with
 * If we find something we put it at the top of the list
 *
 * @returns sorted config
 */
const getPreferredConfig = (walletConfig: Config[]) => {
  const preferredWalletName = localStorage.getItem(walletLocalStorageKey)
  const sortedConfig = walletConfig.sort((a: Config, b: Config) => a.priority - b.priority)

  if (!preferredWalletName) {
    return sortedConfig
  }

  const preferredWallet = sortedConfig.find((sortedWalletConfig) => sortedWalletConfig.title === preferredWalletName)

  if (!preferredWallet) {
    return sortedConfig
  }

  return [
    preferredWallet,
    ...sortedConfig.filter((sortedWalletConfig) => sortedWalletConfig.title !== preferredWalletName),
  ]
}

const ConnectModal: React.FC<Props> = ({ login, onDismiss = () => null, displayCount = 2, t }) => {
  const theme = useTheme()
  const [showExperimentalConnectors, setShowExperimentalConnectors] = useState(false)
  const sortedConfig = getPreferredConfig(config)
  const displayListRecommendedConfig = sortedConfig
    .filter((connector) => connector.priority === 1)
    .slice(0, displayCount)
  const displayListAlternativeConfig = sortedConfig
    .filter((connector) => connector.priority !== 1)
    .slice(0, displayCount)

  return (
    <ModalContainer minWidth="320px">
      <ModalHeader background={getThemeValue('colors.gradients.bubblegum')(theme)}>
        <ModalTitle>
          <Heading>{t('Connect Wallet')}</Heading>
        </ModalTitle>
        <ModalCloseButton onDismiss={onDismiss} />
      </ModalHeader>
      <ModalBody width={['320px', null, '340px']}>
        <WalletWrapper py="24px" maxHeight="453px" overflowY="auto">
          <Grid gridTemplateColumns="1fr 1fr">
            {displayListRecommendedConfig.map((wallet) => (
              <Box key={wallet.title}>
                <Label>{t('Recommended')}</Label>
                <WalletCard walletConfig={wallet} login={login} onDismiss={onDismiss} />
              </Box>
            ))}
          </Grid>
          {showExperimentalConnectors ? (
            <>
              <Warning>
                ⚠️ The following wallet connector integrations are at an experimental state and not recommend yet.
              </Warning>
              <Grid gridTemplateColumns="1fr 1fr">
                {displayListAlternativeConfig.map((wallet) => (
                  <Box key={wallet.title}>
                    <WalletCard walletConfig={wallet} login={login} onDismiss={onDismiss} />
                  </Box>
                ))}
              </Grid>
            </>
          ) : (
            <ButtonWrapper>
              <Button variant="secondary" scale="xs" onClick={() => setShowExperimentalConnectors(true)}>
                {t('Show Experimental Connectors')}
              </Button>
            </ButtonWrapper>
          )}
        </WalletWrapper>
      </ModalBody>
    </ModalContainer>
  )
}

export default ConnectModal
