import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 64 64"
        enableBackground="new 0 0 64 64"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <path
              d="M15,44.5c2.2,0.6,4.5,1,7,1c12.2,0,22-8.1,22-18s-9.8-18-22-18s-22,8.1-22,18c0,5.6,3.1,10.7,8,14c0,0,0,2.6-2.1,5.2
			c-0.6,0.7,0,1.8,0.9,1.6C12.2,47.5,15,44.5,15,44.5z"
            />
          </g>
        </g>
        <g>
          <g>
            <g>
              <path
                d="M64,33c0-9.6-6.7-17.4-20.6-18c2.9,3.5,4.6,7.8,4.6,12.5c0,9.6-7.3,17.8-17.5,20.8C33.8,50,37.8,51,42,51
				c2.5,0,4.8-0.4,7-1c0,0,2.8,3,8.1,3.8c0.9,0.1,1.5-0.9,0.9-1.6C56,49.6,56,47,56,47C60.9,43.7,64,38.6,64,33z"
              />
            </g>
          </g>
        </g>
      </svg>
    </Svg>
  )
}

export default Icon
