import { createReducer } from '@reduxjs/toolkit'

import { etherscanTxList } from './actions'

interface EtherscanAPITx {
  nonce?: string
  hash?: string
  to?: string
}

interface TransientState {
  etherscanTxList?: Array<EtherscanAPITx>
}

export const initialState: TransientState = {}

// the transient reducer should hold any data that can be removed when the app is closed
export default createReducer(initialState, (builder) =>
  builder.addCase(etherscanTxList, (state, { payload: { result } }) => {
    state.etherscanTxList = result
  }),
)
