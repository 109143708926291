import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1321.15 1321.15">
        <g id="Camada_2" data-name="Camada 2">
          <g id="Camada_1-2" data-name="Camada 1">
            <path d="M986.13,532.42c-.49-3.73-1-7.46-1.42-11.26-1-8.13-2-16.54-3.48-24.87-13-74.57-48.73-138.08-106.15-188.79C827.06,265.09,770,239,705.53,229.86c-5.74-.81-11.53-1.47-17.14-2.11l-.89-.1-7.08-.82-.34,0-37.83,0h-.19l-1.29.1c-3.65.49-7.31.94-11,1.39l-.25,0c-8.15,1-16.57,2-25,3.48C537,243.4,478,274.29,429.18,323.55c-46.05,46.49-75.77,102.9-88.34,167.67C325,573,341.24,657,386.66,727.91s115,120.81,195.92,140.58a329.46,329.46,0,0,0,78,9.44A325.91,325.91,0,0,0,967,662.91a311.44,311.44,0,0,0,18.14-85.55,20,20,0,0,1,.44-2.36c.16-.74.32-1.48.46-2.22l.19-1V533.89Zm-59,20.06c-.07,71-27.92,137.94-78.41,188.34s-117.08,78-187.86,78h-.51A265,265,0,0,1,472,740.23c-50.37-50.52-78-117.42-77.82-188.37a265.19,265.19,0,0,1,78.69-188.34c50.29-50.09,116.86-77.68,187.43-77.68h.95C808,286.36,927.28,406,927.14,552.48Z" />
            <path d="M719.12,531.75h0a91,91,0,0,0-40.4-9c-3.51,0-7.06,0-10.49,0h-1c-10.45.12-21.26.23-31.46-.93-14.54-1.65-25-14.72-24.26-30.39.68-15,12.52-27,27-27.5,17.17-.54,34.67-.5,51.61-.46,7.73,0,15.73,0,23.58,0h.54a70.35,70.35,0,0,0,8.79-.42c15.79-2,26.58-14.33,26.26-29.95-.32-15.89-12.22-27.89-28.29-28.51-7.14-.28-14.29-.25-21.21-.22h-.32l-7.06,0h-2.15c0-1.95,0-3.9,0-5.85,0-7.79,0-15.84-.09-23.81-.23-14-9.26-25.52-22.45-28.63a29.58,29.58,0,0,0-25.1,5.23,30.25,30.25,0,0,0-11.41,23.56c-.16,9.31-.12,18.77-.09,27.95,0,.73,0,1.45,0,2.18a88.56,88.56,0,0,0-73.48,118.27,88.57,88.57,0,0,0,82.08,58.48c5.19.1,10.41.06,15.45,0h.39c9.73-.08,19.78-.17,29.25.79,14.91,1.5,25.67,14.53,25,30.3-.61,15.11-12.25,26.86-27.68,27.94-2.3.16-4.78.23-7.83.24H650.21c-15.26,0-31,0-46.57.07-9.56.06-17.69,3.21-23.49,9.1a28.57,28.57,0,0,0-8.07,20.8c.14,16.93,13,28.9,31.37,29.11,7.16.09,14.65.07,20.67.05h7v1.64c0,9.51-.06,18.49.07,27.69.24,17,12.91,29.85,29.46,29.85h.18c16.56-.09,28.91-12.66,29.37-29.89.07-2.62,0-5.26,0-7.58v-.2c0-1,0-2,0-3V699.53c37.91-4.22,69.2-32.21,77-69.56A88.86,88.86,0,0,0,719.12,531.75Z" />
            <path d="M936.61,891.47c-10.11-1.43-20.68,3.79-29.76,14.7A64.83,64.83,0,0,1,891,919.94c-19.67,12-41.8,15.7-67.64,11.32-21.65-3.67-37.92-13.07-49.77-28.72a27.58,27.58,0,0,0-22-11.27h0c-9.06,0-17.63,4.44-23.52,12.19a65.6,65.6,0,0,1-26.23,20.95c-18.54,8.19-38.47,10.53-59.22,7-22-3.79-38.45-13.27-50.26-29a26.8,26.8,0,0,0-20.66-11c-8.94-.33-17.72,3.62-24.11,10.86-5,5.67-11,12.11-17.64,16.87-10.79,7.73-24.33,12.18-40.24,13.21-36.08,2.35-61.06-7.23-78.61-30.16-9.34-12.19-26.62-14.37-39.34-5A28,28,0,0,0,365,935.75a114.82,114.82,0,0,0,16.17,18.72c22,20.44,50.09,32.26,83.48,35.12,40.15,3.44,75.6-6.08,105.38-28.29,49.83,38.73,131.77,38.94,181.31.1h0C779.29,981,808.91,990.74,840,990.74q31.8,0,65.49-13.71c26.17-10.66,44.75-27.42,55.2-49.81l.54-1.14V914.42l-.48-1.09C955.13,900.64,946.57,892.87,936.61,891.47Z" />
            <path d="M960.12,1016c-3.9-11.85-12.16-18.94-23.93-20.54-13.92-1.88-22.8,6.48-28.28,12.94a74.83,74.83,0,0,1-17.38,15.5c-18.48,11.31-40.33,15.15-65,11.42-22.67-3.43-39.41-12.67-51.16-28.23-5.8-7.68-14.16-12.09-22.93-12.1h0c-9,0-17.67,4.59-23.75,12.59a63.89,63.89,0,0,1-23.91,19.59c-18.38,8.73-38.41,11.48-59.54,8.15-22.75-3.58-38.65-12.18-50-27.07-6.5-8.51-15.08-13.2-24.15-13.2h0c-9,0-17.55,4.66-24,13.1A62.69,62.69,0,0,1,524.8,1026a95.42,95.42,0,0,1-51.55,10.35c-28.12-2-47.52-11.25-61.05-29.15-10-13.25-27.41-16-40.44-6.37a28.34,28.34,0,0,0-11.55,19.37,28,28,0,0,0,6.4,21.38c6.34,7.73,14.95,17.6,25.27,25.24,24.18,17.93,55.72,27.65,88.86,27.28s64.55-10.58,88.79-28.83l.15-.12.51-.38c25.22,18.92,57.31,29.34,90.39,29.34h0c33.11,0,65.32-10.42,90.71-29.36,27.86,19.74,57.51,29.63,88.66,29.63q31.72,0,65.44-13.7c26.12-10.64,44.69-27.4,55.21-49.8l.54-1.15v-12.09l-.85-1.37C960.27,1016.19,960.2,1016.09,960.12,1016Z" />
            <path d="M660.57,1321.15A660.74,660.74,0,0,1,403.44,51.93a660.4,660.4,0,0,1,917.71,608.64,42.52,42.52,0,1,1-85,0C1236.11,343.22,977.93,85,660.57,85S85,343.22,85,660.57s258.18,575.54,575.53,575.54c172.94,0,335.15-76.74,445-210.54a42.52,42.52,0,1,1,65.72,54C1045.22,1233.08,859.06,1321.15,660.57,1321.15Z" />
            <path d="M1211.67,979.18a42.54,42.54,0,0,1-37.54-62.43c10.22-19.29,36.26-91.88,36.26-121.29a42.52,42.52,0,1,1,85,0c0,22-6.13,52.38-18.22,90.12-8.86,27.63-20.08,56.15-27.93,71A42.52,42.52,0,0,1,1211.67,979.18Z" />
          </g>
        </g>
      </svg>
    </Svg>
  )
}

export default Icon
