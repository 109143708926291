// Set of helper functions to facilitate wallet setup

// import { BASE_BSC_SCAN_URL, BASE_URL } from 'config'
import { useDispatch } from 'react-redux'
import { BASE_URL } from 'config'
import { useMemo } from 'react'
import { reset } from 'state/block'
import { ChainId } from '../config/constants/chainId'
import NETWORK_URLS, { networks } from '../config/constants/networks'
import { usePageVisibility } from './useBrowserTabVisibility'

export function useSetupNetwork() {
  const dispatch = useDispatch()
  const isPageVisible = usePageVisibility()

  const _setupNetwork = useMemo(
    () => (chainId: ChainId) => {
      return setupNetwork(chainId, dispatch, isPageVisible)
    },
    [dispatch, isPageVisible],
  )

  return {
    setupNetwork: _setupNetwork,
  }
}

/**
 * Prompt the user to add BSC as a network on Metamask, or switch to BSC if the wallet is on a different network
 * @returns {boolean} true if the setup succeeded, false otherwise
 */
export const setupNetwork = async (chainId: ChainId, dispatch: any, isBrowserTabActive?: boolean) => {
  const provider = window.ethereum

  if (provider && isBrowserTabActive) {
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${chainId.toString(16)}` }],
      })

      dispatch(reset())

      return true
    } catch (switchError) {
      // This error code indicates that the chain has not been added to MetaMask.
      // @ts-ignore
      if (switchError.code === 4902) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: `0x${chainId.toString(16)}`,
                chainName: networks.filter((netw) => netw.chainId === chainId)[0].name,
                rpcUrls: [NETWORK_URLS[chainId]],
              },
            ],
          })
          return true
        } catch (addError) {
          console.error('Failed to setup the network in Metamask:', addError)
          return false
        }
      }
      // handle other "switch" errors
      return false
    }
  } else {
    console.error("Can't setup the network on metamask because window.ethereum is undefined")
    return false
  }
}

/**
 * Prompt the user to add a custom token to metamask
 * @param tokenAddress
 * @param tokenSymbol
 * @param tokenDecimals
 * @returns {boolean} true if the token has been added, false otherwise
 */
export const registerToken = async (tokenAddress: string, tokenSymbol: string, tokenDecimals: number) => {
  const tokenAdded = await window.ethereum.request({
    method: 'wallet_watchAsset',
    params: {
      type: 'ERC20',
      options: {
        address: tokenAddress,
        symbol: tokenSymbol,
        decimals: tokenDecimals,
        image: `${BASE_URL}/images/tokens/${tokenAddress}.png`,
      },
    },
  })

  return tokenAdded
}
