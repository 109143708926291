import { useEffect, useState } from 'react'

// document passed as argument to avoid type issues for msHidden and webkitHidden
export const getBrowserVisibilityEventName = (document: Document & { msHidden?: boolean; webkitHidden?: boolean }) => {
  if (typeof document.hidden !== 'undefined') {
    // Opera 12.10 and Firefox 18 and later support
    return 'visibilitychange'
  }
  if (typeof document.msHidden !== 'undefined') {
    return 'msvisibilitychange'
  }
  if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitvisibilitychange'
  }
  return 'visibilitychange'
}

export function getBrowserDocumentHiddenNameProp(document: Document & { msHidden?: boolean; webkitHidden?: boolean }) {
  if (typeof document.hidden !== 'undefined') {
    return 'hidden'
  }
  if (typeof document.msHidden !== 'undefined') {
    return 'msHidden'
  }
  if (typeof document.webkitHidden !== 'undefined') {
    return 'webkitHidden'
  }
  return 'hidden'
}

export function getIsDocumentHidden() {
  return document[getBrowserDocumentHiddenNameProp(document)]
}

export function usePageVisibility() {
  const [isVisible, setIsVisible] = useState(!getIsDocumentHidden())
  const onVisibilityChange = () => {
    const isHidden = getIsDocumentHidden()
    return setIsVisible(!isHidden)
  }
  useEffect(() => {
    const visibilityChangeName = getBrowserVisibilityEventName(document)

    document.addEventListener(visibilityChangeName, onVisibilityChange)
  })

  return isVisible
}
