import { StakingPoolConfig } from '../types'
import { ChainId } from '../chainId'
import getLPTokens, { getLPTokensList } from '../tokensLP'
import getTokens, { getTokensList } from '../tokens'
import { asyncExtendedPools } from '../asyncExtendedPools'

const contractAddress = {
  [ChainId.RINKEBY]: '0x681bCFbb9Be4b812dd57f4A3Ce916e9d2356693D',
  [ChainId.BSCTEST]: '0xc834489D1df63baD295f298f212aD70D2a0722e4',
  [ChainId.LETH]: '0xF93AE55237221c074d45cafa9A895c396cf5F52D',
  [ChainId.LBSC]: '0xF93AE55237221c074d45cafa9A895c396cf5F52D',
}

let pools

function initialPools() {
  const lpTokens = getLPTokens()
  const tokens = getTokens()

  pools = [
    {
      stakingPoolId: 2,
      stakingToken: lpTokens.usdc,
      earningToken: tokens.usdc,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '0.01261',
      isFinished: false,
    },
    {
      stakingPoolId: 1,
      stakingToken: lpTokens.usdt,
      earningToken: tokens.usdt,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '0.01261',
      isFinished: false,
    },
    {
      stakingPoolId: 0,
      stakingToken: lpTokens.txl,
      earningToken: tokens.txl,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '0.01261',
      isFinished: false,
    },
    /*
    {
      stakingPoolId: 3,
      stakingToken: lpTokens.link,
      earningToken: tokens.link,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '0.01261',
      isFinished: false,
    },
     */
  ]
}

export default function getPools(): StakingPoolConfig[] {
  if (!pools) {
    initialPools()
  }

  return pools
}

asyncExtendedPools(
  'liquidityMining',
  'stakingPoolId',
  ChainId.RINKEBY,
  contractAddress,
  getPools,
  getLPTokensList,
  getTokensList,
  (pool) => pools.push(pool),
).catch((err) => console.error('Dynamic pools', err))
