import { useCallback, useMemo } from 'react'
import { CurrencyAmount, Percent, JSBI, Currency } from '@pancakeswap/sdk'
import { useDispatch, useSelector } from 'react-redux'
import { useWeb3React } from '@web3-react/core'
import {
  calculateGasMargin,
  getBridgeSourceContract,
  getBuyBackAndBurnContract,
  getLiquidityManagerContract,
} from 'utils'
import { AppDispatch, AppState } from 'state'
import { useTransactionAdder } from 'state/transactions/hooks'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { getToken, getWhitelistedTokensForBurn } from 'config/constants/tokens'
import { TokenAmount } from 'sdk/entities/tokenAmount'
import { Token } from 'sdk/entities/token'
import { getNativeCurrency, isNativeSymbol } from 'sdk/entities/currency'
import { useTokenContract } from 'hooks/useContract'
import { useSingleCallResult } from 'state/multicall/hooks'
import getAddresses from 'utils/getAddresses'
import { LiquidityPositionState } from 'hooks/useLiquidityPosition'
import { useBridgingLiquidity } from 'state/bridgeERC20/useBridgingLiquidity'
import useWrappedNative from 'state/bridgeERC20/useWrappedNative'
import {
  reset,
  selectCurrency,
  setCurrencyBeingRemoved,
  typeAmount,
  typeRecipient,
  selectSourceChain,
  selectTargetChain,
  setDeposit,
} from './actions'
import { LiquidityState } from './reducer'
import useUserAddedTokens from '../user/hooks/useUserAddedTokens'
import { useTransactionInfo, getWaitConfirmations } from '../bridgeERC20/hooks'
import { useSupportedTokens } from '../../hooks/Tokens'
import { ChainId } from '../../config/constants/chainId'
import { useBurnTokensBalancesWithLoadingIndicator } from '../wallet/hooks'

export function useLiquidityState(): LiquidityState {
  return useSelector<AppState, AppState['liquidity']>((state) => {
    return state.liquidity
  })
}

export function useLiquidityManagerActionHandlers() {
  const dispatch = useDispatch<AppDispatch>()

  const onReset = useCallback(() => {
    dispatch(reset())
  }, [dispatch])

  const onAmountInput = useCallback(
    (typedAmount: string) => {
      dispatch(typeAmount({ typedAmount }))
    },
    [dispatch],
  )

  const onSelectCurrency = useCallback(
    (currencyId: string) => {
      dispatch(selectCurrency({ currencyId }))
    },
    [dispatch],
  )

  const onSelectSourceChain = useCallback(
    (sourceChainId: number) => {
      dispatch(selectSourceChain({ sourceChainId }))
    },
    [dispatch],
  )

  const onSelectTargetChain = useCallback(
    (targetChainId: number) => {
      dispatch(selectTargetChain({ targetChainId }))
    },
    [dispatch],
  )

  const onSetDepositHash = useCallback(
    (depositHash: string, sourceChainId: number) => {
      dispatch(setDeposit({ depositHash, sourceChainId }))
    },
    [dispatch],
  )

  const onRecipientInput = useCallback(
    (typedRecipient: string) => {
      dispatch(typeRecipient({ typedRecipient }))
    },
    [dispatch],
  )

  const onSetCurrencyIdBeingRemoved = useCallback(
    (currencyIdBeingRemoved: string) => {
      dispatch(setCurrencyBeingRemoved({ currencyIdBeingRemoved }))
    },
    [dispatch],
  )

  return {
    onReset,
    onAmountInput,
    onSelectCurrency,
    onSelectTargetChain,
    onSelectSourceChain,
    onSetDepositHash,
    onRecipientInput,
    onSetCurrencyIdBeingRemoved,
  }
}

// used to get current and max pool size, checks if pool can be used (amount dependent)
export function useLiquidityPoolInfo(
  token: Token,
  amount: TokenAmount,
): { maxPoolSize: JSBI; currentPoolSize: JSBI; amountPoolInvalid: boolean; poolFull: boolean } {
  const { account, chainId, library } = useActiveWeb3React()
  const { wrappedNative } = useWrappedNative()
  const managerContract = getLiquidityManagerContract(library, chainId, account)
  const tokenAddressInput = useMemo(() => {
    if (isNativeSymbol(token?.symbol)) {
      return [wrappedNative]
    }

    return [token?.address]
  }, [token, wrappedNative])
  const lpLookup = useSingleCallResult(managerContract, 'maxPoolSize', tokenAddressInput)

  const maxPoolSize = useMemo(() => {
    if (lpLookup.result) return JSBI.BigInt(lpLookup.result[0])
    return undefined
  }, [lpLookup])

  const tokenContract = useTokenContract(token?.address)
  const bridgeAddress = useMemo(() => [getAddresses(chainId).BridgeV2ERC20], [chainId])
  const balanceLookup = useSingleCallResult(tokenContract, 'balanceOf', bridgeAddress)

  const currentPoolSize = useMemo(() => {
    if (balanceLookup.result) return JSBI.BigInt(balanceLookup.result[0])
    return undefined
  }, [balanceLookup])

  const poolFull =
    maxPoolSize !== undefined &&
    currentPoolSize !== undefined &&
    JSBI.NE(maxPoolSize, 0) &&
    JSBI.GE(currentPoolSize, maxPoolSize)

  const amountPoolInvalid =
    amount !== undefined &&
    maxPoolSize !== undefined &&
    currentPoolSize !== undefined &&
    JSBI.NE(maxPoolSize, 0) &&
    JSBI.GT(JSBI.add(currentPoolSize, amount.raw), maxPoolSize)

  return { maxPoolSize, currentPoolSize, amountPoolInvalid, poolFull }
}

export function useDerivedLiquidityInfo() {
  const { chainId } = useWeb3React()
  const {
    depositHash,
    currencyId: stateCurrency,
    sourceChainId: stateSource,
    targetChainId: stateTarget,
    currencyIdBeingRemoved,
  } = useLiquidityState()

  const userAddedTokens = useUserAddedTokens()
  const depositTx = useTransactionInfo(chainId, depositHash)
  const sourceChainId = stateSource ?? chainId
  const targetChainId = stateTarget ?? null
  const currencyId = stateCurrency ?? ''

  let currency = isNativeSymbol(currencyId) ? getNativeCurrency(chainId) : getToken(chainId, currencyId)

  const WAIT_CONFIRMATIONS = getWaitConfirmations(chainId)

  // if deposited, compare to current block until enough confirmations
  const confirmations = depositTx.confirmations || 0
  const waitingDepositConfirmations =
    Boolean(depositHash) && confirmations < WAIT_CONFIRMATIONS && chainId === sourceChainId

  const networkSwitchNeeded = depositHash && chainId !== targetChainId
  const deposited = Boolean(depositHash)

  if (!currency) {
    currency = userAddedTokens.filter((token) => token.symbol === stateCurrency || token.address === stateCurrency)[0]
  }

  return {
    currencyId,
    currency,
    currencyIdBeingRemoved,
    depositHash,
    sourceChainId,
    targetChainId,
    confirmations,
    waitingDepositConfirmations,
    deposited,
    networkSwitchNeeded,
  }
}

export function useWithdrawLiquidityInfo(
  tokenState: LiquidityPositionState,
  lpToken: Token,
  position: TokenAmount,
  percentToWithdraw: Percent,
): TokenAmount | undefined {
  const withdrawAmount = useMemo(() => {
    if (tokenState === LiquidityPositionState.LOADING || !position) return undefined

    return new TokenAmount(lpToken, percentToWithdraw.multiply(position.raw).quotient)
  }, [tokenState, lpToken, position, percentToWithdraw])

  return withdrawAmount
}

export function useAddLiquidityCallback(
  tokenCurrency: Token | Currency | undefined,
  amount: CurrencyAmount | undefined,
): { callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const isNative = isNativeSymbol(tokenCurrency?.symbol)
  const contract = getLiquidityManagerContract(library, chainId, account || undefined)

  return useMemo(() => {
    if (!account || !chainId) {
      return {
        callback: null,
        error: 'No wallet connected.',
      }
    }

    if (!contract) {
      return {
        callback: null,
        error: 'Manager contract not found',
      }
    }

    return {
      error: null,
      callback: async function onAddLiquidity(): Promise<string> {
        const callMethod = isNative ? 'addLiquidityNative' : 'addLiquidityERC20'
        const value = amount?.raw.toString()
        const inputs = isNative ? [value] : [(tokenCurrency as Token).address, value]
        const args = isNative ? { value, from: account } : { from: account }

        try {
          const gasEstimate = await contract.estimateGas[callMethod](...inputs, args)
          const gasLimit = calculateGasMargin(gasEstimate)

          return contract[callMethod](...inputs, { gasLimit, ...args }).then((response: any) => {
            addTransaction(response)
            return response.hash
          })
        } catch (gasError) {
          return contract.callStatic[callMethod](...inputs, args)
            .catch((error) => {
              console.debug(error)
              throw new Error(error.data.message)
            })
            .then((result) => {
              console.debug('Unexpected successful call after failed estimate gas', gasError, result)
              throw new Error('Unexpected issue with estimating the gas. Please try again.')
            })
        }
      },
    }
  }, [account, chainId, contract, isNative, amount, tokenCurrency, addTransaction])
}

interface RemoveLiquidityCallback {
  bridgingLiquidity: TokenAmount | string | undefined
  callback: null | (() => Promise<string>)
  error: string | null
}

export function useRemoveLiquidityCallback(
  sourceTokenAddress: string | undefined,
  amount: CurrencyAmount | undefined,
  targetChain: ChainId | undefined,
): RemoveLiquidityCallback {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const { wrappedNative } = useWrappedNative()

  if (!library) {
    throw new Error('Missing input parameters')
  }

  // its a native withdrawal, if the lp token has the same address as the wrapped native token
  const isNative = wrappedNative === sourceTokenAddress

  // CrossChainBridgeERC20LiquidityManagerV1
  const contract = getLiquidityManagerContract(library, chainId, account || undefined)
  const bridgingLiquidity = useBridgingLiquidity(sourceTokenAddress, targetChain)

  return useMemo(() => {
    if (!account || !chainId) {
      return {
        callback: null,
        error: 'No wallet connected.',
        bridgingLiquidity: undefined,
      }
    }

    if (!contract) {
      return {
        callback: null,
        error: 'Manager contract not found',
        bridgingLiquidity: undefined,
      }
    }

    return {
      error: null,
      bridgingLiquidity,
      callback: async function onRemoveLiquidity(): Promise<string> {
        const callMethod = isNative ? 'withdrawLiquidityNative' : 'withdrawLiquidityERC20'
        const value = amount?.raw.toString()
        const inputs = isNative ? [value] : [sourceTokenAddress, value]
        const args = { from: account }

        try {
          const gasEstimate = await contract.estimateGas[callMethod](...inputs, args)
          const gasLimit = calculateGasMargin(gasEstimate)

          return contract[callMethod](...inputs, { gasLimit, ...args }).then((response: any) => {
            addTransaction(response)
            return response.hash
          })
        } catch (gasError) {
          return contract.callStatic[callMethod](...inputs, args)
            .catch((error) => {
              console.debug(error)
              throw new Error(error.data.message)
            })
            .then((result) => {
              console.debug('Unexpected successful call after failed estimate gas', gasError, result)
              throw new Error('Unexpected issue with estimating the gas. Please try again.')
            })
        }
      },
    }
  }, [account, chainId, contract, bridgingLiquidity, isNative, sourceTokenAddress, amount, addTransaction])
}

interface RemoveLiquidityInAnotherNetworkCallbackProps {
  liquidityWithdrawalFee?: TokenAmount | undefined
  bridgingLiquidity: TokenAmount | string | undefined
  callback: null | (() => Promise<string>)
  error: string | null
}

export function useRemoveLiquidityInAnotherNetworkCallback(
  sourceTokenAddress: string | undefined,
  amount: CurrencyAmount | undefined,
  targetChainId: number | undefined,
): RemoveLiquidityInAnotherNetworkCallbackProps {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  if (!library) {
    throw new Error('Missing input parameters')
  }

  // CrossChainBridgeV2ERC20
  const contract = getBridgeSourceContract(library, chainId, account || undefined)
  const bridgingLiquidity = useBridgingLiquidity(sourceTokenAddress, chainId)

  return useMemo(() => {
    if (!account || !chainId) {
      return {
        callback: null,
        error: 'No wallet connected.',
        bridgingLiquidity: undefined,
      }
    }

    if (!contract) {
      return {
        callback: null,
        error: 'Manager contract not found',
        bridgingLiquidity: undefined,
      }
    }

    return {
      error: null,
      bridgingLiquidity,
      callback: async function onRemoveLiquidityInAnotherNetwork(): Promise<string> {
        const value = amount?.raw.toString()
        const targetChain = targetChainId?.toString()
        const inputs = [sourceTokenAddress, value, account, targetChain]
        const args = { from: account }
        try {
          const gasEstimate = await contract.estimateGas.burnLpTokenForWithdrawalInOtherNetwork(...inputs, args)
          const gasLimit = calculateGasMargin(gasEstimate)

          return contract
            .burnLpTokenForWithdrawalInOtherNetwork(...inputs, { gasLimit, ...args })
            .then((response: any) => {
              addTransaction(response)

              return response.hash
            })
        } catch (gasError) {
          return contract.callStatic
            .burnLpTokenForWithdrawalInOtherNetwork(...inputs, args)
            .catch((error) => {
              console.debug(error)
              throw new Error(error.data.message)
            })
            .then((result) => {
              console.debug('Unexpected successful call after failed estimate gas', gasError, result)
              throw new Error('Unexpected issue with estimating the gas. Please try again.')
            })
        }
      },
    }
  }, [account, chainId, contract, bridgingLiquidity, sourceTokenAddress, amount, targetChainId, addTransaction])
}
