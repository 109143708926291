import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1321.15 1321.15">
        <g id="Camada_2">
          <g id="Camada_1-2">
            <path d="M316.27,778.05c3.59-14.71,5.93-29.88,11-44.07,25-70.21,101.83-114.41,175.4-101.69C570.53,644,620,690.86,635.51,758.15c14.54,63-15.12,134.41-70,168.65-70.13,43.74-155.24,31.7-210-30.15C334.16,872.55,322,844,317.67,812.11c-.33-2.41-.93-4.77-1.4-7.16ZM478,731c-32.8-.27-60.31,26.91-60.76,60-.43,32.73,26.66,60.3,59.86,60.92,32.89.62,61-27.14,61.09-60.36C538.26,758.75,511,731.3,478,731Z" />
            <path d="M965.47,691.64c-12-4.92-22.18-10-32.94-13.42-69.24-21.89-142.58,4-182.6,64.59-4.24,6.42-8.52,8.77-16.12,8.53-19.24-.58-38.51-.19-58-.19-18.11-72.39-60.36-124.28-131-149.39s-135.72-11.28-195.76,33.77l-26-31.36c12.5-8.17,24-17.14,36.77-23.81,83.33-43.57,165.69-41.69,246.8,6.4a22,22,0,0,0,10.63,2.87c88.3.16,176.6,0,264.89.16,39.5,0,71.57,24.78,80,62.7C964.9,664.94,964.42,678.1,965.47,691.64Z" />
            <path d="M884.14,710.82c66.68.23,121,54.77,120.74,121.32s-54.77,121-121.32,120.74-121-54.77-120.73-121.32S817.59,710.6,884.14,710.82Zm19.84,121A20.13,20.13,0,1,0,883.88,852,20.3,20.3,0,0,0,904,831.82Z" />
            <path d="M417.05,514.62V408.37H377.3V368.26H699.2v39.55H664.47c12.45,47.49,24.65,94.05,37.11,141.59H694.4c-22,0-43.92.13-65.87-.13a23,23,0,0,1-10.68-2.87q-70.41-40-151.24-37C450.34,510.05,434.16,512.77,417.05,514.62Z" />
            <path d="M863.46,428.68v39.37c-15.89,4.5-20,9.91-20,26.4v54.62h-40c-.12-1.88-.39-3.8-.37-5.72.26-20.83-.73-41.78,1.16-62.46C807.09,450.91,832.41,429.34,863.46,428.68Z" />
            <path d="M679.74,851.55V791.83h46.08c-1.27,19.77-2.53,39.46-3.83,59.72Z" />
            <path d="M477.51,771.38a20.12,20.12,0,1,1-20,20.28A20.31,20.31,0,0,1,477.51,771.38Z" />
            <path d="M660.57,1321.15A660.74,660.74,0,0,1,403.44,51.93a660.4,660.4,0,0,1,917.71,608.64,42.52,42.52,0,1,1-85,0C1236.11,343.22,977.93,85,660.57,85S85,343.22,85,660.57s258.18,575.54,575.53,575.54c172.94,0,335.15-76.74,445-210.54a42.52,42.52,0,1,1,65.72,54C1045.22,1233.08,859.06,1321.15,660.57,1321.15Z" />
            <path d="M1211.67,979.18a42.54,42.54,0,0,1-37.54-62.43c10.22-19.29,36.26-91.88,36.26-121.29a42.52,42.52,0,1,1,85,0c0,22-6.13,52.38-18.22,90.12-8.86,27.63-20.08,56.15-27.93,71A42.52,42.52,0,0,1,1211.67,979.18Z" />
          </g>
        </g>
      </svg>
    </Svg>
  )
}

export default Icon
