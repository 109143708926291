import { ChainId } from './chainId'

const Avalanche = '/images/networks/avalanche-network.jpg'
const Bsc = '/images/networks/bsc-network.jpg'
const Fantom = '/images/networks/fantom-network.jpg'
// const Harmony = '/images/networks/harmonyone-network.jpg'
const Mainnet = '/images/networks/mainnet-network.jpg'
const Polygon = '/images/networks/polygon-network.jpg'
const Rinkeby = '/images/networks/rinkeby-network.jpg'
const Autobahn = '/images/networks/autobahn-network.png'

export const NETWORK_ICON = {
  [ChainId.MAINNET]: Mainnet,
  [ChainId.RINKEBY]: Rinkeby,
  [ChainId.FANTOM]: Fantom,
  [ChainId.BSC]: Bsc,
  [ChainId.BSCTEST]: Bsc,
  [ChainId.POLYGON]: Polygon,
  [ChainId.AVALANCHE]: Avalanche,
  //   [ChainId.HARMONY]: Harmony,
  [ChainId.LBSC]: Bsc,
  [ChainId.LETH]: Mainnet,
  [ChainId.AUTOBAHN]: Autobahn,
}
