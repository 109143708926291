import { ethers } from 'ethers'
import { Farm } from 'state/types'
import fetchPublicFarmData from './fetchPublicFarmData'
import { ChainId } from '../../config/constants/chainId'

const fetchFarm = async (
  farm: Farm,
  chainId: ChainId,
  signer?: ethers.Signer | ethers.providers.Provider,
): Promise<Farm> => {
  const farmPublicData = await fetchPublicFarmData(farm, chainId, signer)

  return { ...farm, ...farmPublicData }
}

export default fetchFarm
