import { useMemo } from 'react'
import { useSelector } from 'react-redux'

import { Token } from 'sdk/entities/token'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { ChainId } from 'config/constants/chainId'

import { AppState } from '../../index'
import { deserializeToken } from './helpers'

export default function useUserAddedTokens(): Token[] {
  const { chainId } = useActiveWeb3React()
  const serializedTokensMap = useSelector<AppState, AppState['user']['tokens']>(({ user: { tokens } }) => tokens)
  return useMemo(() => {
    if (!chainId) return []
    return Object.values(serializedTokensMap?.[chainId as ChainId] ?? {}).map(deserializeToken)
  }, [serializedTokensMap, chainId])
}
