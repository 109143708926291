import { Contract } from 'ethers'
import { getAddress } from '@ethersproject/address'
import { AddressZero } from '@ethersproject/constants'
import { JsonRpcSigner, Web3Provider } from '@ethersproject/providers'
import { BigNumber } from '@ethersproject/bignumber'
import { Currency, CurrencyAmount, ETHER, JSBI, Percent, Token } from '@pancakeswap/sdk'
import { BASE_EXPLORER_SCAN_URLS } from '../config'
import { TokenAddressMap } from '../state/lists/hooks'
import getAddresses from './getAddresses'
import CROSSCHAIN_BRIDGE_ERC20_LIQUIDITY_MANAGER_ABI from '../config/abi/CrossChainBridgeERC20LiquidityManagerV1.json'
import CROSSCHAIN_BRIDGE_ERC20_ABI from '../config/abi/CrossChainBridgeERC20.json'
import BRIDGE_V2_ERC721_ABI from '../config/abi/BridgeV2ERC721V1.json'
import MINTABLE_ERC721_ABI from '../config/abi/MintableERC721.json'
import { BUY_BACK_AND_BURN_ABI } from '../config/abi/BuyBackAndBurn'
import { ChainId } from '../config/constants/chainId'

// returns the checksummed address if the address is valid, otherwise returns false
export function isAddress(value: any): string | false {
  try {
    return getAddress(value)
  } catch {
    return false
  }
}

export function getBscScanLink(
  data: string | number,
  type: 'transaction' | 'token' | 'address' | 'block' | 'countdown',
  chainId: ChainId = ChainId.ETHEREUM,
): string {
  switch (type) {
    case 'transaction': {
      return `${BASE_EXPLORER_SCAN_URLS[chainId]}/tx/${data}`
    }
    case 'token': {
      return `${BASE_EXPLORER_SCAN_URLS[chainId]}/token/${data}`
    }
    case 'block': {
      return `${BASE_EXPLORER_SCAN_URLS[chainId]}/block/${data}`
    }
    case 'countdown': {
      return `${BASE_EXPLORER_SCAN_URLS[chainId]}/block/countdown/${data}`
    }
    default: {
      return `${BASE_EXPLORER_SCAN_URLS[chainId]}/address/${data}`
    }
  }
}

// shorten the checksummed version of the input address to have 0x + 4 characters at start and end
export function shortenAddress(address: string, chars = 4): string {
  const parsed = isAddress(address)
  if (!parsed) {
    return '0x0000...0000'
    // throw Error(`Invalid 'address' parameter '${address}'.`)
  }
  return `${parsed.substring(0, chars + 2)}...${parsed.substring(42 - chars)}`
}

// add 10%
export function calculateGasMargin(value: BigNumber): BigNumber {
  return value.mul(BigNumber.from(10000).add(BigNumber.from(1000))).div(BigNumber.from(10000))
}

// converts a basis points value to a sdk percent
export function basisPointsToPercent(num: number): Percent {
  return new Percent(JSBI.BigInt(num), JSBI.BigInt(10000))
}

export function calculateSlippageAmount(value: CurrencyAmount, slippage: number): [JSBI, JSBI] {
  if (slippage < 0 || slippage > 10000) {
    throw Error(`Unexpected slippage value: ${slippage}`)
  }
  return [
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 - slippage)), JSBI.BigInt(10000)),
    JSBI.divide(JSBI.multiply(value.raw, JSBI.BigInt(10000 + slippage)), JSBI.BigInt(10000)),
  ]
}

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
  return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
  return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
  if (!isAddress(address) || address === AddressZero) {
    throw Error(`Invalid 'address' parameter '${address}'.`)
  }

  return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function escapeRegExp(string: string): string {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function isTokenOnList(defaultTokens: TokenAddressMap, currency?: Currency): boolean {
  if (currency === ETHER) return true
  return Boolean(currency instanceof Token && defaultTokens[currency.chainId]?.[currency.address])
}

export function getBridgeSourceContract(library: Web3Provider, chainId?: ChainId, account?: string): Contract | null {
  if (!chainId) {
    return null
  }
  return getContract(getAddresses(chainId).BridgeV2ERC20, CROSSCHAIN_BRIDGE_ERC20_ABI, library, account)
}

export function getLiquidityManagerContract(
  library: Web3Provider,
  chainId?: ChainId,
  account?: string,
): Contract | null {
  if (!chainId) {
    return null
  }
  return getContract(
    getAddresses(chainId).BridgeV2LiquidityManager,
    CROSSCHAIN_BRIDGE_ERC20_LIQUIDITY_MANAGER_ABI,
    library,
    account,
  )
}

export function getBridgeV2ERC721Contract(library: Web3Provider, chainId?: ChainId, account?: string): Contract | null {
  if (!chainId) {
    return null
  }
  const adresses = getAddresses(chainId)
  if (!adresses || !adresses.BridgeV2ERC721) return null

  return getContract(adresses.BridgeV2ERC721, BRIDGE_V2_ERC721_ABI, library, account)
}

export function getERC721Contract(library: Web3Provider, collectionAddress: string, account?: string): Contract | null {
  return getContract(collectionAddress, MINTABLE_ERC721_ABI, library, account)
}

export function getBuyBackAndBurnContract(library: Web3Provider, chainId?: ChainId, account?: string): Contract | null {
  if (!chainId) {
    return null
  }
  const adresses = getAddresses(chainId)
  if (!adresses || !adresses.BridgeV2BuyBackAndBurn) return null

  return getContract(adresses.BridgeV2BuyBackAndBurn, BUY_BACK_AND_BURN_ABI, library, account)
}

export function getBridgeTokenDexLink(chainId: ChainId) {
  switch (chainId) {
    case ChainId.MAINNET:
      return 'https://app.uniswap.org/#/add/v2/ETH/0x92868A5255C628dA08F550a858A802f5351C5223'
    case ChainId.BSC:
      return 'https://pancakeswap.finance/add/BNB/0x92868A5255C628dA08F550a858A802f5351C5223'
    case ChainId.POLYGON:
      return 'https://app.sushi.com/add/0x7ceB23fD6bC0adD59E62ac25578270cFf1b9f619/0x92868A5255C628dA08F550a858A802f5351C5223'
    case ChainId.AVALANCHE:
      return 'https://traderjoexyz.com/pool/AVAX/0xC0367f9b1f84Ca8DE127226AC2A994EA4bf1e41b'
    case ChainId.FANTOM:
      return 'https://traderjoexyz.com/pool/AVAX/0xC0367f9b1f84Ca8DE127226AC2A994EA4bf1e41b'
    default:
      return ''
  }
}

export function getSwapToBridgeTokenDexLink(chainId: ChainId) {
  switch (chainId) {
    case ChainId.MAINNET:
      return 'https://app.uniswap.org/#/swap?outputCurrency=0x92868A5255C628dA08F550a858A802f5351C5223'
    case ChainId.BSC:
      return 'https://pancakeswap.finance/swap?outputCurrency=0x92868A5255C628dA08F550a858A802f5351C5223'
    case ChainId.POLYGON:
      return 'https://app.sushi.com/swap?outputCurrency=0x92868A5255C628dA08F550a858A802f5351C5223'
    case ChainId.AVALANCHE:
      return 'https://traderjoexyz.com/trade?outputCurrency=0xC0367f9b1f84Ca8DE127226AC2A994EA4bf1e41b#/'
    case ChainId.FANTOM:
      return 'https://spookyswap.finance/swap?outputCurrency=0x92868a5255c628da08f550a858a802f5351c5223'
    default:
      return '#'
  }
}

export const capitalizeFirstLetter = (string) => string && string.charAt(0).toUpperCase() + string.slice(1)
