import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'

const InputWrapper = styled.div`
  flex: 1;
`

const StyledInput = styled.input<{ error?: boolean; fontSize?: string; align?: string }>`
  color: ${({ error, theme }) => (error ? theme.colors.failure : theme.colors.text)};
  max-width: 120px;
  height: 32px;
  position: relative;
  font-weight: 500;
  outline: none;
  border: none;
  border-radius: 16px;
  flex: 1 1 auto;
  background-color: #e9eaeb;
  font-size: 16px;
  text-align: ${({ align }) => align && align};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 0.5rem;
  -webkit-appearance: textfield;

  ::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  [type='number'] {
    -moz-appearance: textfield;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  ::placeholder {
    color: #ccc;
  }
`
export const Input = React.memo(function InnerInput({
  value,
  onUserInput,
  placeholder,
  ...rest
}: {
  value: string | number
  onUserInput: (input: string) => void
  error?: boolean
  fontSize?: string
  align?: 'right' | 'left'
} & Omit<React.HTMLProps<HTMLInputElement>, 'ref' | 'onChange' | 'as'>) {
  const { t } = useTranslation()

  return (
    <InputWrapper>
      <StyledInput
        {...rest}
        disabled
        value={value}
        onChange={(event) => {
          onUserInput(event.target.value)
        }}
        // universal input options
        inputMode="text"
        title={t('ID')}
        autoComplete="off"
        autoCorrect="off"
        // text-specific options
        type="text"
        pattern="^([0-9]*)$"
        placeholder={placeholder || '123'}
        minLength={1}
        maxLength={79}
        spellCheck="false"
      />
    </InputWrapper>
  )
})

export default Input
