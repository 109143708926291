import { ethers } from 'ethers'
import { getMulticallContract } from 'utils/contractHelpers'
import { ChainId } from '../config/constants/chainId'

export interface Call {
  address: string // Address of the contract
  name: string // Function name on the contract (example: balanceOf)
  params?: any[] // Function params
}

const multicall = async <T = any>(
  abi: any[],
  calls: Call[],
  chainId: ChainId,
  signer?: ethers.Signer | ethers.providers.Provider,
): Promise<T> => {
  if (chainId === ChainId.UNDEFINED) return null

  try {
    const multi = getMulticallContract(chainId, signer)
    const itf = new ethers.utils.Interface(abi)

    const calldata = calls
      .filter((call) => call.address)
      .map((call) => [call.address.toLowerCase(), itf.encodeFunctionData(call.name, call.params)])
    const { returnData } = await multi.aggregate(calldata)

    const res = returnData.map((call, i) => {
      try {
        return itf.decodeFunctionResult(calls[i].name, call)
      } catch (err) {
        console.error(err)
        return null
      }
    })

    return res
  } catch (error) {
    console.error(error)
    throw new Error(JSON.stringify(error))
  }
}

export default multicall
