import { DYNAMIC_TOKENS_URL } from 'config'
import { ChainId } from './chainId'
import { TokenConfig } from './tokens'
import { Address, PoolConfig, StakingPoolConfig } from './types'

// Extend pools from an endpoint
// Usually called once per module load
export async function asyncExtendedPools(
  type: 'reward' | 'liquidityMining',
  idField: 'rewardPoolId' | 'stakingPoolId',
  chainId: ChainId,
  contractAddress: Address,
  getPools: () => PoolConfig[] | StakingPoolConfig[],
  getStakingTokens: () => TokenConfig[],
  getEarningTokens: () => TokenConfig[],
  onAddToPool: (PoolConfig) => any,
) {
  const connectedChainId = Number(window.ethereum?.networkVersion)

  if (connectedChainId !== chainId) {
    return
  }

  const url = `${DYNAMIC_TOKENS_URL}/${type}.json?chainId=${chainId}`
  const res = await fetch(url)

  if (!res.ok) {
    console.error(`Fetch ${url} failed with ${res.status}: ${await res.text()}`)
    return
  }

  const json = await res.json()

  if (!Array.isArray(json.pools)) {
    console.error(`Dynamic ${type} pools returned empty response (no pools array)`)
    return
  }

  const currentPool = getPools()
  const stakingTokens = getStakingTokens()
  const earningTokens = getEarningTokens()
  const maxId = Math.max(...currentPool.map((item) => item[idField]))

  json.pools.forEach((item, index) => {
    const pool: PoolConfig = { ...json.pools[item] }

    const stakingToken = stakingTokens.find((t) => t.symbol === item.stakingSymbol)
    const earningToken = earningTokens.find((t) => t.symbol === item.earningSymbol)

    if (!stakingToken || !earningToken) {
      console.error(`No dynamic tokens found for ${type} pool: ${item.stakingSymbol}, ${item.earningSymbol}`)
      return
    }

    pool[idField] = maxId + index + 1
    pool.stakingToken = stakingToken
    pool.earningToken = earningToken
    pool.contractAddress = contractAddress

    console.info(`Add dynamic to ${type} pool: ${pool.earningToken.symbol} ${pool[idField]}`)

    onAddToPool(pool)
  })
}
