import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1321.15 1321.15">
        <g id="Camada_2" data-name="Camada 2">
          <g id="Camada_1-2" data-name="Camada 1">
            <path d="M911.08,731.28H495l6.75-6.72c9.1-9.08,18.52-18.46,27.7-27.8,15.1-15.37,15.38-37.89.64-52.4-14.55-14.3-36.85-13.83-51.88,1.11-30.52,30.32-62.66,62.46-95.55,95.52-15.81,15.9-15.79,37.28.06,53.21,30.21,30.38,61.32,61.48,95.1,95.1,8,8,17.76,12,27.35,12A36.53,36.53,0,0,0,541,864.44c-.27-9.59-4.54-19-12-26.6-8.87-9-17.94-18-26.71-26.76L495,803.85H909.91c1.91,0,3.9,0,6-.09a36.34,36.34,0,0,0,34-35.91,35.33,35.33,0,0,0-10.08-25.4C932.66,735.25,922.46,731.28,911.08,731.28Z" />
            <path d="M409.93,589.69H826l-6.63,6.6c-9.55,9.49-19.43,19.31-29,29.17-14,14.43-14.06,36.1-.21,50.41A36.38,36.38,0,0,0,816,687.2h.42a35.19,35.19,0,0,0,24.81-10.31c32.08-31.6,65.25-64.75,98.58-98.54,14.05-14.24,14-35.74-.1-50-39.43-39.91-69.89-70.26-98.76-98.4-7.08-6.9-17.57-9.1-21.75-9.72-14.21-2.13-28.85,6.15-35.59,20.11a35.61,35.61,0,0,0,6.5,40.69c9.63,9.91,19.57,19.81,29.17,29.38q3.39,3.36,6.75,6.73H411c-1.9,0-3.87,0-5.91.09a36.29,36.29,0,0,0-34,35.92,35.34,35.34,0,0,0,10.11,25.38C388.37,585.72,398.57,589.69,409.93,589.69Z" />
            <path d="M660.57,1321.15A660.74,660.74,0,0,1,403.44,51.93a660.4,660.4,0,0,1,917.71,608.64,42.52,42.52,0,1,1-85,0C1236.11,343.22,977.93,85,660.57,85S85,343.22,85,660.57s258.18,575.54,575.53,575.54c172.94,0,335.15-76.74,445-210.54a42.52,42.52,0,1,1,65.72,54C1045.22,1233.08,859.06,1321.15,660.57,1321.15Z" />
            <path d="M1211.67,979.18a42.54,42.54,0,0,1-37.54-62.43c10.22-19.29,36.26-91.88,36.26-121.29a42.52,42.52,0,1,1,85,0c0,22-6.13,52.38-18.22,90.12-8.86,27.63-20.08,56.15-27.93,71A42.52,42.52,0,0,1,1211.67,979.18Z" />
          </g>
        </g>
      </svg>
    </Svg>
  )
}

export default Icon
