import React from 'react'
import { useWeb3React } from '@web3-react/core'
import {
  Flex,
  LogoutIcon,
  useModal,
  UserMenu as UIKitUserMenu,
  UserMenuDivider,
  UserMenuItem,
} from '@pancakeswap/uikit'
import useAuth from 'hooks/useAuth'
import ConnectWalletButton from 'components/ConnectWalletButton'
import { FetchStatus, useGetBnbBalance } from 'hooks/useTokenBalance'
import { useTranslation } from 'contexts/Localization'
import NetworkSearchModal from 'components/SearchModal/NetworkSearchModal'
import { NetworkSelectPanelProps } from 'components/NetworkSelectPanel/index'
import styled from 'styled-components'
import { NETWORK_ICON } from '../../../config/constants/networkIcons'
import WalletModal, { WalletView, LOW_BNB_BALANCE } from './WalletModal'
import WalletUserMenuItem from './WalletUserMenuItem'

export const NetworkIconImg = styled.img`
  align-items: center;
  background-color: #faf9fa;
  border-color: #0050ff;
  border-radius: 50%;
  border-style: solid;
  border-width: 2px;
  display: flex;
  height: 40px;
  justify-content: center;
  left: 0;
  position: absolute;
  width: 40px;
  z-index: 102;
  margin-right: 1rem;
  box-shadow: inset 0px -2px 0px rgb(0 0 0 / 10%);
`

NetworkIconImg.defaultProps = {
  src: NetworkIconImg,
}

// Don't show ToggleButton on small screens
const ToggleButton = styled.div`
  position: absolute;
  height: 40px;
  width: 40px;
  margin-left: -48px;
  margin-top: -4px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`

// Don't show ToggleUserMenuItem on big screens
const ToggleUserMenuItem = styled(UserMenuItem)`
  @media only screen and (min-width: 768px) {
    display: none;
  }
`

export const UserMenu = ({ onNetworkSelect, availableNetworkIds = [] }: NetworkSelectPanelProps) => {
  const { t } = useTranslation()
  const { account, chainId } = useWeb3React()
  const { logout } = useAuth()
  const { balance, fetchStatus } = useGetBnbBalance()
  const [onPresentWalletModal] = useModal(<WalletModal initialView={WalletView.WALLET_INFO} />)
  const [onPresentTransactionModal] = useModal(<WalletModal initialView={WalletView.TRANSACTIONS} />)
  const hasLowNativeCurrencyBalance = fetchStatus === FetchStatus.SUCCESS && balance.lte(LOW_BNB_BALANCE)

  const [onPresentNetworkModal] = useModal(
    <NetworkSearchModal
      availableNetworkIds={availableNetworkIds}
      onNetworkSelect={onNetworkSelect}
      selectedNetwork={chainId}
    />,
  )
  if (!account) {
    return <ConnectWalletButton scale="sm" />
  }

  return (
    <div>
      <div>
        <ToggleButton
          onClick={() => {
            onPresentNetworkModal()
          }}
        >
          <NetworkIconImg src={NETWORK_ICON[chainId]} alt="Switch Network" />
        </ToggleButton>
        <UIKitUserMenu account={account}>
          <WalletUserMenuItem
            hasLowBnbBalance={hasLowNativeCurrencyBalance}
            onPresentWalletModal={onPresentWalletModal}
          />
          <UserMenuItem as="button" onClick={onPresentTransactionModal}>
            {t('Transactions')}
          </UserMenuItem>
          <ToggleUserMenuItem
            as="button"
            onClick={() => {
              onPresentNetworkModal()
            }}
          >
            {t('Switch Network')}
          </ToggleUserMenuItem>
          <UserMenuDivider />
          <UserMenuDivider />
          <UserMenuItem as="button" onClick={logout}>
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              {t('Disconnect')}
              <LogoutIcon />
            </Flex>
          </UserMenuItem>
        </UIKitUserMenu>
      </div>
    </div>
  )
}
