import getTokens from '../tokens'
import getLPTokens from '../tokensLP'
import { FarmConfig } from '../types'

export default function getFarms(): FarmConfig[] {
  const lpTokens = getLPTokens()
  const tokens = getTokens()

  return [
    /**
     * by order of release (some may be out of PID order due to multiplier boost)
     */
    {
      farmId: 1,
      lpSymbol: 'USDC-LP',
      lpToken: lpTokens.usdc,
      token: tokens.usdc,
    },
    {
      farmId: 0,
      lpSymbol: 'TXL-LP',
      lpToken: lpTokens.txl,
      token: tokens.txl,
    },
  ]
}
