import React, { useMemo } from 'react'
import styled from 'styled-components'
import { CurrencySelectButton, LabelRow } from 'components/CurrencyInputPanel'
import { RowBetween } from 'components/Layout/Row'
import { ChevronDownIcon, Flex, Text, useModal } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import { ChainId } from 'config/constants/chainId'
import NetworkSearchModal from 'components/SearchModal/NetworkSearchModal'
import { networks } from 'config/constants/networks'

export interface NetworkSelectPanelProps {
  id: string
  onNetworkSelect: (chainId: ChainId) => void
  label?: string
  chainId?: number
  disabled?: boolean
  disableNetworkSelect?: boolean
  availableNetworkIds?: ChainId[]
  message?: string
  style?: any
  noPaddingOuterContainer?: boolean
}

const SwitchSourceChainInfo = styled.div`
  color: #888;
  padding: 0 15px;
  margin-top: 8px;
`

const StyledText = styled(Text)`
  max-width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export default function NetworkSelectPanel({
  id,
  onNetworkSelect,
  label,
  chainId,
  disabled,
  disableNetworkSelect,
  availableNetworkIds = [],
  message,
  style,
  noPaddingOuterContainer,
}: NetworkSelectPanelProps) {
  const { t } = useTranslation()

  const network = useMemo(() => {
    if (availableNetworkIds && availableNetworkIds.length > 0) {
      return networks.find((netw) => netw.chainId === chainId && availableNetworkIds.indexOf(chainId) > -1)
    }
    return networks.find((netw) => netw.chainId === chainId)
  }, [availableNetworkIds, chainId])

  const translatedLabel = label || t('Input')

  const [onPresentNetworkModal] = useModal(
    <NetworkSearchModal
      availableNetworkIds={availableNetworkIds}
      onNetworkSelect={onNetworkSelect}
      selectedNetwork={chainId}
    />,
  )

  return (
    <div id={id} style={style}>
      <LabelRow style={noPaddingOuterContainer && { padding: '0' }}>
        <RowBetween>
          <Text fontSize="14px" fontWeight="bold">
            {translatedLabel}
          </Text>
          <CurrencySelectButton
            selected={!!chainId}
            disabled={disabled}
            className="open-chain-select-button"
            onClick={() => {
              if (!disableNetworkSelect) {
                onPresentNetworkModal()
              }
            }}
          >
            <Flex alignItems="center" justifyContent="space-between">
              <StyledText id="pair">{chainId && network ? network.name : t('Select a network')}</StyledText>
              {!disableNetworkSelect && <ChevronDownIcon />}
            </Flex>
          </CurrencySelectButton>
        </RowBetween>
      </LabelRow>
      {message && message.length > 0 && <SwitchSourceChainInfo>{message}</SwitchSourceChainInfo>}
    </div>
  )
}
