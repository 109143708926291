import React from 'react'
import { TokenAmount } from 'sdk/entities/tokenAmount'
import CircleLoader from 'components/Loader/CircleLoader'
import { getExplorerUri } from 'utils/getExplorerUri'
import getAddresses from 'utils/getAddresses'
import { Token } from 'sdk/entities/token'
import { networks } from 'config/constants/networks'

interface Props {
  currencyToken: Token
  parsedAmount: TokenAmount
  targetChainId: number
  bridgingLiquidity: string | TokenAmount | null
  isRemoveLiquidityInAnotherNetwork?: boolean
}

export default function BridgeLiquidityInfo({
  bridgingLiquidity,
  targetChainId,
  currencyToken,
  parsedAmount,
  isRemoveLiquidityInAnotherNetwork,
}: Props) {
  let view = null
  let enoughLiquidity = false

  if (typeof bridgingLiquidity === 'undefined') {
    view = (
      <div>
        <span style={{ marginRight: '10px' }}>
          <CircleLoader />
        </span>
        Fetching available liquidity from target network...
      </div>
    )
  } else if (typeof bridgingLiquidity === 'string') {
    const explorerUri = `${getExplorerUri(targetChainId, currencyToken.address)}?a=${
      getAddresses(targetChainId).BridgeV2ERC20
    }`
    view = (
      <div>
        {bridgingLiquidity}
        <a href={explorerUri} target="_blank" rel="noreferrer" style={{ fontWeight: 'bold' }}>
          Explorer
        </a>
      </div>
    )
  } else if (bridgingLiquidity) {
    enoughLiquidity = bridgingLiquidity.greaterThan(parsedAmount)
    if (!enoughLiquidity) {
      view = `⚠️ There is currently only ${bridgingLiquidity.toSignificant(6)} ${bridgingLiquidity.token.symbol.replace(
        '<SYMBOL>',
        currencyToken.symbol,
      )} available as liquidity for bridging.
      ${
        isRemoveLiquidityInAnotherNetwork
          ? ' Withdrawing on a different network requires one bridging. Thus fees are slightly higher.'
          : ''
      }`
    } else {
      const amountAsString = bridgingLiquidity.greaterThan(BigInt(100))
        ? bridgingLiquidity.toFixed(0)
        : bridgingLiquidity.greaterThan(BigInt(10))
        ? bridgingLiquidity.toFixed(2)
        : bridgingLiquidity.toSignificant(6)

      view = `There is currently ~${amountAsString} 
      ${bridgingLiquidity.token.symbol} available as liquidity on
      ${networks.find((netw) => netw.chainId === targetChainId)?.name}.
      ${
        isRemoveLiquidityInAnotherNetwork
          ? ' Withdrawing on a different network requires one bridging. Thus fees are slightly higher.'
          : ''
      }`
    }
  }

  return view
}
