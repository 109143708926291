import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1321.2 1321.2">
        <path
          d="M660.6,1321.2C295.7,1320.9,0,1024.9,0.2,660c0.2-265.2,159-504.7,403.2-608.1c335.9-142.1,723.4,15,865.5,350.9
	c34.5,81.5,52.2,169.2,52.2,257.7c0.7,23.5-17.7,43.1-41.2,43.8s-43.1-17.7-43.8-41.2c0-0.9,0-1.7,0-2.6
	C1236.1,343.2,977.9,85,660.6,85S85,343.2,85,660.6s258.2,575.5,575.5,575.5c172.9,0,335.2-76.7,445-210.5
	c14.9-18.1,41.7-20.8,59.9-5.9c18.1,14.9,20.8,41.7,5.9,59.9l0,0C1045.2,1233.1,859.1,1321.2,660.6,1321.2z"
        />
        <path
          d="M1211.7,979.2c-23.5,0-42.5-19.1-42.5-42.6c0-6.9,1.7-13.7,4.9-19.8c10.2-19.3,36.3-91.9,36.3-121.3
	c-0.7-23.5,17.7-43.1,41.2-43.8s43.1,17.7,43.8,41.2c0,0.9,0,1.7,0,2.6c0,22-6.1,52.4-18.2,90.1c-8.9,27.6-20.1,56.2-27.9,71
	C1241.9,970.5,1227.4,979.2,1211.7,979.2z"
        />
        <g>
          <path
            d="M637.5,1121c-10.4-1.3-20.8-2.2-31.1-4.1c-126-23.1-218.4-91.7-275-206.4c-45.5-92.2-49.2-187.2-10.6-282.9
		c12-29.8,28.2-57.4,55.8-75.5c10.4-6.8,23.3-11.2,35.6-13.4c19.7-3.6,33.5,11,31.7,31c-3,33-3.6,66,3,98.6
		c3.1,15.4,8.5,30.6,15.1,44.8c6.3,13.7,18.7,19.2,34.3,17.8c7-0.7,10-3.8,9.6-10.1c-1.1-15.5-1.3-31.3-4.2-46.5
		c-12.1-63.3-17.5-127.1-15.4-191.4c1.7-50.3,8.5-99.7,29.8-146.1c33.1-72.2,90-114.3,167-129.5c22.2-4.4,45.1-6,67.8-7.3
		c27.6-1.6,40,18.4,28.1,43.1c-16.9,35.3-23.7,72.2-16.7,111c8,44.3,31.7,79.2,66,107.1c16.2,13.2,34.2,24.2,51.2,36.6
		c65.4,47.6,112.6,108.9,136.7,186.6c13.3,42.7,12.7,86.3,5.2,130c-17.8,103.9-71.5,185.1-157.7,244.6
		c-53.1,36.6-112.6,56.7-177.2,60.8c-2.6,0.2-5.3,0.9-7.9,1.4C665.1,1121,651.3,1121,637.5,1121z M387.1,617.2
		c-3.2,5.2-5.4,8.4-7.1,11.8c-36.7,71.3-39.8,145.4-15.4,220.7c21.1,64.9,60.2,117.6,115.7,157.4c76.9,55.1,162.4,74.5,254.1,50.3
		c128.1-33.7,205.9-118,233.3-247.6c9.5-44.8,7.5-89.4-10.6-132.4C930.9,615.1,888,567.4,832.2,530c-20.8-13.9-41.2-29.2-59-46.8
		c-51.3-50.5-74-112.3-65.5-184.4c1.6-13.9,4.7-27.5,7.4-42.8c-9.3,1.7-18.1,2.8-26.8,4.8c-52.1,11.9-92.6,39.5-118,87.5
		c-17.3,32.6-25.3,67.9-28,104.3c-4.9,64.8-1.9,129.3,9.4,193.5c4.5,25.5,7.2,51.7,7.8,77.6c0.5,22-9.3,40.8-29.6,52.1
		c-33.9,18.8-81.5,8.3-105.6-22.5c-18.5-23.7-27.1-51.2-31.6-80.2C390.1,655.4,389.1,637.5,387.1,617.2z"
          />
        </g>
      </svg>
    </Svg>
  )
}

export default Icon
