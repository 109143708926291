import React from 'react'
import styled from 'styled-components'
import { Text, Link } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import Button from '../../../theme/components/Button/Button'

const ButtonText = styled(Text)`
  display: block;
`

const StyledButton = styled(Button)`
  background-color: white;
`

const StyledLink = styled(Link)`
  display: flex;
  justify-content: flex-end;

  &:hover {
    text-decoration: none;
  }
  ${({ theme }) => theme.mediaQueries.sm} {
    flex: 1;
  }
`

const WhitelistNftButton = () => {
  const { t } = useTranslation()
  return (
    <StyledLink href="https://form.asana.com/?k=e5i2442gyXrVOAvu_LtMIg&d=1187896536578697" target="_blank">
      <StyledButton px={['14px', null, null, null, '20px']} variant="primary">
        <ButtonText color="secondary" bold fontSize="16px">
          {t('Whitelist NFT Collection')}
        </ButtonText>
      </StyledButton>
    </StyledLink>
  )
}

export default WhitelistNftButton
