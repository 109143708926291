import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1321.15 1321.15">
        <g id="Camada_2" data-name="Camada 2">
          <g id="Camada_1-2" data-name="Camada 1">
            <path d="M1033,648.56c-.18-.55-.37-1.1-.56-1.65a20,20,0,0,1-.71-2.27A81.12,81.12,0,0,0,998.65,595c-17.2-12.08-37.52-16.92-57.22-13.64-29.44,4.92-53.08,24-63.25,51s-5,56.89,13.92,80a78.17,78.17,0,0,0,60.35,28.46H953a79.1,79.1,0,0,0,60.87-28c11.17-13.28,16.14-28.48,19.23-40.23l.23-.89v-22Z" />
            <path d="M660.71,580.65h-.11a80.07,80.07,0,0,0-.19,160.14h.11a80.07,80.07,0,0,0,.19-160.14Z" />
            <path d="M367.92,580.65h-.08A80.07,80.07,0,0,0,368,740.79h.14a80.07,80.07,0,0,0-.23-160.14Z" />
            <path d="M660.57,1321.15A660.74,660.74,0,0,1,403.44,51.93a660.4,660.4,0,0,1,917.71,608.64,42.52,42.52,0,1,1-85,0C1236.11,343.22,977.93,85,660.57,85S85,343.22,85,660.57s258.18,575.54,575.53,575.54c172.94,0,335.15-76.74,445-210.54a42.52,42.52,0,1,1,65.72,54C1045.22,1233.08,859.06,1321.15,660.57,1321.15Z" />
            <path d="M1211.67,979.18a42.54,42.54,0,0,1-37.54-62.43c10.22-19.29,36.26-91.88,36.26-121.29a42.52,42.52,0,1,1,85,0c0,22-6.13,52.38-18.22,90.12-8.86,27.63-20.08,56.15-27.93,71A42.52,42.52,0,0,1,1211.67,979.18Z" />
          </g>
        </g>
      </svg>
    </Svg>
  )
}

export default Icon
