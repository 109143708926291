import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, AppState } from '../index'
import { userWalletState } from './reducer'
import { reset, updateSelectedChainNoUserWalletPresent, updateIsUserWalletConnected } from './actions'

// used to store information about
export function useNoUserWalletPresentState(): userWalletState {
  return useSelector<AppState, AppState['userNoWalletPresent']>((state) => {
    return state.userNoWalletPresent
  })
}

export function useNoUserWalletPresentActionHandlers() {
  const dispatch = useDispatch<AppDispatch>()

  const onReset = useCallback(() => {
    dispatch(reset())
  }, [dispatch])

  const onChainSelect = useCallback(
    (selectedChainNoUserWalletPresent) => {
      dispatch(updateSelectedChainNoUserWalletPresent({ selectedChainNoUserWalletPresent }))
    },
    [dispatch],
  )

  const onUpdateIsUserWalletConnected = useCallback(
    (isUserWalletConnected) => {
      dispatch(updateIsUserWalletConnected({ isUserWalletConnected }))
    },
    [dispatch],
  )

  return {
    onReset,
    onChainSelect,
    onUpdateIsUserWalletConnected,
  }
}
