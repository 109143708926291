import BigNumber from 'bignumber.js'
import { ChainId } from './chainId'

export interface Address {
  [ChainId.ETHEREUM]?: string
  [ChainId.RINKEBY]?: string
  [ChainId.BSC]?: string
  [ChainId.BSCTEST]?: string
  [ChainId.POLYGON]?: string
  [ChainId.AVALANCHE]?: string
  [ChainId.AVALANCHETEST]?: string
  [ChainId.FANTOM]?: string
  [ChainId.LETH]?: string
  [ChainId.LBSC]?: string
}

export interface Decimals {
  [ChainId.ETHEREUM]?: number
  [ChainId.RINKEBY]?: number
  [ChainId.BSC]?: number
  [ChainId.BSCTEST]?: number
  [ChainId.POLYGON]?: number
  [ChainId.AVALANCHE]?: number
  [ChainId.AVALANCHETEST]?: number
  [ChainId.FANTOM]?: number
  [ChainId.LETH]?: number
  [ChainId.LBSC]?: number
}

export interface Token {
  symbol: string
  address?: Address
  decimals?: Decimals
  projectLink?: string
  busdPrice?: string
}

export interface FarmConfig {
  farmId: number
  lpSymbol: string
  lpToken: Token
  token: Token
  multiplier?: string
  isCommunity?: boolean
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
  quoteToken?: Token
}

export interface PoolConfig {
  rewardPoolId: number
  earningToken: Token
  stakingToken: Token
  contractAddress: Address
  tokenPerBlock: string
  sortOrder?: number
  harvest?: boolean
  isFinished?: boolean
}

export interface StakingPoolConfig {
  stakingPoolId: number
  earningToken: Token
  stakingToken: Token
  contractAddress: Address
  tokenPerBlock: string
  sortOrder?: number
  harvest?: boolean
  isFinished?: boolean
}

export type Images = {
  lg: string
  md: string
  sm: string
  ipfs?: string
}

export type PageMeta = {
  title: string
  description?: string
  image?: string
}

// Farm Auction
export interface FarmAuctionBidderConfig {
  account: string
  farmName: string
  tokenAddress: string
  quoteToken: Token
  tokenName: string
  projectSite?: string
  lpAddress?: string
}

// Note: this status is slightly different compared to 'status' comfing
// from Farm Auction smart contract
export enum AuctionStatus {
  ToBeAnnounced, // No specific dates/blocks to display
  Pending, // Auction is scheduled but not live yet (i.e. waiting for startBlock)
  Open, // Auction is open for bids
  Finished, // Auction end block is reached, bidding is not possible
  Closed, // Auction was closed in smart contract
}

export interface Auction {
  id: number
  status: AuctionStatus
  startBlock: number
  startDate: Date
  endBlock: number
  endDate: Date
  auctionDuration: number
  farmStartBlock: number
  farmStartDate: Date
  farmEndBlock: number
  farmEndDate: Date
  initialBidAmount: number
  topLeaderboard: number
  leaderboardThreshold: BigNumber
}

export interface BidderAuction {
  id: number
  amount: BigNumber
  claimed: boolean
}

export interface Bidder extends FarmAuctionBidderConfig {
  position?: number
  isTopPosition: boolean
  samePositionAsAbove: boolean
  amount: BigNumber
}

export interface ConnectedBidder {
  account: string
  isWhitelisted: boolean
  bidderData?: Bidder
}

export interface NftAssetConfig {
  nftTokenId: string
  name?: string
  imagePreviewUrl?: string
  assetContract?: {
    address?: string
    createdDate?: string
    collectionName?: string
  }
}
