import { PoolConfig } from '../types'
import { ChainId } from '../chainId'
import getTokens, { getTokensList } from '../tokens'
import { asyncExtendedPools } from '../asyncExtendedPools'

const contractAddress = {
  [ChainId.ETHEREUM]: '0x0BDC1f983bC82B8F6F6BCcbF9810A9cdC1FE455f',
  [ChainId.BSC]: '0x0BDC1f983bC82B8F6F6BCcbF9810A9cdC1FE455f',
  [ChainId.POLYGON]: '0x0BDC1f983bC82B8F6F6BCcbF9810A9cdC1FE455f',
  [ChainId.AVALANCHE]: '0xbAb537b7AE2Fcb00eeA7e91Fa4782EEbaD3B6d10',
  [ChainId.FANTOM]: '0x6eBC0D4Ae955218195E6D016Fb9D4358Ee34d1F9',
  [ChainId.AUTOBAHN]: '0x6eBC0D4Ae955218195E6D016Fb9D4358Ee34d1F9',
}

let pools

function initialPools() {
  const tokens = getTokens()

  pools = [
    {
      rewardPoolId: 2,
      stakingToken: tokens.bridge,
      earningToken: tokens.usdc,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 13,
      stakingToken: tokens.bridge,
      earningToken: tokens.usdt,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 12,
      stakingToken: tokens.bridge,
      earningToken: tokens.weth,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 1,
      stakingToken: tokens.bridge,
      earningToken: tokens.txl,
      contractAddress,
      harvest: true,
      tokenPerBlock: '1',
      sortOrder: 999,
      isFinished: false,
    },
    {
      rewardPoolId: 0,
      stakingToken: tokens.bridge,
      earningToken: tokens.bridge,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 4,
      stakingToken: tokens.bridge,
      earningToken: tokens.pmon,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 20,
      stakingToken: tokens.bridge,
      earningToken: tokens.pmlg,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 3,
      stakingToken: tokens.bridge,
      earningToken: tokens.gfx,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 5,
      stakingToken: tokens.bridge,
      earningToken: tokens.bulk,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 6,
      stakingToken: tokens.bridge,
      earningToken: tokens.mny,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 7,
      stakingToken: tokens.bridge,
      earningToken: tokens.ulti,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 8,
      stakingToken: tokens.bridge,
      earningToken: tokens.island,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 9,
      stakingToken: tokens.bridge,
      earningToken: tokens.dax,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 10,
      stakingToken: tokens.bridge,
      earningToken: tokens.realm,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 11,
      stakingToken: tokens.bridge,
      earningToken: tokens.upr,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 14,
      stakingToken: tokens.bridge,
      earningToken: tokens.uno,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 15,
      stakingToken: tokens.bridge,
      earningToken: tokens.uct,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 16,
      stakingToken: tokens.bridge,
      earningToken: tokens.daf,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 17,
      stakingToken: tokens.bridge,
      earningToken: tokens.cndl,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 18,
      stakingToken: tokens.bridge,
      earningToken: tokens.haka,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 19,
      stakingToken: tokens.bridge,
      earningToken: tokens.shr,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 21,
      stakingToken: tokens.bridge,
      earningToken: tokens.xgc,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 22,
      stakingToken: tokens.bridge,
      earningToken: tokens.yop,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 23,
      stakingToken: tokens.bridge,
      earningToken: tokens.multiverse,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
    {
      rewardPoolId: 24,
      stakingToken: tokens.bridge,
      earningToken: tokens.wnd,
      contractAddress,
      harvest: true,
      sortOrder: 999,
      tokenPerBlock: '1',
      isFinished: false,
    },
  ]
}

export default function getPools(): PoolConfig[] {
  if (!pools) {
    initialPools()
  }

  return pools
}

asyncExtendedPools(
  'reward',
  'rewardPoolId',
  ChainId.BSC,
  contractAddress,
  getPools,
  getTokensList,
  getTokensList,
  (pool) => pools.push(pool),
).catch((err) => console.error('Dynamic pools', err))
