import React, { useState } from 'react'
import { Button, Link, Modal, Overlay, Text } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import { AutoRow } from 'components/Layout/Row'
import { useAcceptedTerms } from 'state/user/hooks'
import { useDispatch } from 'react-redux'
import { acceptTerms } from 'state/user/actions'
import { useLocation } from 'react-router'

const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${({ theme }) => theme.zIndices.modal - 1};
`

const TermsConditionModal: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const location = useLocation()
  const acceptedTerms = useAcceptedTerms()
  const [open, setOpen] = useState(!acceptedTerms)

  const handleDismiss = () => {
    console.debug('can not dismiss terms modal')
  }

  const handleAccept = () => {
    setOpen(false)
    dispatch(acceptTerms())
  }

  if (location.pathname === '/') return null
  if (!open) return null

  return (
    <ModalWrapper>
      <Overlay show onClick={handleDismiss} />
      <Modal
        title={t('General Terms and Conditions')}
        headerBackground="gradients.cardHeader"
        maxWidth="320px"
        onDismiss={handleDismiss}
        hideCloseButton
      >
        <AutoRow maxWidth="300px">
          <Text textAlign="center">
            Before you start please make sure that you read and understand the General Terms and Conditions. You can use
            the following link to open or download these General Terms and Conditions.
            <Link
              mt="20px"
              external
              href="https://drive.google.com/file/d/1k76mPutQmkW0erIFRObHY-B3zBS7PsU9/view?usp=sharing"
            >
              Download General Terms and Conditions
            </Link>
          </Text>
          <Button mt="20px" onClick={handleAccept}>
            Accept General Terms and Conditions
          </Button>
        </AutoRow>
      </Modal>
    </ModalWrapper>
  )
}

export default TermsConditionModal
