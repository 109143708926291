import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'state'

export default function useSavedClaims(filterType: string) {
  const claims = useSelector<AppState, AppState['claims']>((state) => state.claims)

  const data = useMemo(() => {
    const hashs = Object.keys(claims || {})

    return hashs
      .map((hash) => {
        return claims[hash]
      })
      .filter((item) => item.type === filterType)
      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
  }, [claims, filterType])

  return [data]
}
