import React, { useState } from 'react'
import { ArrowForwardIcon } from '@pancakeswap/uikit'
import styled from 'styled-components'
import { networks } from 'config/constants/networks'
import Text from 'theme/components/Text/Text'
import { LightCard, LightGreyCard } from 'components/Card'
import { Row, RowBetween } from 'components/Layout/Row'
import useSavedClaims from 'state/claims/useSavedClaims'
import { LinkExternal } from 'theme/components/Link'
import { getCollection } from 'config/constants/nftCollections'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { NETWORK_ICON } from '../../../config/constants/networkIcons'
import { ClaimListRemoveButton, NetworkIconImg } from '../../BridgeERC20/components/ClaimList'

const InlineText = styled(Text)`
  display: inline;
`

const LabelButton = styled.button`
  border: 0;
  background-color: transparent;
  color: #0051ff;
  cursor: pointer;
  display: inline-block;
`

export default function ClaimList() {
  const queryString = useParsedQueryString()
  const [claims] = useSavedClaims('erc721')
  const [editMode, setEditMode] = useState(false)

  if (!claims || !claims.length) return null

  return (
    <Row width="100%" maxWidth="436px" marginTop="2em">
      <LightCard>
        <Text fontSize="14px" mb="3" bold>
          Saved claims
          <LabelButton onClick={() => setEditMode(!editMode)}>Edit</LabelButton>
        </Text>
        <div>
          {claims.map((claim) => {
            if (!claim.depositHash) return null

            const isActive = queryString.release && (queryString.release as string).includes(claim.depositHash)
            const releaseLink = `/bridge/nfts?release=${claim.sourceChainId},${claim.targetChainId},${claim.depositHash}`
            const networkSource = networks.find((net) => net.chainId === claim.sourceChainId)
            const networkTarget = networks.find((net) => net.chainId === claim.targetChainId)
            const collection = getCollection(claim.targetChainId, claim.currencyId)

            return (
              <LightGreyCard key={claim.depositHash} mb="3" highlight={isActive}>
                <RowBetween>
                  <InlineText>
                    <NetworkIconImg src={NETWORK_ICON[claim.sourceChainId]} alt={networkSource?.name} />
                    <InlineText mx="1" verticalAlign="super">
                      <ArrowForwardIcon width="14px" color="primary" />
                    </InlineText>
                    <NetworkIconImg src={NETWORK_ICON[claim.targetChainId]} alt={networkTarget?.name} />
                    <InlineText>{collection?.name}</InlineText>
                  </InlineText>
                  <LinkExternal href={releaseLink} color="primary" fontSize="14px">
                    {claim.typedAmount}
                  </LinkExternal>
                </RowBetween>

                <ClaimListRemoveButton depositHash={claim.depositHash} editMode={editMode} />
              </LightGreyCard>
            )
          })}
        </div>
      </LightCard>
    </Row>
  )
}
