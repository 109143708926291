import React, { useCallback } from 'react'
import { Currency, CurrencyAmount } from '@pancakeswap/sdk'
import { InjectedModalProps } from '@pancakeswap/uikit'
import TransactionConfirmationModal, {
  TransactionErrorContent,
  ConfirmationModalContent,
} from 'components/TransactionConfirmationModal'
import { TokenAmount } from 'sdk/entities/tokenAmount'

import BridgeModalHeader from './BridgeModalHeader'
import BridgeModalFooter from './BridgeModalFooter'

interface ConfirmBridgeModalProps {
  amount: CurrencyAmount | undefined
  currency: Currency | undefined
  fees?: TokenAmount | undefined
  attemptingTxn: boolean
  txHash: string | undefined
  recipient: string | null
  onConfirm: () => void
  swapErrorMessage: string | undefined
  customOnDismiss?: () => void
  targetChainId?: number | undefined
  isWithdrawalToOtherNetwork?: boolean
}

export default function ConfirmBridgeModal({
  amount,
  currency,
  fees,
  onConfirm,
  onDismiss,
  customOnDismiss,
  recipient,
  swapErrorMessage,
  attemptingTxn,
  txHash,
  targetChainId,
  isWithdrawalToOtherNetwork,
}: ConfirmBridgeModalProps & InjectedModalProps) {
  const modalHeader = useCallback(() => {
    return currency ? (
      <BridgeModalHeader
        fees={fees}
        amount={amount}
        currency={currency}
        recipient={recipient}
        isWithdrawalToOtherNetwork={isWithdrawalToOtherNetwork}
      />
    ) : null
  }, [amount, currency, recipient, fees, isWithdrawalToOtherNetwork])

  const modalBottom = useCallback(() => {
    return currency ? (
      <BridgeModalFooter
        onConfirm={onConfirm}
        currency={currency}
        fees={fees}
        swapErrorMessage={swapErrorMessage}
        targetChainId={targetChainId}
        isWithdrawalToOtherNetwork={isWithdrawalToOtherNetwork}
      />
    ) : null
  }, [onConfirm, currency, swapErrorMessage, fees, targetChainId, isWithdrawalToOtherNetwork])

  const closeModal = useCallback(() => {
    onDismiss()
    if (customOnDismiss) customOnDismiss()
  }, [onDismiss, customOnDismiss])

  // text to show while loading
  const pendingText = isWithdrawalToOtherNetwork
    ? 'Withdrawal pending...'
    : `Bridging ${amount?.toSignificant(6)} ${currency?.symbol}`

  const confirmationContent = useCallback(
    () =>
      swapErrorMessage ? (
        <TransactionErrorContent onDismiss={closeModal} message={swapErrorMessage} />
      ) : (
        <ConfirmationModalContent topContent={modalHeader} bottomContent={modalBottom} />
      ),
    [closeModal, modalBottom, modalHeader, swapErrorMessage],
  )

  return (
    <TransactionConfirmationModal
      onDismiss={closeModal}
      attemptingTxn={attemptingTxn}
      hash={txHash}
      content={confirmationContent}
      pendingText={pendingText}
      title={isWithdrawalToOtherNetwork ? 'Confirm Withdrawal' : 'Confirm Bridging'}
    />
  )
}
