import { createReducer } from '@reduxjs/toolkit'
import {
  addPosition,
  clear,
  reset,
  selectCurrency,
  setCurrencyBeingRemoved,
  typeAmount,
  typeRecipient,
  selectSourceChain,
  selectTargetChain,
  setDeposit,
} from './actions'

export interface LiquidityState {
  readonly typedAmount: string
  readonly currencyId: string
  readonly lpTokens: Record<
    number,
    Array<{
      token: string
      lpToken: string
    }>
  >
  readonly targetChainId: number | null
  readonly sourceChainId: number | null
  readonly depositHash: string
  readonly typedRecipient: string
  readonly currencyIdBeingRemoved: string
}

const initialState = {
  typedAmount: '',
  currencyId: '',
  lpTokens: {},
  targetChainId: null,
  sourceChainId: null,
  depositHash: '',
  typedRecipient: '',
  currencyIdBeingRemoved: '',
}

export default createReducer<LiquidityState>(initialState, (builder) =>
  builder
    .addCase(clear, () => initialState)
    .addCase(reset, (state) => {
      return {
        typedAmount: '',
        currencyId: '',
        lpTokens: state.lpTokens,
        targetChainId: null,
        sourceChainId: null,
        depositHash: '',
        typedRecipient: '',
        currencyIdBeingRemoved: '',
      }
    })
    .addCase(typeAmount, (state, { payload: { typedAmount } }) => {
      state.typedAmount = typedAmount
    })
    .addCase(selectCurrency, (state, { payload: { currencyId } }) => {
      state.currencyId = currencyId
    })
    .addCase(addPosition, (state, { payload }) => {
      if (!state.lpTokens[payload.chainId]) {
        state.lpTokens[payload.chainId] = []
      }

      const exist = state.lpTokens[payload.chainId].find((item) => item.lpToken === payload.lpToken)

      if (!exist) {
        state.lpTokens[payload.chainId].push({
          token: payload.token,
          lpToken: payload.lpToken,
        })
      }
    })
    .addCase(selectSourceChain, (state, { payload: { sourceChainId } }) => {
      return {
        ...state,
        sourceChainId,
      }
    })
    .addCase(selectTargetChain, (state, { payload: { targetChainId } }) => {
      return {
        ...state,
        targetChainId,
      }
    })
    .addCase(setDeposit, (state, { payload: { depositHash, sourceChainId } }) => {
      return {
        ...state,
        sourceChainId,
        depositHash,
      }
    })
    .addCase(typeRecipient, (state, { payload: { typedRecipient } }) => {
      return {
        ...state,
        typedRecipient,
      }
    })
    .addCase(setCurrencyBeingRemoved, (state, { payload: { currencyIdBeingRemoved } }) => {
      return {
        ...state,
        currencyIdBeingRemoved,
      }
    }),
)
