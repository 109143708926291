import { useCallback, useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useDispatch, useSelector } from 'react-redux'

import { AppDispatch, AppState } from 'state'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useTransactionAdder } from 'state/transactions/hooks'
import { calculateGasMargin, getBridgeV2ERC721Contract, getERC721Contract } from 'utils'
import { ChainId } from 'config/constants/chainId'
import { getCollection } from 'config/constants/nftCollections'
import { getWaitConfirmations, useTransactionInfo } from 'state/bridgeERC20/hooks'
import { TokenAmount } from 'sdk/entities/tokenAmount'
import useParsedQueryString from 'hooks/useParsedQueryString'
import {
  reset,
  selectCollection,
  selectSourceChain,
  selectTargetChain,
  setApproval,
  setDeposit,
  typeRecipient,
  typeToken,
} from './actions'
import { useBridgingFees } from './useBridgingFees'

export function useBridgeState(): AppState['bridgeERC721'] {
  return useSelector<AppState, AppState['bridgeERC721']>((state) => {
    return state.bridgeERC721
  })
}

export function useBridgeActionHandlers() {
  const dispatch = useDispatch<AppDispatch>()

  const onReset = useCallback(() => {
    dispatch(reset())
  }, [dispatch])

  const onTokenInput = useCallback(
    (typedToken: string) => {
      dispatch(typeToken({ typedToken }))
    },
    [dispatch],
  )

  const onRecipientInput = useCallback(
    (typedRecipient: string) => {
      dispatch(typeRecipient({ typedRecipient }))
    },
    [dispatch],
  )

  const onSelectCollection = useCallback(
    (collectionId: string) => {
      dispatch(selectCollection({ collectionId }))
    },
    [dispatch],
  )

  const onSelectTargetChain = useCallback(
    (targetChainId: number) => {
      dispatch(selectTargetChain({ targetChainId }))
    },
    [dispatch],
  )

  const onSelectSourceChain = useCallback(
    (sourceChainId: number) => {
      dispatch(selectSourceChain({ sourceChainId }))
    },
    [dispatch],
  )

  const onSetDepositHash = useCallback(
    (depositHash: string, sourceChainId: number) => {
      dispatch(setDeposit({ depositHash, sourceChainId }))
    },
    [dispatch],
  )

  const onSetApprovalhash = useCallback(
    (approvalHash: string) => {
      dispatch(setApproval({ approvalHash }))
    },
    [dispatch],
  )

  return {
    onReset,
    onTokenInput,
    onRecipientInput,
    onSelectCollection,
    onSelectTargetChain,
    onSelectSourceChain,
    onSetDepositHash,
    onSetApprovalhash,
  }
}

export const WAIT_APPROVE_CONFIRMATIONS = process.env.REACT_APP_ENV === 'local' ? -1 : 1

export function useApproveInfo() {
  const { chainId } = useWeb3React()
  const { approvalHash } = useBridgeState()
  const approvalTx = useTransactionInfo(chainId, approvalHash)

  if (approvalTx.currentBlock === 0) {
    return {
      approved: undefined,
      approvalPending: undefined,
    }
  }

  const approved = Boolean(approvalHash) && (approvalTx?.confirmations || 0) > WAIT_APPROVE_CONFIRMATIONS
  const approvalPending = Boolean(approvalHash) && !approved

  return {
    approved,
    approvalPending,
  }
}

export function useDerivedBridgeInfo() {
  const queryString = useParsedQueryString()
  const { chainId } = useWeb3React()
  const { depositHash, collectionId, sourceChainId: stateSource, targetChainId: stateTarget } = useBridgeState()
  const depositTx = useTransactionInfo(chainId, depositHash)

  // if release params are present in the URL, take these to try a release
  if (queryString.release) {
    const parts = (queryString.release as string).split(',')

    if (parts.length === 3) {
      return {
        depositHash: parts[2],
        sourceChainId: Number.parseInt(parts[0]),
        targetChainId: Number.parseInt(parts[1]),
        networkSwitchNeeded: chainId !== Number.parseInt(parts[1]),
        deposited: true,
      }
    }
  }

  const sourceChainId = stateSource ?? chainId
  // prevents the form from having targetChainId the same as sourceChainId
  const targetChainId = stateTarget === sourceChainId ? null : stateTarget ?? null
  const networkSwitchNeeded = depositHash && chainId !== targetChainId
  const deposited = Boolean(depositHash)

  const collection = getCollection(sourceChainId, collectionId)
  const WAIT_CONFIRMATIONS = getWaitConfirmations(chainId)

  // if deposited, compare to current block until enough confirmations
  const confirmations = depositTx.confirmations || 0
  const waitingDepositConfirmations =
    Boolean(depositHash) && confirmations < WAIT_CONFIRMATIONS && chainId === sourceChainId

  return {
    depositHash,
    collection,
    sourceChainId,
    targetChainId,
    networkSwitchNeeded,
    deposited,
    waitingDepositConfirmations,
    confirmations,
  }
}
export function useBridgeV2ERC721ApproveCallback(
  collectionAddress: string | undefined,
  tokenId: string | undefined,
): { callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()

  const bridge = getBridgeV2ERC721Contract(library, chainId, account || undefined)

  return useMemo(() => {
    if (!account || !chainId) {
      return {
        callback: null,
        error: 'No wallet connected.',
      }
    }

    if (!bridge) {
      return {
        callback: null,
        error: 'BridgeERC721 contract not found.',
      }
    }

    if (!collectionAddress) {
      return {
        callback: null,
        error: 'No collection set.',
      }
    }

    const collection = getERC721Contract(library, collectionAddress, account || undefined)

    if (!collection) {
      return {
        callback: null,
        error: 'Collection contract not found.',
      }
    }

    return {
      error: null,
      callback: async function onBridgeV2ERC721Approve(): Promise<string> {
        const args = [bridge.address, tokenId]

        try {
          const gasEstimate = await collection.estimateGas.approve(...args, {})
          const gasLimit = calculateGasMargin(gasEstimate)

          return collection.approve(bridge.address, tokenId, { gasLimit, from: account }).then((response: any) => {
            addTransaction(response)
            return response.hash
          })
        } catch (gasError) {
          return collection.callStatic
            .approve(...args, {})
            .catch((callError) => {
              console.debug(callError)
              throw new Error(callError.data.message)
            })
            .then((result) => {
              console.debug('Unexpected successful call after failed estimate gas', gasError, result)
              throw new Error('Unexpected issue with estimating the gas. Please try again.')
            })
        }
      },
    }
  }, [library, bridge, account, chainId, tokenId, collectionAddress, addTransaction])
}

export function useBridgeV2ERC721DepositCallback(
  collectionAddress: string | undefined,
  tokenId: string | undefined,
  targetAccountAddress: string,
  targetChainId: ChainId | undefined,
): { bridgingFees: TokenAmount | undefined; callback: null | (() => Promise<string>); error: string | null } {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const contract = getBridgeV2ERC721Contract(library, chainId, account || undefined)
  const bridgingFees = useBridgingFees(contract, targetChainId, collectionAddress)

  return useMemo(() => {
    if (!account || !chainId) {
      return {
        bridgingFees: undefined,
        callback: null,
        error: 'No wallet connected.',
      }
    }

    if (!contract) {
      return {
        bridgingFees: undefined,
        callback: null,
        error: 'BridgeERC721 contract not found',
      }
    }

    return {
      bridgingFees,
      error: null,
      callback: async function onBridgeV2ERC721Deposit(): Promise<string> {
        const args = [collectionAddress, tokenId, targetAccountAddress, targetChainId]

        try {
          const gasEstimate = await contract.estimateGas.deposit(...args, {})
          const gasLimit = calculateGasMargin(gasEstimate)
          return contract
            .deposit(collectionAddress, tokenId, targetAccountAddress, targetChainId, { gasLimit, from: account })
            .then((response: any) => {
              addTransaction(response)
              return response.hash
            })
        } catch (gasError) {
          return contract.callStatic
            .deposit(...args, {})
            .catch((callError) => {
              console.debug(callError)
              throw new Error(callError.data.message)
            })
            .then((result) => {
              console.debug('Unexpected successful call after failed estimate gas', gasError, result)
              throw new Error('Unexpected issue with estimating the gas. Please try again.')
            })
        }
      },
    }
  }, [
    account,
    chainId,
    contract,
    collectionAddress,
    tokenId,
    targetAccountAddress,
    targetChainId,
    addTransaction,
    bridgingFees,
  ])
}

export function useBridgeV2ERC721ReleaseCallback(): {
  callback: null | ((releaseparams: any) => Promise<string>)
  error: string | null
} {
  const { account, chainId, library } = useActiveWeb3React()
  const addTransaction = useTransactionAdder()
  const contract = getBridgeV2ERC721Contract(library, chainId, account || undefined)

  return useMemo(() => {
    if (!account || !chainId) {
      return {
        callback: null,
        error: 'No wallet connected.',
      }
    }

    if (!contract) {
      return {
        callback: null,
        error: 'BridgeERC721 contract not found',
      }
    }

    return {
      error: null,
      callback: async function onBridgeV2ERC721Release(releaseParams): Promise<string> {
        console.debug('release hook args', { releaseParams })

        try {
          return contract
            .release(...releaseParams, { from: account, value: 100000 })
            .then((response: any) => {
              addTransaction(response)
              return response.hash
            })
            .catch((err: any) => {
              console.debug('Unexpected error during release', err)
              const errorMessage = err.message || err.data?.message

              if (errorMessage.indexOf('Deposit was already processed and released') > -1) {
                throw new Error('Deposit was already processed and released.')
              }

              throw new Error('Unexpected error during release. Please try again in 15 mins.')
            })
        } catch (gasError) {
          return contract.callStatic
            .release(...releaseParams, {})
            .catch((callError) => {
              console.debug(callError)
              throw new Error(callError.error.message)
            })
            .then((result) => {
              console.debug('Unexpected successful call after failed estimate gas', gasError, result)
              throw new Error('Unexpected issue with estimating the gas. Please try again.')
            })
        }
      },
    }
  }, [account, chainId, contract, addTransaction])
}
