import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { useTranslation } from 'contexts/Localization'
import { useDispatch } from 'react-redux'
import { ArrowDownIcon, Box, Button, Flex, Text, useModal, LinkExternal, InfoIcon } from '@pancakeswap/uikit'
import Page from 'views/Page'
import { AppBody } from 'components/App'
import { ArrowWrapper, Wrapper } from 'components/styleds'
import Column, { AutoColumn } from 'components/Layout/Column'
import ConnectWalletButton from 'components/ConnectWalletButton'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { Row, AutoRow, RowBetween } from 'components/Layout/Row'
import CollectionInputPanel from 'components/CollectionInputPanel'
import getCollections, { Collection } from 'config/constants/nftCollections'
import {
  useBridgeActionHandlers,
  useBridgeState,
  useDerivedBridgeInfo,
  useBridgeV2ERC721ApproveCallback,
  useBridgeV2ERC721DepositCallback,
  useBridgeV2ERC721ReleaseCallback,
  useApproveInfo,
} from 'state/bridgeERC721/hooks'
import { getReleaseParamsERC721, rewordError } from 'utils/getReleaseParams'
import { useSetupNetwork } from 'utils/wallet'
import { removeClaimLink } from 'state/claims/actions'
import { tokenAddressURLParameter } from 'utils/queryStringParser'
import useParsedQueryString from 'hooks/useParsedQueryString'
import { GreyCard, LightCard } from 'components/Card'
import CircleLoader from 'components/Loader/CircleLoader'
import { useSaveClaimModal } from 'views/BridgeERC20/components/SaveClaimModal'
import { getWaitConfirmations, useReleaseLink } from 'state/bridgeERC20/hooks'
import ClaimList from './components/ClaimList'
import { ReleaseBridgeModal } from './components/ReleaseBridgeModal'
import PageHeader from '../../components/PageHeader'
import Transactions from '../../components/App/Transactions'
import NetworkSelectPanel from '../../components/NetworkSelectPanel'
import AddressInputPanel, { WarningLabelRow } from '../../components/AddressInputPanel'
import ProgressSteps from '../../components/ProgressSteps'
import { networks } from '../../config/constants/networks'
import Heading from '../../theme/components/Heading/Heading'
import NftCollectionAssetsSlides from './components/NftCollectionAssetsSlides'
import { useGetNftAssetsData } from '../../hooks/useGetNftAssetsData'
import WhitelistNftButton from './components/WhitelistNftButton'

export default function BridgeERC721({
  match: {
    params: { collectionId, toChainId },
  },
}: RouteComponentProps<{ collectionId?: string; toChainId?: string }>) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { account, chainId } = useActiveWeb3React()
  const queryString = useParsedQueryString()
  const history = useHistory()
  const { setupNetwork } = useSetupNetwork()

  const [showSwitchSourceChainInfo, setShowSwitchSourceChainInfo] = useState(false)
  const [{ swapErrorMessage, attemptingTxn, releaseHash }, setBridgeState] = useState({
    attemptingTxn: false,
    swapErrorMessage: undefined,
    releaseHash: undefined,
  })
  const {
    onReset,
    onTokenInput,
    onRecipientInput,
    onSelectCollection,
    onSelectTargetChain,
    onSetDepositHash,
    onSetApprovalhash,
  } = useBridgeActionHandlers()
  const { typedToken, typedRecipient } = useBridgeState()
  const [selectedNFT, setSelectedNFT] = useState(undefined)
  const {
    depositHash,
    collection,
    sourceChainId,
    targetChainId,
    networkSwitchNeeded,
    deposited,
    waitingDepositConfirmations,
    confirmations,
  } = useDerivedBridgeInfo()
  const { approved, approvalPending } = useApproveInfo()
  const { data } = useGetNftAssetsData(collection?.address)
  useEffect(() => {
    if (!typedRecipient && queryString.recipient) onRecipientInput(tokenAddressURLParameter(queryString.recipient))
    else if (!typedRecipient && account) onRecipientInput(account)
  }, [onRecipientInput, account, typedRecipient, queryString])

  // update typedRecipient in the redux state when account changes
  useEffect(() => {
    if (account) onRecipientInput(account)
  }, [onRecipientInput, account])

  const handleFromNetworkSelect = (selectedChainId: number) => {
    setShowSwitchSourceChainInfo(true)
    setupNetwork(selectedChainId)
    onSetApprovalhash('')
  }

  // the main bridge callbacks
  const { callback: handleApproveCallback } = useBridgeV2ERC721ApproveCallback(collection?.address, typedToken)
  const { error: bridgeDepositCallbackError, callback: handleDepositCallback } = useBridgeV2ERC721DepositCallback(
    collection?.address,
    typedToken,
    typedRecipient,
    targetChainId,
  )
  const { error: bridgeReleaseCallbackError, callback: handleReleaseCallback } = useBridgeV2ERC721ReleaseCallback()

  // preselect collection
  useEffect(() => {
    if (collectionId) {
      onSelectCollection(collectionId)
    }
  }, [collectionId, onSelectCollection])

  // preselect target chain
  useEffect(() => {
    const toChainIdNum = parseInt(toChainId)

    if (!Number.isNaN(toChainIdNum)) {
      onSelectTargetChain(toChainIdNum)
    }
  }, [toChainId, onSelectTargetChain])

  // preselect nft ID in the input
  useEffect(() => {
    if (selectedNFT) {
      onTokenInput(selectedNFT)
    }
    if (!selectedNFT || data?.length === 0) {
      onTokenInput('')
    }
  }, [data?.length, onTokenInput, selectedNFT])

  // user click event handlers
  const handleReleaseDismiss = useCallback(() => {
    setBridgeState({
      attemptingTxn: false,
      swapErrorMessage: undefined,
      releaseHash,
    })
  }, [releaseHash])

  const { onPresentSaveClaimModal } = useSaveClaimModal('erc721')
  const handleTokenId = (params) => {
    if (params && selectedNFT !== params) {
      setSelectedNFT(params)
      onSetApprovalhash('')
    }
  }
  const handleCollectionSelect = (col: Collection) => {
    onSelectCollection(col.address)
    onSetApprovalhash('')
  }

  const tryRelease = useCallback(async () => {
    setBridgeState({
      attemptingTxn: true,
      swapErrorMessage: undefined,
      releaseHash,
    })

    try {
      // get release params from oracle
      const releaseParams = await getReleaseParamsERC721(sourceChainId, depositHash)

      // trigger release on target contract
      if (handleReleaseCallback) {
        handleReleaseCallback(releaseParams)
          .then((hash) => {
            // final state
            setBridgeState({
              attemptingTxn: false,
              swapErrorMessage: undefined,
              releaseHash: hash,
            })
            dispatch(removeClaimLink({ depositHash }))
            onReset()
            history.push('/bridge/nfts')
          })
          .catch((error) => {
            if (error.message.indexOf('already processed and released') > -1) {
              onReset()
              history.push('/bridge/tokens')
            }

            setBridgeState({
              attemptingTxn: false,
              swapErrorMessage: rewordError(error.message),
              releaseHash: undefined,
            })
          })
      }
    } catch (err: any) {
      console.error(err)

      setBridgeState({
        attemptingTxn: false,
        swapErrorMessage: rewordError(err.message),
        releaseHash: undefined,
      })
    }
  }, [dispatch, history, sourceChainId, handleReleaseCallback, depositHash, releaseHash, onReset])

  const [onPresentReleaseModal] = useModal(
    <ReleaseBridgeModal
      attemptingTxn={attemptingTxn}
      txHash={releaseHash}
      swapErrorMessage={swapErrorMessage}
      customOnDismiss={handleReleaseDismiss}
      tokenId={typedToken}
      collectionName={collection?.name}
      onRetry={tryRelease}
    />,
    true,
    true,
    'releaseBridgeModal',
  )
  const handleConfirmRelease = useCallback(async () => {
    setBridgeState({
      attemptingTxn: true,
      swapErrorMessage: undefined,
      releaseHash,
    })

    if (depositHash && sourceChainId) {
      onPresentReleaseModal()
      tryRelease()
    }
  }, [onPresentReleaseModal, tryRelease, sourceChainId, depositHash, releaseHash])

  const handleConfirmApprove = useCallback(() => {
    if (handleApproveCallback) {
      setBridgeState({
        attemptingTxn: false,
        swapErrorMessage: undefined,
        releaseHash,
      })

      handleApproveCallback()
        .then((approvalHash) => {
          onSetApprovalhash(approvalHash)
        })
        .catch((error) => {
          console.error(error)
          setBridgeState({
            attemptingTxn: false,
            swapErrorMessage: rewordError(error.message),
            releaseHash,
          })
        })
    }
  }, [handleApproveCallback, releaseHash, onSetApprovalhash])

  const handleConfirmDeposit = useCallback(() => {
    if (handleDepositCallback) {
      handleDepositCallback()
        .then((hash) => {
          console.debug('deposited', { hash })
          onSetDepositHash(hash, sourceChainId)

          setBridgeState({
            attemptingTxn: false,
            swapErrorMessage: undefined,
            releaseHash: undefined,
          })
        })

        .catch((error) => {
          console.error(error)

          setBridgeState({
            attemptingTxn: false,
            swapErrorMessage: rewordError(error.message),
            releaseHash,
          })
        })
    }
  }, [handleDepositCallback, releaseHash, sourceChainId, onSetDepositHash])

  const {
    link: releaseLink,
    error: releaseLinkError,
    isInUrl: releaseUrlPresent,
  } = useReleaseLink(depositHash, sourceChainId, targetChainId)

  const handleBridgingPrimaryButton = (forceNetworkSwitch: boolean) => {
    if (forceNetworkSwitch) {
      setupNetwork(targetChainId)
    } else {
      handleConfirmRelease()
    }
  }

  const nftCollections = getCollections()
  const availableTargetChainNetworkIds = networks
    .filter((netw) => netw.chainId !== sourceChainId)
    .filter((netw) => nftCollections[netw.chainId].filter((col) => col.name === collection?.name).length > 0)
    .map((netw) => netw.chainId) // hide SourceChainId as option

  const showReleaseFlow = Boolean(deposited)
  const showApproveFlow = Boolean(typedToken && collection && targetChainId && !deposited)

  const depositInputContainerStyle = deposited ? { opacity: 0.3 } : { opacity: 1 }
  const errorView = rewordError(bridgeDepositCallbackError || bridgeReleaseCallbackError || swapErrorMessage)
  const claimLinkDisabled = releaseLinkError !== false || waitingDepositConfirmations

  const WAIT_CONFIRMATIONS = getWaitConfirmations(chainId)

  return (
    <>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            <Heading as="h1" scale="xxl" color="white" mb="24px">
              {t('Bridge NFTs')}
            </Heading>
            <Heading scale="lg" color="white">
              {t('Transfer NFT collection tokens')}
            </Heading>
          </Flex>
          <Flex flex="1" height="fit-content" justifyContent="flex-end" alignItems="right" mt={['24px', null, '0']}>
            <Flex alignItems="flex-end">
              <WhitelistNftButton />
              <br />
              <Transactions />
            </Flex>
          </Flex>
        </Flex>
      </PageHeader>
      <Page>
        <AppBody>
          <Wrapper id="swap-page">
            <AutoColumn gap="md" style={depositInputContainerStyle}>
              <NetworkSelectPanel
                id="bridge-collection-from"
                label="From"
                disabled={deposited}
                disableNetworkSelect={deposited}
                chainId={sourceChainId}
                onNetworkSelect={handleFromNetworkSelect}
                message={
                  showSwitchSourceChainInfo
                    ? 'You can change the source network by connecting your wallet accordingly.'
                    : null
                }
                style={{ borderBottom: '2px solid #eee', paddingBottom: '16px' }}
              />
              <CollectionInputPanel
                id="bridge-token-form"
                label="Token ID & Collection"
                value={typedToken}
                onUserInput={handleTokenId}
                chainId={sourceChainId}
                collectionId={collection?.address}
                disabled={deposited}
                onCollectionSelect={handleCollectionSelect}
              />
              <AutoColumn justify="space-between">
                <AutoRow justify="center" style={{ padding: '0.2rem 1rem' }}>
                  <ArrowWrapper clickable={false}>
                    <ArrowDownIcon width="16px" color="primary" />
                  </ArrowWrapper>
                </AutoRow>
              </AutoColumn>
              {account && collection && (
                <>
                  <NftCollectionAssetsSlides
                    onNftAssetSelect={handleTokenId}
                    chainId={sourceChainId}
                    collectionId={collection?.address}
                  />
                  <AutoColumn justify="space-between">
                    <AutoRow justify="center" style={{ padding: '0.2rem 1rem' }}>
                      <ArrowWrapper clickable={false}>
                        <ArrowDownIcon width="16px" color="primary" />
                      </ArrowWrapper>
                    </AutoRow>
                  </AutoColumn>
                </>
              )}
              <NetworkSelectPanel
                id="bridge-collection-to"
                label="To"
                chainId={targetChainId}
                disabled={deposited}
                disableNetworkSelect={deposited}
                availableNetworkIds={availableTargetChainNetworkIds}
                onNetworkSelect={onSelectTargetChain}
                style={{ borderBottom: '2px solid #eee', paddingBottom: '16px' }}
              />
              <AddressInputPanel
                id="bridge-collection-receiver"
                label="Recipient"
                disabled={deposited}
                value={typedRecipient}
                account={account}
                onUserInput={onRecipientInput}
                recipientWarning
              />
            </AutoColumn>
            <Box mt="1rem">
              {!account ? (
                <ConnectWalletButton width="100%" />
              ) : showApproveFlow ? (
                <RowBetween>
                  <Button
                    variant={!approved ? 'primary' : 'secondary'}
                    onClick={handleConfirmApprove}
                    id="approve-button"
                    width="48%"
                    disabled={approved || approvalPending}
                  >
                    {!approvalPending && <div>Approve</div>}
                    {approvalPending && (
                      <div>
                        Approving
                        <span style={{ marginLeft: '10px' }}>
                          <CircleLoader stroke="white" />
                        </span>
                      </div>
                    )}
                  </Button>
                  <Button
                    variant={approved ? 'primary' : 'secondary'}
                    onClick={handleConfirmDeposit}
                    id="confirm-button"
                    width="48%"
                    disabled={!approved}
                  >
                    Confirm send
                  </Button>
                </RowBetween>
              ) : showReleaseFlow ? (
                <Button
                  disabled={claimLinkDisabled}
                  variant={networkSwitchNeeded || claimLinkDisabled ? 'secondary' : 'primary'}
                  onClick={() => handleBridgingPrimaryButton(networkSwitchNeeded)}
                  id="release-button"
                  width="100%"
                >
                  {waitingDepositConfirmations
                    ? confirmations > 0 && confirmations < WAIT_CONFIRMATIONS
                      ? `Waiting for confirmations (${confirmations}/${WAIT_CONFIRMATIONS})`
                      : 'Waiting for confirmations'
                    : networkSwitchNeeded
                    ? 'Switch Network & Claim'
                    : 'Claim NFT'}
                </Button>
              ) : (
                <Button variant="primary" id="bridge-button" width="100%" disabled>
                  Enter Collection &amp; NFT ID
                </Button>
              )}
              {showApproveFlow && (
                <Column style={{ marginTop: '1rem' }}>
                  <ProgressSteps steps={[approved]} />
                </Column>
              )}
              {releaseLinkError && (
                <WarningLabelRow>
                  <InfoIcon mr="10px" color="#FF0000" />
                  <Text fontSize="14px" textAlign="center">
                    There is an error in the active claim link:
                    <br /> {releaseLinkError}
                  </Text>
                </WarningLabelRow>
              )}
              {errorView ? (
                <Box mt="1rem">
                  <GreyCard style={{ textAlign: 'center' }}>
                    <Text color="textSubtle" mb="4px">
                      {errorView}
                    </Text>
                  </GreyCard>
                </Box>
              ) : null}
            </Box>
          </Wrapper>
        </AppBody>

        {!releaseLinkError && releaseLink && !releaseUrlPresent && (
          <Row width="100%" maxWidth="436px" marginTop="2em">
            <LightCard>
              <Text color="textSubtle" fontSize="14px" textAlign="center">
                Or send this link to release the token:
                <Flex justifyContent="center" mt="20px">
                  <LinkExternal href={releaseLink}>Link To Release</LinkExternal>
                </Flex>
              </Text>

              <Button variant="secondary" onClick={onPresentSaveClaimModal} mt="3">
                Save claim link and start new bridging
              </Button>
            </LightCard>
          </Row>
        )}

        <Row width="100%" maxWidth="436px" marginTop="2em">
          <LightCard>
            <Text color="textSubtle" fontSize="14px" textAlign="center">
              NFT bridgings are completely free of charge for a limited period of time! Afterwards, a small protocol
              incentive or bridging fee will be deducted which will be used to buy-back-and-burn BRIDGE.
            </Text>
          </LightCard>
        </Row>

        {/* {showApproveFlow && bridgingFees && (
          <Row width="100%" maxWidth="436px" marginTop="2em">
            <LightCard>
              <Text color="textSubtle" fontSize="14px" textAlign="center">
                A protocol incentive or bridging fee of {bridgingFees.toFixed(4)} {bridgingFees.token?.symbol} is being deducted
                which is used to buy-back-and-burn TXL.
              </Text>
            </LightCard>
          </Row>
        )} */}

        <ClaimList />
      </Page>
    </>
  )
}
