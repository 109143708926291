import React from 'react'
import styled from 'styled-components'
import Page from '../Layout/Page'
import CrossChainIcon from './CrossChainLogo'

const Wrapper = styled(Page)`
  display: flex;
  justify-content: center;
  align-items: center;
`

const PageLoader: React.FC = () => {
  return (
    <Wrapper>
      <span
        style={{
          animation: 'pulse_animation',
          animationDuration: '800ms',
          animationIterationCount: 'infinite',
          animationTimingFunction: 'ease-in-out',
        }}
      >
        <style>
          {`@keyframes pulse_animation {
            0% { transform: scale(1); }
            50% { transform: scale(1.2); }
            100% { transform: scale(1); }
        }`}
        </style>
        <CrossChainIcon isDark />
      </span>
    </Wrapper>
  )
}

export default PageLoader
