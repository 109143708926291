import { createReducer } from '@reduxjs/toolkit'
import { speedUpTransaction } from 'state/transactions/actions'
import { addClaimLink, ClaimLinkInfo, removeClaimLink, reset } from './actions'

export type ClaimState = Record<string, ClaimLinkInfo>

const initialState = {}

export default createReducer<ClaimState>(initialState, (builder) =>
  builder
    .addCase(reset, () => initialState)
    .addCase(addClaimLink, (state, { payload: { info } }) => {
      if (state[info.depositHash]) return

      state[info.depositHash] = {
        ...info,
        createdAt: Date.now(),
      }
    })
    .addCase(removeClaimLink, (state, { payload: { depositHash } }) => {
      delete state[depositHash]
    })
    .addCase(speedUpTransaction, (state, { payload: { hash, toHash } }) => {
      if (state[hash]) {
        state[toHash] = state[hash]
        delete state[hash]
      }
    }),
)
