import React from 'react'
import styled from 'styled-components'
import { HistoryIcon, Button, useModal } from '@pancakeswap/uikit'
import TransactionsModal from './TransactionsModal'

const StyledButton = styled(Button)`
  background-color: white;
`

const Transactions = (props) => {
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)
  return (
    <>
      <StyledButton variant="text" p={10} onClick={onPresentTransactionsModal} ml="16px" {...props}>
        <HistoryIcon color="secondary" width="24px" />
      </StyledButton>
    </>
  )
}

export default Transactions
