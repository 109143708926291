import getPoolsConfig from 'config/constants/pools'
import rewardPoolsABI from 'config/abi/rewardPools.json'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getAddress } from 'utils/addressHelpers'
import BigNumber from 'bignumber.js'
import { ChainId } from '../../config/constants/chainId'
import { getRpcProvider } from '../../utils/providers'

export const fetchPoolsAllowance = async (account, chainId: ChainId) => {
  const calls = getPoolsConfig(chainId)
    .map((p) => ({
      address: getAddress(p.stakingToken.address, chainId),
      name: 'allowance',
      params: [account, getAddress(p.contractAddress, chainId)],
    }))
    .filter((callParams) => callParams.address && callParams.address.length > 0)

  const provider = await getRpcProvider(chainId)

  let allowances
  if (provider.connection.url === 'metamask') {
    allowances = await multicall(erc20ABI, calls, chainId)
  } else {
    allowances = await multicall(erc20ABI, calls, chainId, provider)
  }

  return getPoolsConfig(chainId).reduce(
    (acc, pool, index) => ({ ...acc, [pool.rewardPoolId]: new BigNumber(allowances[index]).toJSON() }),
    {},
  )
}

export const fetchUserBalances = async (account, chainId: ChainId) => {
  const calls = getPoolsConfig(chainId)
    .map((p) => ({
      address: getAddress(p.stakingToken.address, chainId),
      name: 'balanceOf',
      params: [account],
    }))
    .filter((callParams) => callParams.address && callParams.address.length > 0)

  const defaultSigner = await getRpcProvider(chainId)
  const tokenBalancesRaw = await multicall(erc20ABI, calls, chainId, defaultSigner)
  return getPoolsConfig(chainId).reduce(
    (acc, pool, index) => ({ ...acc, [pool.rewardPoolId]: new BigNumber(tokenBalancesRaw[index]).toJSON() }),
    {},
  )
}

export const fetchUserStakeBalances = async (account, chainId: ChainId) => {
  const calls = getPoolsConfig(chainId)
    .map((p) => ({
      address: getAddress(p.contractAddress, chainId),
      name: 'stakes',
      params: [getAddress(p.earningToken.address, chainId), account],
      pool: p,
    }))
    .filter((callParams) => callParams.address && callParams.address.length > 0 && callParams.params[0].length > 0)

  const defaultSigner = await getRpcProvider(chainId)
  const userInfo = await multicall(rewardPoolsABI, calls, chainId, defaultSigner)

  return calls.reduce(
    (acc, call, index) => ({
      ...acc,
      [call.pool.rewardPoolId]: new BigNumber(userInfo[index].balance._hex).toJSON(),
    }),
    {},
  )
}

export const fetchUserPendingRewards = async (account, chainId: ChainId) => {
  const calls = getPoolsConfig(chainId)
    .map((p) => ({
      address: getAddress(p.contractAddress, chainId),
      name: 'pendingReward',
      params: [getAddress(p.earningToken.address, chainId), account],
      pool: p,
    }))
    .filter((callParams) => callParams.address && callParams.address.length > 0 && callParams.params[0].length > 0)

  const defaultSigner = await getRpcProvider(chainId)
  const res = await multicall(rewardPoolsABI, calls, chainId, defaultSigner)
  return calls.reduce(
    (acc, call, index) => ({
      ...acc,
      [call.pool.rewardPoolId]: new BigNumber(res[index]).toJSON(),
    }),
    {},
  )
}
