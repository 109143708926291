import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { RouteComponentProps, useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import { ArrowDownIcon, Box, Flex, Text, useModal, LinkExternal, InfoIcon } from '@pancakeswap/uikit'
import { Currency } from '@pancakeswap/sdk'
import { removeClaimLink } from 'state/claims/actions'
import BridgeLiquidityInfo from 'views/AddLiquidity/components/BridgeLiquidityInfo'
import { TokenAmount } from 'sdk/entities/tokenAmount'
import { getNativeCurrency } from 'sdk/entities/currency'
import ActiveBridgeInfo from 'components/ActiveBridgeInfo/ActiveBridgeInfo'
import { Token } from '../../sdk/entities/token'
import { AppBody } from '../../components/App'
import { ArrowWrapper, Wrapper } from '../../components/styleds'
import { AutoColumn } from '../../components/Layout/Column'
import ConnectWalletButton from '../../components/ConnectWalletButton'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'
import { ApprovalState, useApproveCallbackFromBridge } from '../../hooks/useApproveCallback'
import { Row, AutoRow, RowBetween } from '../../components/Layout/Row'
import { GreyCard, LightCard } from '../../components/Card'
import CurrencyInputPanel from '../../components/CurrencyInputPanel'
import CircleLoader from '../../components/Loader/CircleLoader'
import { useBridgeDepositCallback } from '../../state/bridgeERC20/useBridgeDepositCallback'
import {
  tryParseAmount,
  useBridgeActionHandlers,
  useBridgeState,
  useBridgeV2ERC20ReleaseCallback,
  useDerivedBridgeInfo,
  useReleaseLink,
  useTransactionDetails,
  getWaitConfirmations,
} from '../../state/bridgeERC20/hooks'
import { useSetupNetwork } from '../../utils/wallet'
import { tokenAddressURLParameter, tokenAmountURLParameter } from '../../utils/queryStringParser'
import useParsedQueryString from '../../hooks/useParsedQueryString'
import { getReleaseParamsERC20, rewordError } from '../../utils/getReleaseParams'
import { networks } from '../../config/constants/networks'
import { useCurrencyBalance, useTokenBalances } from '../../state/wallet/hooks'
import { ReleaseBridgeModal } from './components/ReleaseBridgeModal'
import ConfirmBridgeModal from './components/ConfirmBridgeModal'
import PageHeader from '../../components/PageHeader'
import { useTranslation } from '../../contexts/Localization'
import ManageLiquidityButton from '../LiquidityMining/components/ManageLiquidityButton'
import Transactions from '../../components/App/Transactions'
import NetworkSelectPanel from '../../components/NetworkSelectPanel'
import AddressInputPanel, { WarningLabelRow } from '../../components/AddressInputPanel'
import Page from '../Page'
import getTokens, { sourceTokens } from '../../config/constants/tokens'
import Button from '../../theme/components/Button/Button'
import Heading from '../../theme/components/Heading/Heading'
import { useSaveClaimModal } from './components/SaveClaimModal'
import ClaimList from './components/ClaimList'
import { useDerivedLiquidityInfo, useLiquidityManagerActionHandlers } from '../../state/liquidity/hooks'
import { getBscScanLink } from '../../utils'
import AuditIcon from '../../components/Icons/AuditIcon'
import { ChainId } from '../../config/constants/chainId'

const paused = false

function claimLabel(currency: Currency, chainId: number, nativeRelease: boolean): string {
  const nativeCurrency = getNativeCurrency(chainId)

  if (!currency) return ''
  if (nativeRelease && nativeCurrency) {
    return nativeCurrency.symbol || ''
  }

  return `${currency.symbol || ''} Tokens`
}

export default function BridgeERC20({
  match: {
    params: { currencyId, toChainId },
  },
}: RouteComponentProps<{ currencyId?: string; toChainId?: string }>) {
  const { t } = useTranslation()
  const { account, chainId } = useActiveWeb3React()
  const queryString = useParsedQueryString()
  const history = useHistory()
  const dispatch = useDispatch()
  const { setupNetwork } = useSetupNetwork()
  const tokens = getTokens()

  const [showSwitchSourceChainInfo, setShowSwitchSourceChainInfo] = useState(false)
  const [{ swapErrorMessage, attemptingTxn, releaseHash }, setBridgeState] = useState({
    attemptingTxn: false,
    swapErrorMessage: undefined,
    releaseHash: undefined,
  })
  const [claimSignatures, setClaimSignatures] = useState(false)
  const [releaseSignatures, setReleaseSignatures] = useState(null)

  const {
    onReset,
    onAmountInput,
    onRecipientInput,
    onSelectCurrency,
    // onSelectSourceChain,
    onSelectTargetChain,
    onSetDepositHash,
  } = useBridgeActionHandlers()
  const { typedAmount, typedRecipient } = useBridgeState()

  // needed for liquidity withdrawal in target network functionality
  // used to check if withdrawal is pending when user is releasing a depositHash
  const { depositHash: depositHashOfLiquidityWithdrawal } = useDerivedLiquidityInfo()
  const { onReset: onResetLiquidityState } = useLiquidityManagerActionHandlers()
  useTokenBalances(account ?? undefined, Object.values(sourceTokens(chainId, null)))

  const {
    currency,
    depositHash,
    sourceChainId,
    targetChainId,
    networkSwitchNeeded,
    deposited,
    waitingDepositConfirmations,
    confirmations,
  } = useDerivedBridgeInfo()

  // accepted transactions appear in the redux list
  // if they exist => accepted
  // alternative would be to check against the block number to get the no of confirmations
  const releaseTx = useTransactionDetails(targetChainId, releaseHash)
  const releaseTxAccepted = releaseTx?.receipt !== undefined
  const depositTx = useTransactionDetails(sourceChainId, depositHash)
  const depositTxAccepted = depositTx?.receipt !== undefined

  // prefill recipient
  useEffect(() => {
    if (!typedRecipient && queryString.recipient) {
      onRecipientInput(tokenAddressURLParameter(queryString.recipient))
    } else if (!typedRecipient && account) {
      onRecipientInput(account)
    } else if (typedRecipient && !account) {
      onRecipientInput('')
    }
  }, [onRecipientInput, account, typedRecipient, queryString])

  // update typedRecipient in the redux state when account changes
  useEffect(() => {
    if (account) onRecipientInput(account)
  }, [onRecipientInput, account])

  // preselect currency
  useEffect(() => {
    if (currencyId) {
      onSelectCurrency(currencyId)
    }
  }, [currencyId, onSelectCurrency])

  // preselect target chain
  useEffect(() => {
    const toChainIdNum = parseInt(toChainId)

    if (!Number.isNaN(toChainIdNum)) {
      onSelectTargetChain(toChainIdNum)
    }
  }, [toChainId, onSelectTargetChain])

  // prefill amount
  useEffect(() => {
    if (queryString.amount) {
      onAmountInput(tokenAmountURLParameter(queryString.amount))
    }
  }, [queryString, onAmountInput])

  const parsedAmount = useMemo(() => tryParseAmount(typedAmount, currency), [typedAmount, currency])
  const selectedCurrencyBalance = useCurrencyBalance(account, currency)

  const invalidAmount = useMemo(() => {
    if (!parsedAmount || !selectedCurrencyBalance) return true
    if (!parsedAmount.greaterThan('0')) return true
    if (parsedAmount.greaterThan(selectedCurrencyBalance)) return true

    return false
  }, [parsedAmount, selectedCurrencyBalance])

  const handleRecipientInput = (input) => {
    if (input === (currency as Token)?.address) {
      setBridgeState({
        attemptingTxn: false,
        swapErrorMessage: 'Sending tokens to the token contract address would mean impermanent loss.',
        releaseHash: undefined,
      })
    } else {
      onRecipientInput(input)
    }
  }

  // user click event handlers
  const handleConfirmDismiss = useCallback(() => {
    setBridgeState({
      attemptingTxn: false,
      swapErrorMessage: undefined,
      releaseHash: undefined,
    })
  }, [])

  const handleFromNetworkSelect = (selectedChainId: number) => {
    setShowSwitchSourceChainInfo(true)
    setupNetwork(selectedChainId)
  }

  const handleToNetworkSelect = (selectedChainId: number) => {
    onSelectTargetChain(selectedChainId)
  }
  const handleCurrencySelect = (selectedCurrency: Currency) => {
    onSelectCurrency(selectedCurrency.symbol)
  }

  // approval flow
  const [approval, approveCallback] = useApproveCallbackFromBridge(sourceChainId, parsedAmount)

  // check if user has gone through approval process, used to show two step buttons, reset on token change
  const handleApproveConfirm = useCallback(() => {
    if (approveCallback) {
      approveCallback().catch((error) => {
        setBridgeState({
          attemptingTxn: false,
          swapErrorMessage: rewordError(error.message),
          releaseHash: undefined,
        })
      })
    }
  }, [approveCallback])

  // the callback to deposit
  const {
    bridgingFees,
    bridgingLiquidity,
    callback: handleDepositCallback,
  } = useBridgeDepositCallback(currency as Token, parsedAmount, typedRecipient, targetChainId)

  // the callback to release
  const { callback: handleReleaseCallback, isNative: nativeRelease } = useBridgeV2ERC20ReleaseCallback(
    currency as Token,
  )

  // callback when the user clicks confirm to bridge in the modal
  const handleModalConfirm = useCallback(() => {
    setBridgeState({
      attemptingTxn: true,
      swapErrorMessage,
      releaseHash,
    })

    if (handleDepositCallback) {
      handleDepositCallback()
        .then(async (hash) => {
          console.debug('deposited', { hash })
          onSetDepositHash(hash, sourceChainId)

          setBridgeState({
            attemptingTxn: false,
            swapErrorMessage: undefined,
            releaseHash,
          })
        })
        .catch((error) => {
          setBridgeState({
            attemptingTxn: false,
            swapErrorMessage: error.message,
            releaseHash,
          })
        })
    }
  }, [swapErrorMessage, releaseHash, handleDepositCallback, sourceChainId, onSetDepositHash])
  // modal to confirm the bridging
  const [onPresentConfirmModal] = useModal(
    <ConfirmBridgeModal
      fees={bridgingFees}
      currency={currency}
      attemptingTxn={attemptingTxn}
      txHash={depositHash}
      amount={parsedAmount}
      recipient={typedRecipient}
      onConfirm={handleModalConfirm}
      swapErrorMessage={swapErrorMessage}
      customOnDismiss={handleConfirmDismiss}
      targetChainId={targetChainId}
    />,
    true,
    true,
    'confirmBridgeModal',
  )
  const { onPresentSaveClaimModal } = useSaveClaimModal('erc20')

  const handleConfirmSend = useCallback(() => {
    onPresentConfirmModal()
  }, [onPresentConfirmModal])

  const handleReleaseDismiss = useCallback(() => {
    setBridgeState({
      attemptingTxn: false,
      swapErrorMessage: undefined,
      releaseHash,
    })
  }, [releaseHash])

  const tryRelease = useCallback(async () => {
    setBridgeState({
      attemptingTxn: true,
      swapErrorMessage: undefined,
      releaseHash,
    })

    try {
      const releaseParams = releaseSignatures || (await getReleaseParamsERC20(sourceChainId, depositHash))
      if (releaseParams) setClaimSignatures(true)

      // trigger release on target contract
      if (handleReleaseCallback) {
        handleReleaseCallback(releaseParams)
          .then((hash) => {
            console.debug('released', { hash })

            // final state
            setBridgeState({
              attemptingTxn: false,
              swapErrorMessage: undefined,
              releaseHash: hash,
            })

            dispatch(removeClaimLink({ depositHash }))
            // onReset()

            // reset the remove Liquidity form only if user is
            // releasing the same deposit. If another deposit is pending in
            // remove Liquidity component do not reset. This allows non-blocking
            // bridging and liquidity withdrawals
            if (depositHashOfLiquidityWithdrawal === depositHash) {
              onResetLiquidityState()
            }
            // history.push('/bridge/tokens')
          })
          .catch((error) => {
            if (error.message?.includes('already processed and released')) {
              onReset()
              onResetLiquidityState()
              history.push('/bridge/tokens')
            }

            setBridgeState({
              attemptingTxn: false,
              swapErrorMessage: rewordError(error.message),
              releaseHash: undefined,
            })
          })
      }
    } catch (err: any) {
      console.error(err)

      setBridgeState({
        attemptingTxn: false,
        swapErrorMessage: rewordError(err.message),
        releaseHash: undefined,
      })
    }
  }, [
    dispatch,
    history,
    sourceChainId,
    handleReleaseCallback,
    depositHash,
    releaseHash,
    onReset,
    onResetLiquidityState,
    depositHashOfLiquidityWithdrawal,
    releaseSignatures,
  ])

  // modal to confirm the release
  const [onPresentReleaseModal] = useModal(
    <ReleaseBridgeModal
      attemptingTxn={attemptingTxn}
      txHash={releaseHash}
      swapErrorMessage={swapErrorMessage}
      customOnDismiss={handleReleaseDismiss}
      onRetry={tryRelease}
    />,
    true,
    true,
    'releaseBridgeModal',
  )

  const handleConfirmRelease = useCallback(async () => {
    setBridgeState({
      attemptingTxn: true,
      swapErrorMessage: undefined,
      releaseHash,
    })

    if (depositHash && sourceChainId) {
      onPresentReleaseModal()
      tryRelease()
    }
  }, [tryRelease, onPresentReleaseModal, releaseHash, sourceChainId, depositHash])

  const {
    link: releaseLink,
    error: releaseLinkError,
    isInUrl: releaseUrlPresent,
  } = useReleaseLink(depositHash, sourceChainId, targetChainId)

  const handleBridgingPrimaryButton = async (forceNetworkSwitch: boolean) => {
    if (forceNetworkSwitch) {
      setupNetwork(targetChainId)

      // preload claim signature
      const releaseParamsPreload = await getReleaseParamsERC20(sourceChainId, depositHash)
      if (releaseParamsPreload) {
        setClaimSignatures(true)
        setReleaseSignatures(releaseParamsPreload)
      }
    } else {
      handleConfirmRelease()
    }
  }

  const resetForm = () => {
    onReset()
    setClaimSignatures(false)
    setReleaseSignatures(null)
    history.push('/bridge/tokens')
  }

  const showReleaseFlow = Boolean(deposited)
  const showApproveFlow = Boolean(typedAmount && currency && targetChainId && !deposited)
  const approved = approval === ApprovalState.APPROVED
  const approvalPending = approval === ApprovalState.PENDING
  const approvalUnknown = approval === ApprovalState.UNKNOWN

  let selectedTokenNetworks = networks.map((netw) => netw.chainId)
  if (currency) {
    const currencyToken = currency as Token
    let selectedTokenAddresses = currencyToken.address

    const tokenId = currency.symbol.toLowerCase()
    const token = tokens[tokenId === 'wtxl' ? 'txl' : tokenId]
    if (token) {
      selectedTokenAddresses = token.address
      selectedTokenNetworks = networks.map((netw) =>
        selectedTokenAddresses[netw.chainId]?.length > 10 ? netw.chainId : null,
      )
    }
  }

  const availableTargetChainNetworkIds = selectedTokenNetworks.filter((netw) => netw !== sourceChainId) // hide SourceChainId as option
  const claimLinkContainerStyle = waitingDepositConfirmations ? { opacity: 0.3 } : { opacity: 1 }
  const depositInputContainerStyle = deposited ? { opacity: 0.3 } : { opacity: 1 }
  const claimLinkDisabled = releaseLinkError !== false || waitingDepositConfirmations

  const enoughLiquidity =
    parsedAmount && bridgingLiquidity instanceof TokenAmount ? bridgingLiquidity.greaterThan(parsedAmount) : false
  let errorView

  if (swapErrorMessage) {
    errorView = rewordError(swapErrorMessage)
  } else if (parsedAmount && targetChainId) {
    if (typeof bridgingFees === 'undefined') {
      errorView = 'Calculating fees for bridge transfer...'
    } else {
      errorView = (
        <BridgeLiquidityInfo
          bridgingLiquidity={bridgingLiquidity}
          targetChainId={targetChainId}
          currencyToken={currency as Token}
          parsedAmount={parsedAmount}
        />
      )
    }
  }

  const claimStr = claimLabel(currency, targetChainId, nativeRelease)

  const WAIT_CONFIRMATIONS = getWaitConfirmations(sourceChainId)

  return (
    <>
      <PageHeader>
        <Flex justifyContent="space-between" flexDirection={['column', null, null, 'row']}>
          <Flex flex="1" flexDirection="column" mr={['8px', 0]}>
            <Heading as="h1" scale="xxl" color="white" mb="24px">
              {t('Bridge Tokens')}
            </Heading>
            <Heading scale="lg" color="white">
              {t('Transfer tokens between networks')}
            </Heading>
          </Flex>
          <Flex flex="1" height="fit-content" justifyContent="flex-end" alignItems="right" mt={['24px', null, '0']}>
            <ManageLiquidityButton />
            <Flex alignItems="flex-end">
              <Transactions />
              <AuditIcon />
            </Flex>
          </Flex>
        </Flex>
      </PageHeader>
      <Page>
        <AppBody>
          {paused ? (
            <Wrapper id="swap-page">
              <AutoColumn gap="md" style={{ padding: '16px' }}>
                <strong>The token bridge is currently temporarily paused. Please try again later.</strong>
              </AutoColumn>
            </Wrapper>
          ) : (
            <Wrapper id="swap-page">
              {!deposited && (
                <AutoColumn gap="md" style={depositInputContainerStyle}>
                  {/* (sourceChainId === ChainId.AUTOBAHN || targetChainId === ChainId.AUTOBAHN) &&
                    currency?.symbol === tokens.txl.symbol && (
                      <WarningLabelRow>
                        <InfoIcon mr="10px" color="#FF0000" />
                        <Text fontSize="14px" textAlign="center">
                          It is not currently possible to bridge TXL away from the Autobahn Network.
                        </Text>
                      </WarningLabelRow>
                    ) */}
                  {(sourceChainId === ChainId.ETHEREUM ||
                    targetChainId === ChainId.BSC ||
                    targetChainId === ChainId.POLYGON) &&
                    currency?.symbol === tokens.txl.symbol && (
                      <WarningLabelRow>
                        <InfoIcon mr="10px" color="#FF0000" />
                        <Text fontSize="14px" textAlign="center">
                          It is not currently possible to bridge TXL to BSC or Polygon.
                        </Text>
                      </WarningLabelRow>
                    )}
                  <CurrencyInputPanel
                    id="bridge-token-form"
                    label="Amount & Token"
                    value={typedAmount}
                    showMaxButton
                    disabled={deposited}
                    disableCurrencySelect={deposited}
                    currency={currency}
                    onUserInput={onAmountInput}
                    onCurrencySelect={handleCurrencySelect}
                    targetChainId={targetChainId}
                  />
                  <NetworkSelectPanel
                    id="bridge-token-from"
                    label="From"
                    disabled={deposited}
                    disableNetworkSelect={deposited}
                    chainId={sourceChainId}
                    availableNetworkIds={selectedTokenNetworks}
                    onNetworkSelect={handleFromNetworkSelect}
                    message={
                      showSwitchSourceChainInfo
                        ? 'You can change the source network by connecting your wallet accordingly.'
                        : null
                    }
                    style={{ borderTop: '2px solid #eee', paddingBottom: '16px' }}
                  />
                  <AutoColumn justify="space-between">
                    <AutoRow justify="center" style={{ padding: '0 1rem' }}>
                      <ArrowWrapper clickable={false}>
                        <ArrowDownIcon width="16px" color="primary" />
                      </ArrowWrapper>
                    </AutoRow>
                  </AutoColumn>
                  <NetworkSelectPanel
                    id="bridge-token-to"
                    label="To"
                    chainId={targetChainId}
                    disabled={deposited}
                    disableNetworkSelect={deposited}
                    availableNetworkIds={availableTargetChainNetworkIds}
                    onNetworkSelect={handleToNetworkSelect}
                    style={{ borderBottom: '2px solid #eee', paddingBottom: '16px' }}
                  />
                  <AddressInputPanel
                    id="bridge-token-receiver"
                    label="Recipient"
                    value={typedRecipient}
                    disabled={deposited}
                    onUserInput={handleRecipientInput}
                    account={account}
                    recipientWarning
                  />
                </AutoColumn>
              )}

              {depositHash && (
                <>
                  <ActiveBridgeInfo
                    amount={parsedAmount}
                    token={currency}
                    fee={bridgingFees}
                    sourceChainId={sourceChainId}
                    targetChainId={targetChainId}
                    deposited={!!depositHash}
                    depositAccepted={depositTxAccepted}
                    confirmations={confirmations}
                    waitingDepositConfirmations={waitingDepositConfirmations}
                    canSwitchNetwork={networkSwitchNeeded && !waitingDepositConfirmations}
                    onSwitchNetwork={() => handleBridgingPrimaryButton(networkSwitchNeeded)}
                    signatures={claimSignatures}
                    claimed={releaseHash}
                    success={releaseTxAccepted}
                    depositTxHash={depositHash}
                    releaseTxHash={releaseHash}
                  />
                  {releaseHash && (
                    <>
                      <LinkExternal
                        style={{ justifyContent: 'center', marginTop: '12px', width: '100%' }}
                        small
                        href={getBscScanLink(releaseHash, 'transaction', targetChainId)}
                      >
                        Show in Explorer
                      </LinkExternal>
                      <Button onClick={() => resetForm()} variant="primary" width="100%" style={{ marginTop: '12px' }}>
                        Start new bridging
                      </Button>
                    </>
                  )}
                </>
              )}

              {(!depositHash || !releaseHash) && (
                <Box mt="1rem">
                  {!account ? (
                    <ConnectWalletButton width="100%" />
                  ) : showApproveFlow ? (
                    <RowBetween>
                      <Button
                        variant={!approved ? 'primary' : 'secondary'}
                        onClick={handleApproveConfirm}
                        id="approve-button"
                        width="48%"
                        disabled={
                          approved ||
                          approvalPending ||
                          approvalUnknown ||
                          !enoughLiquidity ||
                          (sourceChainId === ChainId.AUTOBAHN && currency?.symbol === tokens.txl.symbol) || // sending TXL from Autobahn to another network is currently disabled
                          (targetChainId === ChainId.BSC && currency?.symbol === tokens.txl.symbol) || // sending TXL to BSC network is currently disabled
                          (targetChainId === ChainId.POLYGON && currency?.symbol === tokens.txl.symbol) // sending TXL to BSC network is currently disabled
                        }
                      >
                        {!approvalPending && <div>Approve {currency.symbol}</div>}
                        {approvalPending && (
                          <div>
                            Approving
                            <span style={{ marginLeft: '10px' }}>
                              <CircleLoader stroke="white" />
                            </span>
                          </div>
                        )}
                      </Button>
                      <Button
                        variant={approved ? 'primary' : 'secondary'}
                        onClick={handleConfirmSend}
                        id="confirm-button"
                        width="48%"
                        disabled={
                          !approved ||
                          invalidAmount ||
                          !enoughLiquidity ||
                          (sourceChainId === ChainId.AUTOBAHN && currency?.symbol === tokens.txl.symbol) || // sending TXL from Autobahn to another network is currently disabled
                          (targetChainId === ChainId.BSC && currency?.symbol === tokens.txl.symbol) || // sending TXL to BSC network is currently disabled
                          (targetChainId === ChainId.POLYGON && currency?.symbol === tokens.txl.symbol) // sending TXL to BSC network is currently disabled
                        }
                      >
                        Confirm send
                      </Button>
                    </RowBetween>
                  ) : showReleaseFlow ? (
                    <Button
                      disabled={claimLinkDisabled}
                      variant={claimLinkDisabled ? 'secondary' : 'primary'}
                      onClick={() => handleBridgingPrimaryButton(networkSwitchNeeded)}
                      id="claim-button"
                      width="100%"
                      ignoreDefaultDisabledStyle
                    >
                      {waitingDepositConfirmations
                        ? confirmations > 0 && confirmations < WAIT_CONFIRMATIONS
                          ? `Waiting for confirmations (${confirmations}/${WAIT_CONFIRMATIONS})`
                          : `Waiting for confirmations`
                        : networkSwitchNeeded
                        ? 'Switch Network & Claim'
                        : `Claim ${claimStr}`}
                    </Button>
                  ) : (
                    <Button variant="primary" id="bridge-button" width="100%" disabled>
                      {targetChainId ? 'Enter amount' : 'Select target network'}
                    </Button>
                  )}
                  {releaseLinkError && (
                    <WarningLabelRow>
                      <InfoIcon mr="10px" color="#FF0000" />
                      <Text fontSize="14px" textAlign="center">
                        There is an error in the active claim link:
                        <br /> {releaseLinkError}
                      </Text>
                    </WarningLabelRow>
                  )}

                  {errorView ? (
                    <Box mt="1rem">
                      <GreyCard style={{ textAlign: 'center' }}>
                        <Text color="textSubtle" mb="4px">
                          {errorView}
                        </Text>
                        {showApproveFlow && bridgingFees && (
                          <Text color="textSubtle" fontSize="14px" textAlign="center" mt="16px">
                            After confirmation, a protocol incentive fee of {bridgingFees.toSignificant(6)}{' '}
                            {bridgingFees.token.symbol} will be deducted which is sent to the Liquidity Mining and/or
                            Reward Pool as well as used to buy-back-and-burn BRIDGE.
                          </Text>
                        )}
                      </GreyCard>
                    </Box>
                  ) : null}
                </Box>
              )}
            </Wrapper>
          )}
        </AppBody>

        {!releaseLinkError && releaseLink && !releaseUrlPresent && !paused && (
          <Row width="100%" maxWidth="436px" marginTop="2em" style={claimLinkContainerStyle}>
            <LightCard>
              <Text color="textSubtle" fontSize="14px" textAlign="center">
                Switch network & claim your tokens above or get a claim link here:
                <Flex justifyContent="center" mt="20px">
                  {!waitingDepositConfirmations ? (
                    <LinkExternal href={releaseLink} color="primary">
                      Claim Link
                    </LinkExternal>
                  ) : (
                    <LinkExternal href={null} color="textSubtle">
                      Claim Link
                    </LinkExternal>
                  )}
                </Flex>
                <Button variant="secondary" onClick={onPresentSaveClaimModal} mt="3" scale="xs">
                  Save claim link and start new bridging
                </Button>
              </Text>
            </LightCard>
          </Row>
        )}

        {showApproveFlow && bridgingFees && !paused && false && (
          <Row width="100%" maxWidth="436px" marginTop="2em">
            <LightCard>
              <Text color="textSubtle" fontSize="14px" textAlign="center">
                A protocol incentive or bridging fee of {bridgingFees.toSignificant(6)} {bridgingFees.token.symbol} is
                being deducted which is sent to the Liquidity Mining and/or Reward Pool as well as used to
                buy-back-and-burn BRIDGE.
              </Text>
            </LightCard>
          </Row>
        )}

        {!paused && <ClaimList />}
      </Page>
    </>
  )
}
