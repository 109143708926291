import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1321.15 1321.15">
        <g id="Camada_2" data-name="Camada 2">
          <g id="Camada_1-2" data-name="Camada 1">
            <path d="M339.2,656.93V644.38c4.27-6.53,7.4-14.3,13-19.4Q494,495,636.38,365.71c15.5-14.11,32.84-14.12,48.32,0Q827,495.16,969.07,624.78c12.15,11.08,15.73,24.28,10.62,37.81-5.2,13.76-16.85,21.28-33.56,21.45-12.14.11-24.27,0-36.4,0-2.19,0-4.38.22-6.88.36v8.18q0,125.52,0,251c0,15.27-7,22.32-22.12,22.33q-75.62.06-151.25,0c-15.6,0-22.46-7-22.47-22.64q0-74.37,0-148.74v-6.74h-92.9v7.27q0,73.44,0,146.86c0,17.48-6.39,24-23.63,24q-74.37,0-148.74,0c-16.92,0-23.47-6.57-23.47-23.5V684.06c-14.84,0-29,0-43.18,0-12.23,0-22.73-3.76-29.73-14.27C342.75,665.88,341.22,661.24,339.2,656.93Z" />
            <path d="M773.15,393.07h10.54q55.21,0,110.44,0c15.69,0,22.61,6.88,22.62,22.43q0,51.45,0,102.91v7.08L772.61,394.43Z" />
            <path d="M660.57,1321.15A660.74,660.74,0,0,1,403.44,51.93a660.4,660.4,0,0,1,917.71,608.64,42.52,42.52,0,1,1-85,0C1236.11,343.22,977.93,85,660.57,85S85,343.22,85,660.57s258.18,575.54,575.53,575.54c172.94,0,335.15-76.74,445-210.54a42.52,42.52,0,1,1,65.72,54C1045.22,1233.08,859.06,1321.15,660.57,1321.15Z" />
            <path d="M1211.67,979.18a42.54,42.54,0,0,1-37.54-62.43c10.22-19.29,36.26-91.88,36.26-121.29a42.52,42.52,0,1,1,85,0c0,22-6.13,52.38-18.22,90.12-8.86,27.63-20.08,56.15-27.93,71A42.52,42.52,0,0,1,1211.67,979.18Z" />
          </g>
        </g>
      </svg>
    </Svg>
  )
}

export default Icon
