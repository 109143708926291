import React from 'react'
import { Svg, SvgProps } from '@pancakeswap/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1321.15 1321.15">
        <g id="Camada_2" data-name="Camada 2">
          <g id="Camada_1-2" data-name="Camada 1">
            <path d="M660,636.36c-22.73-.5-41.33-18.32-42.37-40.57a43.62,43.62,0,0,1,11.45-31.67,41.88,41.88,0,0,1,29.26-13.78,43.18,43.18,0,0,1,31.29,11.22,44,44,0,0,1,14,30.87c.61,14.91,11.08,25.43,24.9,25.05a23.45,23.45,0,0,0,16.59-7.31,26.47,26.47,0,0,0,6.78-19.25,90.28,90.28,0,0,0-26.12-61.48c-11.13-11.5-24.58-19.47-41-24.28,0-.92,0-1.82,0-2.73,0-4.87,0-9.46,0-14.18-.23-14-10.51-24.61-23.92-24.73-13.69-.12-24.19,10.6-24.38,25-.06,4.72,0,9.5,0,14.11,0,.92,0,1.83,0,2.75-.25.08-.49.16-.73.22a91.06,91.06,0,0,0-58.47,50.32,89.56,89.56,0,0,0,.66,75.87c16.36,34.09,44.39,51.93,83.29,53,23,.65,41.63,18.71,42.42,41.13.84,23.73-17.43,43.88-40.72,44.92a42.91,42.91,0,0,1-31.23-11.19,44,44,0,0,1-14.09-30.9c-.61-14.9-11.07-25.46-24.89-25.05A23.54,23.54,0,0,0,576,711a26.25,26.25,0,0,0-6.64,19.21c1.23,30.47,14.12,54.56,38.31,71.59,6.58,4.64,13.71,8,21.27,11.56,2.47,1.17,5,2.36,7.54,3.62,0,.67,0,1.36,0,2.07,0,4.41-.06,9.41,0,14.41.3,13.4,10.78,24,23.86,24.15h.27c13.26,0,23.84-10.62,24.13-24.3.11-4.71.08-9.36.06-14.28,0-1,0-2,0-3.07l2.63-.78a91,91,0,0,0,58.76-55.4,89.77,89.77,0,0,0-7.56-79.34C721,652,694.56,637.12,660,636.36Z" />
            <path d="M971.48,705.6c-14.43-44.1-34.56-88.33-61.54-135.2-55.37-96.17-126.37-194.63-223.45-309.85-8.26-9.8-16.88-14.77-25.63-14.79h-.05c-9,0-17.64,5-26.36,15.26-60.74,71.51-113.32,140.21-160.76,210-32.8,48.26-74.36,112.78-105,183.85-17.47,40.57-36.68,94.88-30.16,155.18,6.21,57.43,24.16,104.81,54.87,144.85a287,287,0,0,0,86.62,75.53c31.32,17.74,67.69,30.62,108.09,38.28,10.7,2,21.63,3.39,32.21,4.71,4.82.6,9.8,1.22,14.67,1.89l50.84.05.45-.08c.86-.16,1.73-.34,2.6-.53a36.63,36.63,0,0,1,4.66-.79c51.45-4,99.39-17.76,142.48-40.78,61.52-32.85,106.62-84.47,130.45-149.28C987.79,826,989.6,761,971.48,705.6Zm-145.19,266c-47.23,30.38-100.58,45.35-163.09,45.75-66.76.41-122.47-15.09-170.36-47.44-56.16-37.95-87.63-91-96.21-162.12C390.51,757,407.27,710.64,424.46,672c27.16-61,63.83-122.95,112.11-189.46,27.81-38.29,57.34-76.37,85.9-113.2,12.42-16,25.26-32.56,37.79-48.92.19-.25.39-.5.61-.75q7.38,9.21,14.71,18.31c13.48,16.76,26.21,32.58,38.93,49,67.67,87.24,115,157.31,153.51,227.2,23.58,42.83,39.64,80,50.52,116.86C940.32,804.86,915.39,914.26,826.29,971.58Z" />
            <path d="M887.76,782.76a46.57,46.57,0,0,0-.89-10.08c-3.08-13.64-16.32-23.41-30.13-22.23-14.71,1.26-25.6,12.69-27.08,28.46q-.35,3.63-.66,7.28c-1,11.81-2.13,24-4.82,35.46-5.23,22.25-17.71,42.29-37.1,59.57-10.48,9.34-13.08,22.75-6.79,35a27.56,27.56,0,0,0,30.89,14.87,37.61,37.61,0,0,0,16-8.4c38.57-34,58.4-78.9,60.62-137.19V785C887.77,784.39,887.77,783.62,887.76,782.76Z" />
            <path d="M660.57,1321.15A660.74,660.74,0,0,1,403.44,51.93a660.4,660.4,0,0,1,917.71,608.64,42.52,42.52,0,1,1-85,0C1236.11,343.22,977.93,85,660.57,85S85,343.22,85,660.57s258.18,575.54,575.53,575.54c172.94,0,335.15-76.74,445-210.54a42.52,42.52,0,1,1,65.72,54C1045.22,1233.08,859.06,1321.15,660.57,1321.15Z" />
            <path d="M1211.67,979.18a42.54,42.54,0,0,1-37.54-62.43c10.22-19.29,36.26-91.88,36.26-121.29a42.52,42.52,0,1,1,85,0c0,22-6.13,52.38-18.22,90.12-8.86,27.63-20.08,56.15-27.93,71A42.52,42.52,0,0,1,1211.67,979.18Z" />
          </g>
        </g>
      </svg>
    </Svg>
  )
}

export default Icon
